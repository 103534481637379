import React, {useEffect} from 'react';
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Button, Col, Divider, List, message, Radio, Row, Spin} from "antd";
import css from './home_login.module.less';
import {useHistory} from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroller';

let store = require('../../store');
let api = require('src/api');
let moment = require('moment');
let history;
let orderHelper = require('commonProject/helper/order_helper');

// create observer
let HomeLogin = observer(function () {
    history = useHistory();
    useEffect(() => {
    }, []);

    return (
        <Col className={css.containBox}>
            <h2 className={`darkBlue ${css.title}`}>User Homepage</h2>
            <Divider className={`gray ${css.divider}`}/>
            <Button className={`greyBtn darkBlue ${css.btnTop}`} onClick={() => {
                history.push('/add_order/choose_lang')
            }}>book a service</Button>
            <p/>
            <Button className={`greyBtn ${css.btnBottom}`} onClick={() => {
                history.push('/order_list');
            }}>existing pre-bookings</Button>
        </Col>
    );
});

export default HomeLogin;