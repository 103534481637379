import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {useLocation, useParams} from "react-router";
import {useHistory} from "react-router-dom";
import {Button, Col, Input, Row, message, Form, Radio, Select, Checkbox, Divider, Modal, Image} from "antd";
import {Link} from "react-router-dom";
import css from "./potential_user_registered.module.less";
import CountDown from "ant-design-pro/lib/CountDown";
import right_svg from 'src/assets/img/common/right.svg';

let store = require('../../../store');
let api = require('src/api');
let data = store.persist.register;
// eslint-disable-next-line react-hooks/rules-of-hooks
let history;
let commonUtils = require('commonProject/utils/Common');
let moment = require('moment');
let loginHelper = require('../../../utils/helper/login_helper');

function initData() {
    // data = store.persist.register;
}

// create observer
let PotentialUserRegistered = observer(function () {
    history = useHistory();
    let params = commonUtils.query2ObjParams(useLocation().search);
    useEffect(() => {
        initData();
    }, []);

    return (
        <Col className={css.containBox}>
            <div>
                <div className={css.title}>Email Update Registration</div>
                <Col align={'middle'}><Image src={right_svg} preview={false} className={css.successLogo}/></Col>
                <div className={css.stepDesc}>
                    You are done!
                </div>
                <div className={css.stepDesc}>
                    Thank you for registering occasional email updates with us.
                </div>
                <div className={css.stepDesc}>
                    We understand it takes time to make decision of using our services, but feel free to contact us
                    anytime for questions, comments, suggestions. We are always listening and delighted to hear from
                    you!
                </div>
                <Col align={'middle'}>
                    <Button className={css.doneBtn + ' greyBtn'} onClick={() => {
                        loginHelper.logout(history);
                    }}>Done</Button>
                </Col>
            </div>
        </Col>
    );
});

export default PotentialUserRegistered;
