import React, {useEffect} from 'react';
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Button, Col, List, message, Radio, Row, Spin} from "antd";
import css from './order_list.module.less';
import {useHistory} from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroller';

let store = require('../../store');
let data = store.order_list;
let api = require('src/api');
let moment = require('moment');
let history;
let orderHelper = require('commonProject/helper/order_helper');

function getOrders() {
    let params = {
        page: data.page,
        pageSize: data.pageSize,
    }
    data.loading = true;
    api.order.get_order_list(params).then(res => {
        data.loading = false;
        if (res.code != 0) return;
        if (data.page == 1) {
            data.orders = res.data.item;
            store.persist.user_last_order = res.data.item[0];
        } else {
            let temp = JSON.parse(JSON.stringify(data.orders));
            data.orders = temp.concat(res.data.item);
        }

        data.page += 1;
        data.hasMore = res.data.total > data.orders.length;
    });
}

function onClickBottomBtn(type) {
    if (!data.selectItemOrder) return message.error('please choose one order');
    if (type == 'active') {
        let order = data.selectItemOrder;
        if (order.status != '2') return message.error('only order is comming soon,you can active');
        // if (moment(order.subscribe_time).diff(moment(), 'minutes') > 5) return message.error('subscribe_time,you can active');

        //如果已经设置过订单联系方式，就直接跳转到订单执行页面。
        return history.push(`/prepare_order_start?id=${order._id}`);
    } else if (type == 'edit') {
        let order = data.selectItemOrder;
        if (order.status != '2') return message.error('only order is comming soon,you can edit');
        history.push(`/edit_order?id=${data.selectItemOrder._id}`)
    } else if (type == 'cancel') {
        if (data.selectItemOrder.status != '2') return message.error('only order is comming soon,you can cancel');
        history.push(`/cancel_order?id=${data.selectItemOrder._id}`);
    } else if (type == 'detail') {
        history.push(`/order_detail?id=${data.selectItemOrder._id}`);
    }
}

function getListItemView(item) {
    let duration = moment.duration(item.subscribe_time_length, 'minutes');

    let itemStr = `${item.interpret_lang_user_need?.language_name}-${item.support_service_type?.type_name}`
    let itemStr2 = `on ${moment(item.subscribe_time).format('MM/DD/yyyy HH:mm:ss dddd')},for ${duration.hours()} hr + ${duration.minutes()} min `;
    let itemStr3 = `Status:${orderHelper.getStatusName(item.status)}`;
    let txtColor = '';
    if (item.status == 0) {//已失效
        txtColor = '#FFA500';
    } else if ([2].includes(item.status)) {//已预约
        txtColor = 'green';
    } else if ([8].includes(item.status)) {//订单结束
        txtColor = 'grey';
    }
    return (
        <List.Item className={css.listItem}>

            <Radio
                value={item}>
                <span>{itemStr}</span>
                <p>{itemStr2}</p>
                <span className={css.btnBox} style={{color: txtColor}}>{itemStr3}</span>
            </Radio>
        </List.Item>
    )
}

const loadMore =
    !data.loading && data.hasMore ? (
        <div
            style={{
                textAlign: 'center',
                marginTop: 12,
                height: 32,
                lineHeight: '32px',
            }}
        >
            <Button onClick={getOrders}>loading more</Button>
        </div>
    ) : null;

function initData() {
    let userInfo = store.persist.userInfo;
    //跳到填写用户信息页面
    if (!userInfo.email || !userInfo.phone_num) return history.push('/register_is_admin');
    getOrders();
}

// create observer
let OrderList = observer(function () {
    history = useHistory();
    data = store.order_list;
    useEffect(() => {
        initData();
    }, []);

    return (
        <Col className={css.containBox}>
            <Col align='middle'>
            </Col>
            <p className={css.loginDesc}>Manage pre-bookings</p>
            <span className={css.head1}>My booking list </span>
            <p className={css.btnBox1}>(scroll up or down to view):</p>
            <Radio.Group onChange={(e) => {
                data.selectItemOrder = e.target.value;
            }}>
                <List
                    className='demo-loadmore-list'
                    loading={data.loading}
                    loadMore={loadMore}
                    dataSource={data.orders}
                    itemLayout='vertical'
                    renderItem={item => (
                        getListItemView(item)
                    )}/>
            </Radio.Group>
            <Row className={css.bottomBtnBox}>
                <Button className={css.head2}disabled={!data.selectItemOrder || data.selectItemOrder.status != '2'}
                        onClick={() => onClickBottomBtn('active')} type='primary'>Activate</Button>
                {(data.selectItemOrder && data.selectItemOrder.status != '2') ?
                    <Button className={css.head2} onClick={() => onClickBottomBtn('detail')} type='primary'>Detail</Button> : ''}
                <Button className={css.head2} disabled={!data.selectItemOrder || data.selectItemOrder.status != '2'}
                        onClick={() => onClickBottomBtn('edit')} type='primary'>Edit</Button>
                <Button className={css.head2} disabled={!data.selectItemOrder || data.selectItemOrder.status != '2'}
                        onClick={() => onClickBottomBtn('cancel')} type='primary'>Cancel</Button>
            </Row>
        </Col>
    );
});

export default OrderList;