import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {Card, message, Row} from "antd";
import css from './team_admin_setting.module.less';
import {useHistory} from "react-router-dom";
import {useLocation} from "react-router";
import BackBtn from "../../component/back_btn/back_btn";

let mobx = require('mobx');
let store = require('../../store');
let api = require('src/api');
let moment = require('moment');
let history, userInfo, queryParams;
let config = require('commonProject/config/config');
let commonUtils = require('commonProject/utils/Common');

let data = mobx.observable({
    objTeamAdmin: {
        data: [],//子团队数据
        formatData: {},//添加子团队数据
    },
    objTeamInfo: {
        user: {},
        subUsers: [],
    },
});

//团队管理设置页面

async function init(queryParams) {
    getTeamAdmins();
    getTeamAdminItem(queryParams);
}

async function getTeamAdmins() {
    let res = await api.user.get_sub_users();
    if (res.code != 0) return;
    data.objTeamAdmin.data = res.data;
}

async function getTeamAdminItem(queryParams) {
    if (!['team_info_read', 'team_info_edit'].includes(queryParams.type) || !queryParams.user_id) return;
    let res = await api.user.owner_get_user_info({user_id: queryParams.user_id});
    if (res.code != 0) return;
    data.objTeamInfo.user = res.data.user;
    data.objTeamInfo.subUsers = res.data.subUsers;
}

async function owner_add_self_team_admin() {
    let res = await api.user.owner_add_self_team_admin();
    if (res.code != 0) return;
    let item = res.data;
    on_add_employee(item);
}

//添加子员工时按钮  item是team_admin团队管理信息
async function on_add_employee(item) {
    if (!item.time_zone) {
        let updateData = {};
        updateData.time_zone = commonUtils.getClientTimeZone();
        updateData._id = item._id;
        updateData.email = item.email;
        updateData.phone_num = item.phone_num;
        updateData.user_role_type = item.user_role_type;
        let resData = await api.user.register_add_info(updateData);
        if (resData.code !== 0) return;
        message.success(resData.msg);
        // return Modal.confirm({
        //     content: 'In order to add users under this admin, you need to complete the admin profile first.',
        //     onOk: function () {
        //         history.push(`/register_add_info_admin?type=half_update&&${commonUtils.obj2QueryParams(item)}`);
        //     },
        // })
    }
    return history.push(`/account_owner_setting?type=team_info_edit&user_id=${item._id}`);
}

function getTeamAdminView(type = 'read') {
    return (
        <div className={css.team_admin_role}>
            <div className={css.toAddUser}>Add Users:</div>
            <div className={css.titleDesc}>--- To add users under a team admin, click on an admin box below.
            </div>
            <div className={css.titleDesc}>--- To add more Team Admin, go back to "Account Owner Role" on previous page.</div>
            <div className={css.teamList}>
                {userInfo.user_role_type == 'owner' ? (
                    <Card className={css.selfAdminTeamItem} onClick={() => {
                        owner_add_self_team_admin();
                    }}>
                        <Row align={'middle'} className={css.selfAdminBox}>
                            <span className={css.name}>add users under you.&nbsp;</span>
                            <div className={'italic '}>( yourself as an admin )</div>
                        </Row>
                    </Card>
                ) : null}
                {data.objTeamAdmin.data.map(item => {
                    if (item.is_owner_self_team_admin == 1) return;
                    return (
                        <Card className={css.teamItem} onClick={() => {
                            on_add_employee(item)
                        }}>
                            <Row align={'middle'} className={css.nameBox}>
                                {`Team name:`}<span className={css.name}>{item.team_name}</span>
                            </Row>
                            <div>{`Admin:`}<span
                                className={css.otherValue}>{`${item.second_name},${item.first_name}`}</span></div>
                            <div>{`Email:`}<span className={css.otherValue}>{item.email}</span></div>
                        </Card>
                    )
                })}
            </div>
            <BackBtn/>
        </div>
    );
}

// Email:   Elon.Musk@gmail.com
function getViewType(type) {
    switch (type) {
        case 'team_admin_role':
            return getTeamAdminView('read');
        default:
            return getTeamAdminView('read');
    }
}

let TeamAdminSetting = observer(function () {
    history = useHistory();
    userInfo = store.persist.userInfo;
    queryParams = commonUtils.query2ObjParams(useLocation().search);
    queryParams.type = queryParams.type ?? 'team_admin_role';

    useEffect(() => {
        init(queryParams);
    }, []);

    return getViewType(queryParams.type);
});

export default TeamAdminSetting;
