// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".containBox_rc8Mxe {\n  text-align: center;\n}\n.containBox_rc8Mxe .headTitle_1MmHiM {\n  color: #122B88;\n  font-weight: bold;\n}\n.containBox_rc8Mxe .headTitle1_2Rr0hr {\n  color: lightgray;\n}\n.containBox_rc8Mxe .headTitle2_2OCTDH {\n  font-size: 16px;\n  font-family: Roboto-Light;\n}\n.containBox_rc8Mxe .headTitle3_t9xZff {\n  font-size: 16px;\n  color: #122B88;\n  font-weight: bold;\n}\n.containBox_rc8Mxe .headTitle4_1T2nJ1 {\n  font-weight: bold;\n}\n", "",{"version":3,"sources":["webpack://src/pages/prepare_order_start/prepare_order_start.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAFA;EAII,cAAA;EACA,iBAAA;AACJ;AANA;EASI,gBAAA;AAAJ;AATA;EAYI,eAAA;EACA,yBAAA;AAAJ;AAbA;EAgBI,eAAA;EACA,cAAA;EACA,iBAAA;AAAJ;AAlBA;EAuBI,iBAAA;AAFJ","sourcesContent":[".containBox {\n  text-align: center;\n\n  .headTitle {\n    color: #122B88;\n    font-weight: bold;\n  }\n\n  .headTitle1 {\n    color: lightgray;\n  }\n  .headTitle2{\n    font-size: 16px;\n    font-family: Roboto-Light;\n  }\n  .headTitle3{\n    font-size: 16px;\n    color: #122B88;\n    font-weight: bold;\n\n  }\n\n  .headTitle4{\n    font-weight: bold;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containBox": "containBox_rc8Mxe",
	"headTitle": "headTitle_1MmHiM",
	"headTitle1": "headTitle1_2Rr0hr",
	"headTitle2": "headTitle2_2OCTDH",
	"headTitle3": "headTitle3_t9xZff",
	"headTitle4": "headTitle4_1T2nJ1"
};
export default ___CSS_LOADER_EXPORT___;
