import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Button, Col, Image, Input, message, Row} from 'antd';
import {Link, useHistory} from "react-router-dom";
import {useParams} from "react-router";
import RegisterEmail from "./register_email/register_email";
import RegisterAddInfo from "./register_add_info/register_add_info";
import RegisterPhone from "./register_phone/register_phone";
import css from "../login/login.module.less";
import CountDown from "ant-design-pro/lib/CountDown";

let config = require('commonProject/config/config');
let store = require('src/store');
let data = store.register;
let api = require('src/api');
let history;
let commonUtils = require('commonProject/utils/Common');
let moment = require('moment');
let loginHelper = require('../../utils/helper/login_helper');


async function register() {
    let res = await api.user.register(data);
    if (res.code != 0) return false;
    // message.success(res.msg);
    loginHelper.loginAfter(res, history);
}

//发送手机验证
async function verify_phone_send_code(is_voice = false) {
    let phone_num = data.phone_num;
    if (commonUtils.isEmpty(phone_num)) return message.error('phone num is empty');
    let res = await api.user.verify_phone_send_code({phone_num: phone_num, is_verify_new: true, is_voice: is_voice});
    if (res.code != 0) return;
    message.success('send code success');
    data.phone_code_time = new Date().getTime() + commonUtils.config.valid_code_time;
    data.is_show_login_view = true;
    data.login_type = 'phone';
}

//发送手机验证
async function verify_email_send_code() {
    let email = data.email;
    if (commonUtils.isEmpty(email)) return message.error('email is empty');
    let res = await api.user.verify_email_send_code({email: email, is_verify_new: true});
    if (res.code != 0) return;
    message.success('send code success');
    data.phone_code_time = new Date().getTime() + commonUtils.config.valid_code_time;
    data.is_show_login_view = true;
    data.login_type = 'email';
}

async function initData() {

}

// create observer
let Register = observer(function () {
    let urlParams = useParams();
    history = useHistory();
    useEffect(async () => {
        await initData();
    }, []);

    return (
        <div className={css.containBox}>
            {!data.is_show_login_view ? (
                <Col>
                    <p className={css.headTitleBox + ' darkBlue'}>User Sign Up</p>
                    <div className={css.loginBox}>
                        <span className={css.desc}>using email:</span><span className={css.hint + ' hintGray'}>(We will email you a pass code).</span>
                        <Input placeholder='Your Email' defaultValue={data.email} onChange={(e) => {
                            data.email = e.target.value
                        }}/>
                        <Col align={'middle'}>
                            <Button className={css.btn} onClick={verify_email_send_code}>Send Code</Button>
                        </Col>
                    </div>

                    {/*<div className={css.loginBox}>*/}
                    {/*    <span className={css.desc}>using cell phone:</span><span className={css.hint + ' hintGray'}>(We will text you a code).</span>*/}
                    {/*    <Input placeholder='US 10-digit cell phone number' defaultValue={data.phone_num} onChange={(e) => {*/}
                    {/*        data.phone_num = e.target.value*/}
                    {/*    }}/>*/}

                    {/*    <Col align={'middle'}>*/}
                    {/*        <Button className={css.btn} onClick={() => {*/}
                    {/*            verify_phone_send_code(false)*/}
                    {/*        }}>send code</Button>*/}
                    {/*    </Col>*/}
                    {/*</div>*/}

                    {/*<div className={css.loginBox}>*/}
                    {/*    <span className={css.desc}>using Landline:</span><span className={css.hint + ' hintGray'}>(We will call you for a code).</span>*/}
                    {/*    <Input placeholder='US 10-digit landline number' defaultValue={data.phone_num} onChange={(e) => {*/}
                    {/*        data.phone_num = e.target.value*/}
                    {/*    }}/>*/}
                    {/*    <Col align={'middle'}>*/}
                    {/*        <Button className={css.btn} onClick={() => {*/}
                    {/*            verify_phone_send_code(true)*/}
                    {/*        }}>send code</Button>*/}
                    {/*    </Col>*/}
                    {/*</div>*/}
                </Col>
            ) : (
                <Col className={css.containBox}>
                    <p className={css.headTitleBox + ' darkBlue'}>User Sign Up</p>
                    <div className={css.loginBox} align='middle'>
                        <div>
                            {data.login_type == 'phone' ? (
                                <div>
                                    <span className={css.desc}>using cell phone:</span><span className={'hintGray'}>(We will text you a code).</span>
                                    <p style={{display: 'none'}}>{data.phone_num}</p>
                                    <Input placeholder='US 10-digit cell phone number' defaultValue={data.phone_num}
                                           onChange={(e) => {
                                               data.phone_num = e.target.value
                                           }}/>
                                </div>
                            ) : (
                                <div>
                                    <span className={css.desc}>using email:</span><span className={'hintGray'}>(We will email you a pass code).</span>
                                    <Input placeholder='Your Email' defaultValue={data.email} onChange={(e) => {
                                        data.email = e.target.value
                                    }}/>
                                </div>
                            )}
                        </div>
                        <div className={css.phoneCodeBox}>
                            <p className={css.loginDesc + ' darkBlue'}>Enter you code:</p>
                            {
                                data.login_type == 'phone' ? (
                                    <Input placeholder='phone code'
                                           className={css.phoneCode}
                                           defaultValue={data.phone_code}
                                           onChange={(e) => {
                                               data.phone_code = e.target.value
                                           }}/>
                                ) : (
                                    <Input placeholder='email code'
                                           className={css.phoneCode}
                                           defaultValue={data.email_code}
                                           onChange={(e) => {
                                               data.email_code = e.target.value
                                           }}/>
                                )
                            }
                            {data.phone_code_time == 0 ?
                                // <Button onClick={verify_phone_send_code}>send code</Button>
                                <span/>
                                : <CountDown
                                    style={{color: 'red'}}
                                    format={time => {
                                        return moment(time).format('mm:ss');
                                    }}
                                    target={data.phone_code_time}
                                    onEnd={() => {
                                        data.phone_code_time = 0;
                                    }}/>}
                        </div>
                        <p className={'hintGray'}>(Please also check your spam folder for the email).</p>
                        <Button
                            onClick={() => {
                                register(data.login_type)
                            }}
                            type='primary'
                            className={css.btn}>Sign Me Up</Button>
                    </div>
                </Col>
            )}
            <div className={css.noteBox}>
                <div className={css.noteTitle}>Note:</div>
                <div className={css.noteDesc}>-- Potential users can sign up for occasional email updates only.</div>
                <div className={css.noteDesc}>-- For trial user, only the account owner needs to sign up. The account
                    owner then can add in all user
                    accounts and/or admin accounts.
                </div>
                <div className={css.noteDesc}>-- Account owner is the one who can setup payment account.</div>
                <div className={css.noteDesc}>-- You may transfer your owner account to your coworker later, if we
                    verify the necessity.
                </div>
            </div>
        </div>
    );
})

export default Register;
