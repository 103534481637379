import React, {useState, useEffect} from 'react';
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Button, Card, Col, Image, List, message, Row} from 'antd';
import {Link, useHistory} from "react-router-dom";
import css from './choose_lang.module.less';

let store = require('src/store');
let addOrderData = store.persist.add_order;
let config = require('commonProject/config/config');
let api = require('src/api');

function getChooseLangs() {
    api.order.get_interpret_lang_list().then(res => {
        store.add_order.chooseLangs = res.data;
    })
}

//清除历史添加订单数据
function clearFormData() {
    let formData = store.persist.add_order.formData;
    let initData = require('src/store/persist').initData;
    // console.log(1,JSON.parse(JSON.stringify(formData)));
    let keys = Object.keys(formData);
    for (let key of keys) {
        formData[key] = initData.add_order.formData[key] ?? null;
    }
    // console.log(JSON.parse(JSON.stringify(formData)));
}

// create observer
let ChooseLang = observer(function () {

    useEffect(() => {
        getChooseLangs();
        clearFormData();
    }, [])//这里传[]，如果数组里面有值，表示当这个发生变化时执行effect方法。
    // 如果没值，表示只执行一次初始化。如果要实现componentWillUnmount的方法，需要在effect里面的return时执行方法

    let history = useHistory();
    let formData = store.persist.add_order.formData;

    return (
        <div className={css.containBox}>
            <p className={css.headTitle}>step 1 of 4</p>
            <p className={css.headTitle1}>Choose language</p>
            <List grid={{gutter: 12, column: 3}}
                  dataSource={store.add_order.chooseLangs}
                  renderItem={item => (
                      <List.Item>
                          <Card onClick={e => {
                              formData.interpret_lang_user_need = item._id;
                              formData.chooseLang = item;
                              message.success('choosed ' + item.language_name);
                              history.push('/add_order/choose_cert');
                          }} className={item.sortIndex == 0 ? css.primaryLang : css.headTitle3}>
                              <div>
                                  {item.language_name}
                              </div>
                              <div>{item.language_special_desc}</div>
                          </Card>
                      </List.Item>
                  )}
            />
        </div>
    );
})

export default ChooseLang;