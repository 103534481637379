let mobx = require('mobx');
// create State object
let registerState = mobx.observable({
    formData: {
        user_name: '',
        chooseLangs: [],
        chooseCerts: [],
        work_years: 0,//工作年限
    },
    chooseCerts: [],
    is_show_verify_ok: false,//是否显示验证成功的页面
    is_same_mail_address: false,//是否和邮寄地址一致
    choose_tz_now_time: 0,//选择的时区的当前时间
    phone_num: '',
    email_code: '',
    phone_code: '',
    phone_code_time: 0,//短信验证码有效时间
    email_code_time: 0,//短信验证码有效时间
    is_show_login_view: false,//是否显示填写验证码和登录的按钮
    login_type: '',
    contacts:[],
    sub_users:[],
    timeZones: [ //目前只显示美国的几个时区
        {name: 'Atlantic Standard Time (Puerto Rico) / UTC-4', value: 'Atlantic/Stanley'},
        {name: 'Atlantic Daylight Time (Puerto Rico) / UTC-3', value: 'Atlantic/Stanley'},
        {name: 'Eastern Standard Time (New York) / UTC-5', value: 'America/New_York'},
        {name: 'Eastern Daylight Time (New York) / UTC-4', value: 'America/New_York'},
        {name: 'Central Standard Time (Chicago) / UTC-6', value: 'America/Chicago'},
        {name: 'Central Daylight Time (Chicago) / UTC-5', value: 'America/Chicago'},
        {name: 'Mountain Standard Time (Denver, Phoenix) / UTC-7', value: 'America/Denver'},
        {name: 'Mountain Daylight Time (Denver) / UTC-6', value: 'America/Denver'},
        {name: 'Pacific Standard Time (Los Angeles) / UTC-8', value: 'America/Los_Angeles'},
        {name: 'Pacific Daylight Time (Los Angeles) / UTC-7', value: 'America/Los_Angeles'},
        {name: 'Alaska Time (Anchorage) / UTC-9', value: 'America/Anchorage'},
        {name: 'Alaska Daylight Time (Anchorage) / UTC-8', value: 'America/Anchorage'},
        {name: 'Hawaii-Aleutian Standard Time (Honolulu) / UTC-10', value: 'Pacific/Honolulu'},
        {name: 'Hawaii-Aleutian Daylight Time (Honolulu) / UTC-9', value: 'Pacific/Honolulu'},
        {name: 'Samoa Standard Time (Pacific/Midway) / UTC-11', value: 'Pacific/Midway'},
        {name: 'Samoa Daylight Time (Pacific/Midway) / UTC-10', value: 'Pacific/Midway'},
        {name: 'Chamorro Standard Time (Pacific/Guam) / UTC+10', value: 'Pacific/Guam'},
    ],
});

// export default registerState;
module.exports = registerState;
