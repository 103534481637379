import React, {useEffect} from 'react';
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Button, Col, message, Radio, Row} from "antd";
import thisCss from './add_pay_subscribe.module.less';
import {useHistory} from "react-router-dom";
import {loadStripe} from "@stripe/stripe-js";
import {
    CardElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import BackBtn from "../../../component/back_btn/back_btn";

let store = require('../../../store');
let data = store.home_login;
let api = require('src/api');
let moment = require('moment');
let history;
let orderHelper = require('commonProject/helper/order_helper');
let config = require('commonProject/config/config');

//添加支付订阅


function getOrders() {
    api.order.get_order_list().then(res => {
        data.orders = res.data;
        console.log(data.orders);
    });
}

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });
        console.log(paymentMethod)
        api.user.add_pay_subscribe({pay_method_id: paymentMethod.id}).then(res => {
            if (res.code != 0) return;
            message.success(res.msg);
        })
    };

    return (
        <form onSubmit={handleSubmit}>
            <CardElement/>
            <Col align={'middle'}>
                <Button type="submit" className={'greyBtn'} disabled={!stripe}>
                    Add Payment Method
                </Button>
            </Col>
            <BackBtn/>
        </form>
    );
};

const stripePromise = loadStripe(config.stripe.publicKey);

// create observer
let AddPaySubscribe = observer(function () {
    history = useHistory();
    useEffect(() => {
        // getOrders();
    }, []);

    return (
        <Col className={thisCss.containBox}>
            <p>Payment Settings</p>
            <Elements stripe={stripePromise}>
                <CheckoutForm/>
            </Elements>
        </Col>
    );
});

export default AddPaySubscribe;
