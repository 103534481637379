import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {Button, Col, Divider, Radio} from 'antd';
import {useHistory} from "react-router-dom";
import {useParams} from "react-router";
import css from "../register_is_admin/register_is_admin.module.less";

let mobx = require('mobx');

let config = require('commonProject/config/config');
let store = require('src/store');
let api = require('src/api');
let history;
let commonUtils = require('commonProject/utils/Common');
let moment = require('moment');

let data = mobx.observable({
    is_admin: false,
    user_role_type: 'owner',
});

async function initData() {

}

let RegisterIsAdmin = observer(function () {
    let urlParams = useParams();
    history = useHistory();
    useEffect(async () => {
        await initData();
    }, []);

    return (
        <div className={css.containBox}>
            <p className={css.title}>You are ...</p>
            <Radio.Group onChange={(e) => {
                data.user_role_type = e.target.value;
            }}>
                <Radio value={'owner'} className={css.radioBox}>
                    <span className={css.accountOwner}>Account Owner: </span>

                </Radio>
                <div className={css.hintBox}>
                    <p>You set up or manage the payment account </p>
                    <div className={css.hintDesc}>* Only the account owner needs to sign up. Then you add your team
                        admin(s), who will then add all users under the team.
                    </div>
                    <div className={css.hintDesc}>* The account owner is also a team admin by default.
                    </div>
                    <div className={css.hintDesc}>* For big organizations, a team admin is a service site manager, a
                        branch manager, or a department manager, etc.
                    </div>
                </div>
                <Radio value={'visitor'} className={css.radioBox}>
                    <span className={css.potentialUser}>Potential User </span>
                </Radio>
                <div className={css.hintBox}>
                    <p>You will receive occasional email updates</p>
                    <div className={css.hintDesc}>We will update you with only important news from us to facilitate you
                        becoming a user in the future.
                    </div>
                </div>
            </Radio.Group>
            <Col align={'middle'}>
                <Button className={css.btn + ' greyBtn'} onClick={() => {
                    if (data.user_role_type == 'owner') {
                        history.push(`/register_add_info_owner?type=all_edit&user_role_type=${data.user_role_type}`);
                    } else {
                        history.push(`/potential_user_registered`);
                    }
                }}>Continue</Button>
            </Col>
        </div>
    )
});

export default RegisterIsAdmin;
