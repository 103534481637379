import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {useLocation, useParams} from "react-router";
import {useHistory} from "react-router-dom";
import css from './register_add_info_admin.module.less';
import {Button, Col, Input, Row, message, Image, Form, Radio, Select, Checkbox, InputNumber} from "antd";
import {Link} from "react-router-dom";
import {Option} from "antd/es/mentions";
import del_icon from 'src/assets/img/common/del_icon.svg';
import BackBtn from "../../../component/back_btn/back_btn";


let store = require('../../../store');
let mobx = require('mobx');
let api = require('src/api');
let data = store.register;
let dataPersist = store.persist.register;//缓存数据
let userInfo = {};
// eslint-disable-next-line react-hooks/rules-of-hooks
let history;
let commonUtils = require('commonProject/utils/Common');
let moment = require('moment-timezone');
let queryParams;

function submitUpdateUser() {
    let formData = data.formData;
    console.log('formData', JSON.parse(JSON.stringify(formData)));
    //如果设置了defaultValue然后直接使用没有调用onChange的话，就需要这里配置了
    if (!formData.email && dataPersist.email) formData.email = dataPersist.email;
    if (!formData.phone_num && dataPersist.phone_num) formData.phone_num = dataPersist.phone_num;
    if (!formData.first_name && dataPersist.first_name) formData.first_name = dataPersist.first_name;
    if (!formData.second_name && dataPersist.second_name) formData.second_name = dataPersist.second_name;
    if (!formData.time_zone && dataPersist.time_zone) formData.time_zone = dataPersist.time_zone;
    if (formData.is_summer_time != 0) formData.is_summer_time = 1;
    if (!formData.first_name
        || !formData.second_name
        || !formData.email
        || !formData.time_zone
    ) return message.error('You have some field missing');
    if (commonUtils.isEmail(formData.email)) return message.error('email format is error');
    if (commonUtils.isUSAPhone(formData.phone_num)) return message.error('phone num format is error');
    api.user.register_add_info(formData).then(res => {
        let resData = res;
        if (resData.code !== 0) return;
        message.success(resData.msg);
        // history.push('/login/intepreter');
        return history.push(`/account_owner_setting?type=team_info_read&user_id=${queryParams._id}`);
    })
}

function submitHalfUpdate(){
    let formData = data.formData;
    console.log('formData', JSON.parse(JSON.stringify(formData)));
    //如果设置了defaultValue然后直接使用没有调用onChange的话，就需要这里配置了
    if (!formData.email && dataPersist.email) formData.email = dataPersist.email;
    if (!formData.phone_num && dataPersist.phone_num) formData.phone_num = dataPersist.phone_num;
    if (!formData.first_name && dataPersist.first_name) formData.first_name = dataPersist.first_name;
    if (!formData.second_name && dataPersist.second_name) formData.second_name = dataPersist.second_name;
    if (!formData.time_zone && dataPersist.time_zone) formData.time_zone = dataPersist.time_zone;
    if (formData.is_summer_time != 0) formData.is_summer_time = 1;
    if (!formData.time_zone) return message.error('You have some field missing');
    formData._id = queryParams._id;
    api.user.register_add_info(formData).then(res => {
        let resData = res;
        if (resData.code !== 0) return;
        message.success(resData.msg);
        // history.push('/login/intepreter');
        return history.push(`/account_owner_setting?type=team_info_read&user_id=${queryParams._id}`);
    })
}


async function getTeamAdminInfo(team) {
    let res = await api.user.owner_get_user_info({user_id: team._id});
    if (res.code != 0) return;
    data.team_admin = res.data.user;
    console.log('data2', dataPersist)
}

async function onClickEdit() {
    history.push('/register_add_info_admin?type=some_edit');
}

//保存已经编辑的数据,如果页面刷新了也能显示
async function saveEdit() {
    store.persist.register_add_info_team_admin = data.formData;
    message.success('save success')
}

async function add_try_out() {
    let res = await api.user.add_try_out();
    if (res.code == 0) return message.success('add try out use success');
}

function refresh() {
    // getSubUser();
}


function getViewType(type) {
    switch (type) {
        case 'all_edit':
            return getAllEditView();
        case 'half_update':
            return getHalfUpdateView();
        default:
            return getAllEditView();
    }
}

function getAllEditView() {
    // if (commonUtils.isEmpty(data.chooseCerts)
    //     || commonUtils.isEmpty(data.chooseLangs)
    //     || commonUtils.isEmpty(dataPersist)) return <div/>

    return (
        <Col aligin='middle'>
            <p className={css.headTitleBox + ' darkBlue'}>Team Admin Profile</p>
            <Form name='registerForm' initialValues={{}} onFinish={submitUpdateUser}>
                <Form.Item className={css.headTitle} label='Your info as a team admin:' name='user_name'>
                    <div className={'flex'}>
                        <label className={'required'}/>
                        <Input placeHolder='First Name' value={dataPersist.first_name}
                               key={dataPersist.first_name}
                               onChange={(e) => {
                                   data.formData.first_name = e.target.value
                               }}/>
                    </div>
                    <div className={'flex'}>
                        <label className={'required'}/>
                        <Input placeHolder='Last Name' value={dataPersist.second_name} onChange={(e) => {
                            data.formData.second_name = e.target.value
                        }}/>
                    </div>
                    <p className={css.hint + ' hintGray'}>Use the name at your workplace.</p>
                </Form.Item>
                <Form.Item className={css.headTitle} name='email'>
                    <div className={'flex'}>
                        <label className={'required'}/>
                        <Input placeHolder='email'
                               defaultValue={dataPersist.email}
                               onChange={(e) => {
                                   data.formData.email = e.target.value
                               }}/>
                    </div>
                    <p className={css.hint + ' hintGray'}>We'll email you for essential communications and account
                        alerts.</p>
                </Form.Item>
                {dataPersist.user_role_type == 'team_admin' ? (
                    <Form.Item className={css.headTitle} name='phone'>
                        <div className={'flex'}>
                            <label className={'required'}/>
                            <Input placeHolder='phone'
                                   defaultValue={dataPersist.phone_num}
                                   onChange={(e) => {
                                       data.formData.phone_num = e.target.value
                                   }}/>
                        </div>
                        <p className={css.hint + ' hintGray'}>we need contact you</p>
                    </Form.Item>
                ) : null}
                <Form.Item className={css.headTitle} label='Business and Team Info：' name='company_info'>
                    <div className={'italic hintGray'}>Your business name and ID are populated from your "Owner"
                        account, which needs to be setup first.
                    </div>
                    <div className={'flex'}>
                        <label className={'required'}/>
                        <Input placeHolder='Your Team or Department Name' defaultValue={dataPersist.team_name}
                               onChange={(e) => {
                                   data.formData.team_name = e.target.value
                               }}/>
                    </div>
                </Form.Item>
                <Form.Item className={css.headTitle}
                           label={
                               <label className={'required'}>
                                   {`Your Time Zone ${data.formData.time_zone ?`:${data.formData.time_zone} ${moment().tz(data.formData.time_zone).format('YYYY-MM-DD HH:mm z')}`: ''}`}
                               </label>
                           }
                           name='time_zone'>
                    {/*<Select className={'italic'} onChange={(value) => {*/}
                    {/*    data.formData.time_zone = value*/}
                    {/*}}>*/}
                    {/*    {data.timeZones.map(item => {*/}
                    {/*        return (*/}
                    {/*            <Select.Option value={item.value} key={item.name}>*/}
                    {/*                {item.name}*/}
                    {/*            </Select.Option>*/}
                    {/*        )*/}
                    {/*    })}*/}
                    {/*</Select>*/}
                </Form.Item>
                <Form.Item className={css.headTitle}
                           label='Does your place observe Daylight Saving Time (or DST) in summer?'
                           name='is_summer_time'>
                    <Radio.Group defaultValue={1} onChange={(e) => {
                        data.formData.is_summer_time = e.target.value
                    }}>
                        <Radio value={1}>Yes, we observe DST in summer.</Radio>
                        <Radio value={0}>No, we do not observe DST in summer.</Radio>
                    </Radio.Group>
                </Form.Item>
                <Row align='center'>
                    <Button className={css.btn + ' greyBtn'} onClick={() => {
                        saveEdit()
                    }}>Save
                    </Button>
                </Row>
                <p/>
                <Row align='center'>
                    <Button className={css.btn + ' greyBtn'} htmlType="submit">
                        Done
                    </Button>
                </Row>
                <BackBtn/>
            </Form>
        </Col>
    )
}

function getHalfUpdateView() {
    // if (commonUtils.isEmpty(data.chooseCerts)
    //     || commonUtils.isEmpty(data.chooseLangs)
    //     || commonUtils.isEmpty(dataPersist)) return <div/>

    return (
        <Col aligin='middle'>
            <p className={css.headTitleBox + ' darkBlue'}>Team Admin Profile</p>
            <div className={css.infoBox}>
                <div className={css.title}>Your Full Name:</div>
                <div className={css.value}>{queryParams.first_name},&nbsp;{queryParams.second_name}</div>
            </div>
            <div className={css.infoBox}>
                <div className={css.title}>Your Email:</div>
                <div className={css.value}>{queryParams.email}</div>
            </div>
            <div className={css.infoBox}>
                <div className={css.title}>Your Team/Department Name:</div>
                <div className={css.value}>{queryParams.team_name}</div>
            </div>
            <div className={css.infoBox}>
                <div className={css.title}>Your Business Name:</div>
                <div className={css.value}>{userInfo.company.company_name}</div>
            </div>
            <div className={css.infoBox}>
                <div className={css.title}>Your Business Account ID:</div>
                <div className={css.value}>{userInfo.id2}</div>
            </div>
            <Form name='registerForm' initialValues={{}} onFinish={submitHalfUpdate}>
                <Form.Item className={css.headTitle}
                           label={
                               <label className={'required'}>
                                   {`Your Time Zone ${data.formData.time_zone ?`:${data.formData.time_zone} ${moment().tz(data.formData.time_zone).format('YYYY-MM-DD HH:mm z')}`: ''}`}
                               </label>
                           }
                           name='time_zone'>
                    {/*<Select className={'italic'} onChange={(value) => {*/}
                    {/*    data.formData.time_zone = value*/}
                    {/*}}>*/}
                    {/*    {data.timeZones.map(item => {*/}
                    {/*        return (*/}
                    {/*            <Select.Option value={item.value} key={item.name}>*/}
                    {/*                {item.name}*/}
                    {/*            </Select.Option>*/}
                    {/*        )*/}
                    {/*    })}*/}
                    {/*</Select>*/}
                </Form.Item>
                <Form.Item className={css.headTitle}
                           label='Does your place observe Daylight Saving Time (or DST) in summer?'
                           name='is_summer_time'>
                    <Radio.Group defaultValue={1} onChange={(e) => {
                        data.formData.is_summer_time = e.target.value
                    }}>
                        <Radio value={1}>Yes, we observe DST in summer.</Radio>
                        <Radio value={0}>No, we do not observe DST in summer.</Radio>
                    </Radio.Group>
                </Form.Item>
                {/*<Row align='center'>*/}
                {/*    <Button className={css.btn + ' greyBtn'} onClick={() => {*/}
                {/*        saveEdit()*/}
                {/*    }}>Save*/}
                {/*    </Button>*/}
                {/*</Row>*/}
                <p/>
                <Row align='center'>
                    <Button className={css.btn + ' greyBtn'} htmlType="submit">
                        Done
                    </Button>
                </Row>
                <BackBtn/>
            </Form>
        </Col>
    )
}

// create observer
let RegisterAddInfoAdmin = observer(function () {
    history = useHistory();
    userInfo = store.persist.userInfo;
    queryParams = commonUtils.query2ObjParams(useLocation().search);
    // queryParams.type 三种类型 all_edit填写所有，only_read显示内容，some_edit显示点击编辑的内容。
    data.formData.user_role_type = queryParams.user_role_type ?? 'team_admin';
    data.formData.id = queryParams._id;
    data.formData.email = queryParams.email;
    data.formData.time_zone = commonUtils.getClientTimeZone();
    dataPersist = !commonUtils.isEmpty(store.persist.register_add_info_team_admin) ? store.persist.register_add_info_team_admin : queryParams;

    console.log('dataPersist', JSON.parse(JSON.stringify(dataPersist)), queryParams)
    useEffect(() => {
        getTeamAdminInfo(queryParams);
    }, []);

    return getViewType(queryParams.type);
});

export default RegisterAddInfoAdmin;
