import React, {useEffect} from 'react';
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Button, Col, Divider, message, Radio, Rate, Row} from "antd";
import css from './order_evaluate.module.less';
import {useHistory} from "react-router-dom";
import {useParams, useRouteMatch, useLocation} from "react-router";
import {loadStripe} from '@stripe/stripe-js';
import TextArea from "antd/lib/input/TextArea";

let store = require('../../store');
let data;
let api = require('src/api');
let moment = require('moment');
let history;
let config = require('commonProject/config/config');
let commonUtils = require('commonProject/utils/Common');
let order_id;

//订单评价页面

async function getOrder(id) {
    let res = await api.order.get_order_list({_id: id});
    if (res.code != 0 || res.data.length == 0) return;
    data.order = res.data.item[0];
}

function initData() {
    getOrder(order_id);
}

//提交评价  type:sample简单   detail详细
async function submit(type) {
    let rate = null;
    let params = {
        type: 1,//1 用户给口译师的评价   2口译师给用户的评价
        _id: order_id,
    };
    if (type == 'sample') {
        if (!data.rate) return message.error(`rate can't empty`)
        rate = data.rate;
        params.rate_effective = rate;
        params.rate_efficiency = rate;
        params.rate_professionality = rate;
        params.rate_vocabulary = rate;
        params.rate_voice_accent = rate;
    } else if (type == 'detail') {
        if (!data.rate_effective || !data.rate_effective || !data.rate_voice_accent) return message.error(`rate can't empty`);
        rate = (Number(data.rate_professionality).mul(0.25))
            .add(Number(data.rate_effective).mul(0.25))
            .add(Number(data.rate_vocabulary).mul(0.25))
            .add(Number(data.rate_voice_accent).mul(0.25)).toFixed(1);
        params.rate_effective = data.rate_effective;
        params.rate_efficiency = data.rate_effective;
        params.rate_professionality = data.rate_professionality;
        params.rate_vocabulary = data.rate_vocabulary;
        params.rate_voice_accent = data.rate_voice_accent;
    }
    params.rate = rate;
    if (data.evaluate_desc) params.evaluate_desc = data.evaluate_desc;
    let res = await api.order.add_order_evaluate(params);
    if (res.code != 0) return;
    message.success('evaluate success');
    commonUtils.historyPushRefresh(history,'/');
}

let Order_evaluate = observer(function () {
    data = store.order_active;
    history = useHistory();
    let params = commonUtils.query2ObjParams(useLocation().search);
    order_id = params.id;
    useEffect(() => {
        initData();
    }, []);

    if (commonUtils.isEmpty(data.order)) return <div/>
    return (
        <Col className={css.containBox}>
            <p>You chose: {data.order.interpret_lang_user_need.language_name}-
                {data.order.support_service_type.type_name}</p>
            <p>Interpreter ID: {data.order.interpreter._id} ({data.order.interpreter.user_name})</p>
            <p>Start/End Time:{moment(data.order.real_start_time).format('yyyy-MM-DD HH:mm')}
                ~ {moment(data.order.real_end_time).format('yyyy-MM-DD HH:mm')}</p>
            <Divider/>
            {
                !data.is_show_detail ?
                    <div>
                        <p>Rate this service call:</p>
                        <p>(mandatory for improving service quality)</p>
                        <Rate onChange={value => {
                            data.rate = value;
                            console.log(data.rate, value)
                        }
                        }/>
                        <Button type='primary' onClick={() => {
                            submit('sample')
                        }}>submit</Button>
                        <Divider/>
                        <p>More ratings & comments:</p>
                        <p>(This is welcome and appreciative)</p>
                        <Button type='primary' onClick={() => {
                            data.is_show_detail = true
                        }}>show me</Button>
                    </div> :
                    <div>
                        <p>More rating & comments:</p>
                        <p>(We appreciative your input)</p>
                        <div>
                            <span>{`Professionality (25%): accurate, complete, communicative, respect, patience, empathy, attention.`}</span>
                            <Rate onChange={value => {
                                data.rate_professionality = value;
                            }
                            }/>
                        </div>
                        <div>
                            <span>{`Efficiency & Effectiveness (25%): fluence, active listening, speedcultural brokering, less repeating, coordination.`}</span>
                            <Rate onChange={value => {
                                data.rate_effective = value;
                            }
                            }/>
                        </div>
                        <div>
                            <span>{`Vocabulary (25%): subject matters vocabulary (medical, legal, etc)`}</span>
                            <Rate onChange={value => {
                                data.rate_vocabulary = value;
                            }
                            }/>
                        </div>
                        <div>
                            <span>{`Voice & Accent (25%): clear, proper speed and tone, pronunciation.`}</span>
                            <Rate onChange={value => {
                                data.rate_voice_accent = value;
                            }
                            }/>
                        </div>
                        <p>Comment (optional):</p>
                        <TextArea onChange={e => {
                            data.evaluate_desc = e.target.value;
                        }}/>
                        <Button type='primary' onClick={() => {
                            submit('detail')
                        }}>submit</Button>
                    </div>
            }


        </Col>
    );
});

export default Order_evaluate;
