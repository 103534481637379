import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {useParams} from "react-router";
import {useHistory} from "react-router-dom";
import {Button, Col, Input, Row, message, Form, Radio, Select, Checkbox, Divider, Modal, Image} from "antd";
import {Link} from "react-router-dom";
import css from "./register_email.module.less";
import right_svg from 'src/assets/img/common/right.svg';
import CountDown from "ant-design-pro/lib/CountDown";

let store = require('../../../store');
let api = require('src/api');
let data = store.persist.register;
// eslint-disable-next-line react-hooks/rules-of-hooks
let history;
let commonUtils = require('commonProject/utils/Common');
let moment = require('moment');


//发送邮件验证
async function verify_email_send_code() {
    let email = data.email;
    if (commonUtils.isEmpty(email)) return message.error('email num is empty');
    let res = await api.interpreter.verify_email_send_code({email: email, is_verify_new: true});
    if (res.code != 0) return;
    message.success('send code success');
    data.email_code_time = new Date().getTime() + commonUtils.config.valid_code_time;
}

async function verify_email() {
    let email = data.email;
    if (commonUtils.isEmpty(email)) return message.error('email is empty');
    let res = await api.interpreter.verify_email({email: email, email_code: data.email_code});
    if (res.code != 0) return;
    let resResult = await register();
    if (!resResult) return;
    store.register.is_show_verify_ok = true;
}

async function register() {
    let res = await api.user.register(data);
    if (res.code != 0) return false;
    message.success(res.msg);
    return true;
}

function initData() {
    // data = store.persist.register;
}

// create observer
let RegisterPhone = observer(function () {
    history = useHistory();
    useEffect(() => {
        initData();
    }, []);

    if (commonUtils.isEmpty(data)) return <div/>;
    return (
        <Col className={css.containBox}>
            <p className={css.headTitleBox + ' wineRed'}>Interpreter Sign Up</p>
            <span className={css.loginDesc + ' wineRed'}>Sign up with email (email is your username):</span>
            <Input placeholder='Email' defaultValue={data.email} onChange={(e) => {
                data.email = e.target.value
            }}/>
            <Input.Password placeholder='Password' onChange={(e) => {
                data.user_password = e.target.value
            }}/>
            <p className={css.hint + ' hintGray'}>We'll text or call you for the sign in code.</p>
            <div className={css.phoneCodeBox} align='middle'>
                <p className={css.loginDesc + ' wineRed'}>Invitation Code:</p>
                <Input placeholder='invitation code'
                       className={css.phoneCode}
                       defaultValue={data.email_code}
                       onChange={(e) => {
                           data.invite_code = e.target.value
                       }}/>
            </div>
            <p className={css.hint + ' hintGray'}>(new user registrations by invitation only)</p>
            <div className={css.phoneCodeBox} align='middle'>
                <p className={css.loginDesc + ' wineRed'}>you email code:</p>
                <Input placeholder='email code'
                       className={css.phoneCode}
                       defaultValue={data.email_code}
                       onChange={(e) => {
                           data.email_code = e.target.value
                       }}/>
                {data.email_code_time == 0 ?
                    <Button onClick={verify_email_send_code}>send code</Button>
                    : <CountDown
                        style={{color: 'red'}}
                        format={time => {
                            return moment(time).format('mm:ss');
                        }}
                        target={data.email_code_time}
                        onEnd={() => {
                            data.email_code_time = 0;
                        }}/>}
            </div>
            <p className={css.voiceCode + ' hintGray'}>I did NOT get the text code, call my number.</p>
            {
                store.register.is_show_verify_ok ?
                    (
                        <div>
                            <Col align='middle'>
                                <Image className={css.greenRight} src={right_svg} preview={false}/>
                            </Col>
                            <p className={css.loginDesc + ' wineRed'}>Registration with your email was successful! </p>
                            <p className={css.loginDesc + ' wineRed'}>What's next?</p>
                            <p>Please check your mail box to validate your email address by clicking the link. The
                                email might be in your spam folder. Please click "Not Spam" or add
                                noReply@InterpreTown.com to your contact so that future emails won't go to your spam
                                folder.</p>
                            <Button
                                type='primary'
                                className={css.btn}
                                style={{width: '30vw'}}
                                onClick={() => {
                                    commonUtils.historyPushRefresh(history, '/');
                                }}>OK</Button>
                        </div>
                    ) : (
                        <div>
                            <p>(By clicking Continue, you agree with
                                <span className={css.teamCondition} onClick={() => {
                                    Modal.info({
                                        content: 'Terms and Conditions',
                                    })
                                }}> Terms and Conditions </span>
                                for data privacy)</p>
                            <Button
                                onClick={verify_email}
                                type='primary'
                                className={css.btn}>Continue</Button>
                        </div>
                    )
            }

        </Col>
    );
});

export default RegisterPhone;
