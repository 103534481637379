let config = require('commonProject/config/config');
let axios = require('src/utils/request').default;
let store = require('src/store');

let user = {
    login(params) {
        return axios.post('/webapp/user/login', params);
    },
    register(params) {
        return axios.post('/webapp/user/register', params);
    },
    //注册时候添加用户信息
    async register_add_info(params) {
        let res = await axios.post('/webapp/user/register_add_info', params)
        if (res.code == 0) {
            this.get_user_info();
        }
        return res;
    },
    //重置密码
    reset_password(params) {
        return axios.post('/webapp/user/reset_password', params);
    },
    //更新用户
    async update_user(params) {
        let res = await axios.post('/webapp/user/update_user', params);
        if (res.code == 0) {
            this.get_user_info();
        }
        return res;
    },
    //更新用户
    async owner_update_user(params) {
        return await axios.post('/webapp/user/owner_update_user', params);
    },
    //获取用户数据 获取成功后自动保存到缓存里面
    async get_user_info(params) {
        let res = await axios.get('/webapp/user/get_user_info', {params});
        console.log('user ', res.data);
        if (res.code == 0) {
            store.persist.userInfo = res.data;
            console.log('user ', res.data);
        }
        return res;
    },
    add_pay_subscribe(params) {
        return axios.post('/webapp/user/add_pay_subscribe', params);
    },
    recharge(params) {
        return axios.post('/webapp/user/recharge', params);
    },
    get_sub_users(params) {
        return axios.post('/webapp/user/get_sub_users', params);
    },
    admin_add_sub_user(params) {
        return axios.post('/webapp/user/admin_add_sub_user', params);
    },
    admin_del_sub_user(params) {
        return axios.post('/webapp/user/admin_del_sub_user', params);
    },
    verify_phone_send_code(params) {
        params.role_type = 'user';
        return axios.post('/webapp/user/verify_phone_send_code', params,{timeout: 25 * 1000});
    },
    verify_email_send_code(params) {
        params.role_type = 'user';
        return axios.post('/webapp/user/verify_email_send_code', params,{timeout: 25 * 1000});
    },
    verify_landline_send_code(params) {
        params.role_type = 'user';
        return axios.post('/webapp/user/verify_landline_send_code', params,{timeout: 25 * 1000});
    },
    add_try_out(params) {
        return axios.post('/webapp/user/add_try_out', params);
    },
    owner_get_user_info(params) {
        return axios.get('/webapp/user/owner_get_user_info', {params});
    },
    update_user_max_auto_pay(params) {
        return axios.post('/webapp/user/update_user_max_auto_pay', params);
    },
    verify_password(params) {
        return axios.post('/webapp/user/verify_password', params);
    },
    get_user_notify_config(params) {
        return axios.get('/webapp/user_notify_config/get_user_notify_config', params);
    },
    update_user_notify_config(params) {
        return axios.post('/webapp/user_notify_config/update_user_notify_config', params);
    },
    add_user_notify_config(params) {
        return axios.post('/webapp/user_notify_config/add_user_notify_config', params);
    },
    get_user_notify_list(params) {
        return axios.get('/webapp/user_notify/get_user_notify_list', params);
    },
    del_user_notify(params) {
        return axios.post('/webapp/user_notify/del_user_notify', params);
    },
    owner_add_self_team_admin(params) {
        return axios.post('/webapp/user/owner_add_self_team_admin', params);
    },
    add_file(params){
        return axios.post(`${config.url.simple_service_host}/file/add_file`,params);
    },
    get_file(params){
        return axios.post(`${config.url.simple_service_host}/file/get_file`,params);
    },
    contact_us(params){
        return axios.post(`/webapp/user/contact_us`,params);
    }
}

module.exports = user;
