import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {useParams} from "react-router";
import {Button, Col, Input, Row, message, Divider} from "antd";
import {Link, useHistory} from "react-router-dom";
import css from './reset_psw.module.less';
import CountDown from "ant-design-pro/lib/CountDown";

let store = require('src/store');
let data = store.reset_psw;
let api = require('src/api');
let history;
let commonUtils = require('commonProject/utils/Common');
let moment = require('moment');


function resetPsw() {
    data.user_name = data.email;
    let params = {
        user_name: data.user_name,
        user_password: data.user_password,
        email_code: data.email_code,
    };
    api.user.reset_password(params).then(res => {
        if (res.code != 0) return;
        message.success('reset password success');
        window.location.href = '/login/user';
    })
}

//发送邮件验证
async function verify_email_send_code() {
    let email = data.email;
    if (commonUtils.isEmpty(email)) return message.error('email is empty');
    let res = await api.interpreter.verify_email_send_code({email: email, is_verify_new: false});
    if (res.code != 0) return;
    message.success('send code success');
    data.email_code_time = new Date().getTime() + commonUtils.config.valid_code_time;

}

//发送手机验证
async function verify_phone_send_code() {
    let phone_num = data.phone_num;
    if (commonUtils.isEmpty(phone_num)) return message.error('phone num is empty');
    let res = await api.interpreter.verify_phone_send_code({phone_num: phone_num, is_verify_new: false});
    if (res.code != 0) return;
    message.success('send code success');
    data.email_code_time = new Date().getTime() + commonUtils.config.valid_code_time;
}


async function initData() {

}

// create observer
let ResetPsw = observer(function () {
    let urlParams = useParams();
    history = useHistory();

    useEffect(async () => {
        await initData();
    }, []);

    return (
        <Col className={css.containBox}>
            <p className={css.headTitleBox + ' wineRed'}>Password Reset</p>
            <span className={css.loginDesc + ' wineRed'}>The email you used for registration?</span>
            <Input placeholder='Email' defaultValue={data.email} onChange={(e) => {
                data.email = e.target.value
            }}/>
            <p className={css.hint + ' hintGray'}>We will send a link to your email for password reset.</p>
            <div className={css.phoneCodeBox} align='middle'>
                <p className={css.loginDesc + ' wineRed'}>Enter you code:</p>
                <Input placeholder='phone code'
                       className={css.phoneCode}
                       defaultValue={data.email_code}
                       onChange={(e) => {
                           data.email_code = e.target.value
                       }}/>
                {data.email_code_time == 0 ?
                    <Button onClick={verify_email_send_code}>send code</Button>
                    : <CountDown
                        style={{color: 'red'}}
                        format={time => {
                            return moment(time).format('mm:ss');
                        }}
                        target={data.email_code_time}
                        onEnd={() => {
                            data.email_code_time = 0;
                        }}/>}
            </div>
            <span className={css.loginDesc + ' wineRed'}>password:</span>
            <Input placeholder='phone code'
                   className={css.psw}
                   onChange={(e) => {
                       data.user_password = e.target.value
                   }}/>
            <Button
                disabled={!data.email_code || !data.email}
                onClick={resetPsw} type='primary'
                className={css.btn}>Submit</Button>
            <p className={css.resetDesc + ' wineRed'}>An email with password reset link was sent to your mail box, if
                your email is registered with us.</p>
        </Col>
    );
});

export default ResetPsw;
