import React, {useState, useEffect} from 'react';
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Button, Card, Checkbox, Col, Divider, Image, List, message, Modal, Radio, Row} from 'antd';
import {Link, useHistory} from "react-router-dom";
import OPISvg from 'src/assets/img/add_order/OPI_logo.svg';
import prebookingSvg from 'src/assets/img/add_order/prebooking.svg';
import css from "../choose_support_service/choose_support_service.module.less";

let store = require('src/store');
let config = require('commonProject/config/config');
let commonUtils = require('commonProject/utils/Common');
let api = require('src/api');
let history;
let moment = require('moment');

function getSupportService() {
    api.order.get_support_service_type_list().then(res => {
        store.add_order.chooseSupportService = res.data;
    })
}

//马上就要使用口译服务
function requestOrderNow() {
    let formData = store.persist.add_order.formData;
    let values = Object.values(formData);
    values.map(item => {
        if (!item) return message.error('params is error');
    })
    let certs = formData.chooseCerts.map(item => {
        return item._id;
    });
    let params = {
        interpret_lang_user_need: formData.interpret_lang_user_need,
        support_service_type: formData.support_service_type._id,
        subscribe_type: 2,//2是实时的
        subscribe_time_length: 60,//实时的按照1小时预约计算
        subscribe_time: moment().add(5, "minutes"),//实时的预约时间是在5分钟内，因为会有短信或者订单超时的功能需要处理
        certs: certs.join(','),
    };

    api.order.add_order(params).then(res => {
        if (res.code == 0) {
            Modal.success({content: 'add new order success'});
            clearFormData();
            return history.push(`/prepare_order_start?id=${res.data._id}`);
        }
    });

}


function clearFormData() {
    let formData = store.persist.add_order.formData;
    let values = Object.values(formData);
    values.map(item => {
        item = null;
    })
}

// create observer
let ChoosePrebooking = observer(function () {
    history = useHistory();
    let formData = store.persist.add_order.formData;

    useEffect(() => {
        // getSupportService();
    }, [])

    return (
        <Col className={css.containBox}>
            <p className={css.headTitle}>step 4 of 4</p>
            <p className={css.headTitle1}>Pre-booking, or now</p>
            <Divider className={`gray ${css.divider}`}/>
            <span>Language Chosen: <span className={css.headTitle2}>{`${formData.chooseLang.language_name}`}
                {formData.chooseCerts.length > 0 ? `-${formData.chooseCerts[0].cert_desc.split(' ')[0]}` : ``}</span>
            </span>
            <div>
                <br/>
                <br/>
                <Card className={css.headTitle3}>
                    <Row align='middle' onClick={() => {
                        history.push('/add_order/prebooking_time')
                    }}>
                        <Image src={prebookingSvg}/>
                        <Col style={{'margin-left': '10px'}}>
                            <span className={css.headTitle4}>Pre-booking</span>
                            <p className={css.headTitle5}>Pre-booking is encouraged</p>
                            <span className={css.headTitle6}>(enjoy 1% - 3% discount)</span>
                        </Col>
                    </Row>
                </Card>
                <br/>
                <Card className={css.headTitle3}>
                    <Row align='middle'>
                        <Image src={OPISvg}/>
                        <Col style={{'margin-left': '10px'}}>
                            <p className={css.headTitle} onClick={() => {
                                requestOrderNow();
                                // Modal.info({
                                //     content: 'are you sure add new order right now!',
                                //     onOk: requestOrderNow
                                // })
                            }}>I need service now</p>
                        </Col>
                    </Row>
                </Card>
            </div>
        </Col>
    );
})

export default ChoosePrebooking;
