import React from "react";
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Col, Dropdown, Image, Menu, message, Modal, Row} from "antd";
// import './header.module.less';
import css from './back_btn.module.less'
import menuSvg from 'src/assets/img/header/menu.svg';
import {useHistory} from "react-router-dom";
import {useLocation} from "react-router";

let store = require('src/store');
let history;
let location;
let menu;
let commonUtils = require('commonProject/utils/Common');

let BackBtn = observer(function () {
    history = useHistory();
    location = useLocation();
    console.log(location.pathname);
    return (

        <Row align={'center'}>
            <div className={css.btn} onClick={()=>{
                history.go(-1)
            }}>Back</div>
        </Row>
    )
})

export default BackBtn;
