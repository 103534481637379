import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Col, Dropdown, Image, Menu, message, Modal, Row} from "antd";
// import './header.module.less';
import headerCss from './white_ip_auth.module.less'
import menuSvg from 'src/assets/img/header/menu.svg';
import {useHistory} from "react-router-dom";
import {useLocation} from "react-router";

let store = require('src/store');
let history;
let location;
let menu;
let commonUtils = require('commonProject/utils/Common');
let api = require('src/api');
let axios = require('axios');

async function init() {
    let userInfo = store.persist.userInfo;
    const script = document.createElement("script");

    script.src = "http://pv.sohu.com/cityjson?ie=utf-8";
    script.async = true;
    document.body.appendChild(script);
    await commonUtils.timeSleep(2500);
    var ip = window.returnCitySN["cip"];
    // message.info(ip);
    if (!ip) return;
    var allowIP = [
        '112.50.42.161',
        '112.51.58.132',
        '112.51.58.112',
        '75.167.178.141',
        '112.50.41.103',
    ]; //允许访问
    var flag = false;

    console.log(ip)
    for (var i = 0; i < allowIP.length; i++) {
        if (ip == allowIP[i]) {
            flag = true;
            break;
        }
    }

    //暂时关闭白名单验证
    flag = true;

    if (!flag) {
        alert("您无权限访问该网站。");
        closewin();
    }

    function closewin() {
        history.go(-1);
        // if (navigator.userAgent.indexOf("Firefox") != -1 || navigator.userAgent.indexOf("Chrome") != -1) {
        //     window.location.href = "about:blank"
        //     window.location.target = "_blank"
        //     window.location.rel = "external nofollow"
        //     window.close();
        // } else {
        //     window.opener = null;
        //     window.open("", "_self");
        //     window.close();
        // }
    }
}

let WhiteIpAuth = observer(function () {
    history = useHistory();
    location = useLocation();
    useEffect(() => {
        init();
    }, []);
    return (
        <span>
        </span>
    )
})

export default WhiteIpAuth;
