let isProEnv = process.env.NODE_ENV === 'production';
let config = {
    url: {
        baseUrl: isProEnv ? 'http://test.dingfu.ink:8002' : 'http://localhost:8002',
        adminBaseUrl: isProEnv ? 'http://test.dingfu.ink:8001' : 'http://localhost:8001',
        imgDefaultUrl: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        simple_service_host: isProEnv ? 'http://test.dingfu.ink:8003' : 'http://localhost:8003',
        user_client_url: isProEnv ? 'http://user.dingfu.ink' : 'http://localhost:3010',
        interpreter_client_url: isProEnv ? 'http://interpreter.dingfu.ink' : 'http://localhost:3011',
    },
    stripe: {
        publicKey: 'pk_test_51IFquNEx2POS8nAPyYW9r2mbr1BOjeYxaYPj9VGhb05JfFP0TGhpRX0tS4wFqvga9xnaMzUet6Ix4XXMRT2fO2w200sS2Ed1az',
    },
    websocket: {
        url: isProEnv ? 'ws://test.dingfu.ink:8083' : 'ws://localhost:8083',
        action_type: {
            receive_order: 'receive_order',
            receive_chat_msg: 'receive_chat_msg',
            receive_order_change: 'receive_order_change',
        }
    },
    valid_code_time: 120 * 1000,//验证码倒计时时间  单位毫秒
}
module.exports = config;
