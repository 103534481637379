// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".containBox_3RIgBh {\n  text-align: center;\n}\n.containBox_3RIgBh .headTitle_kickpn {\n  font-size: 20px;\n  color: #122B88;\n  font-weight: bold;\n}\n.containBox_3RIgBh .headTitle1_22k3X3 {\n  font-size: 23px;\n  font-family: Roboto-Light;\n}\n.containBox_3RIgBh .headTitle2_3kUw5c {\n  text-align: center;\n}\n.containBox_3RIgBh .headTitle3_1au8m5 {\n  font-family: Roboto-Light;\n  color: #122B88;\n  font-size: 16px;\n  font-weight: bold;\n}\n.containBox_3RIgBh .headTitle4_17IzZl {\n  font-size: 20px;\n  color: #122B88;\n}\n.containBox_3RIgBh .headTitle5_2iAQk3 {\n  font-size: 20px;\n  font-family: Roboto-regular;\n  font-weight: bold;\n}\n", "",{"version":3,"sources":["webpack://src/pages/add_order/choose_prebooking/prebooking_time/prebooking.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAFA;EAII,eAAA;EACA,cAAA;EACA,iBAAA;AACJ;AAPA;EAUI,eAAA;EACA,yBAAA;AAAJ;AAXA;EAeI,kBAAA;AADJ;AAdA;EAmBI,yBAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;AAFJ;AApBA;EA0BI,eAAA;EACA,cAAA;AAHJ;AAxBA;EA8BI,eAAA;EACA,2BAAA;EACA,iBAAA;AAHJ","sourcesContent":[".containBox {\n  text-align: center;\n\n  .headTitle {\n    font-size: 20px;\n    color: #122B88;\n    font-weight: bold;\n  }\n\n  .headTitle1 {\n    font-size: 23px;\n    font-family: Roboto-Light;\n  }\n\n  .headTitle2 {\n    text-align: center;\n  }\n\n  .headTitle3 {\n    font-family: Roboto-Light;\n    color: #122B88;\n    font-size: 16px;\n    font-weight: bold;\n  }\n\n  .headTitle4{\n    font-size: 20px;\n    color: #122B88;\n  }\n  .headTitle5{\n    font-size: 20px;\n    font-family: Roboto-regular;\n    font-weight: bold;\n\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containBox": "containBox_3RIgBh",
	"headTitle": "headTitle_kickpn",
	"headTitle1": "headTitle1_22k3X3",
	"headTitle2": "headTitle2_3kUw5c",
	"headTitle3": "headTitle3_1au8m5",
	"headTitle4": "headTitle4_17IzZl",
	"headTitle5": "headTitle5_2iAQk3"
};
export default ___CSS_LOADER_EXPORT___;
