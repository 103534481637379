// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".containBox_45-_wW .headTitle_39QBym {\n  text-align: center;\n  font-size: 26px;\n  font-family: Roboto-regular;\n  color: #122B88;\n  font-weight: bold;\n}\n.containBox_45-_wW .headTitle1_3GpNXh {\n  font-size: 18px;\n  text-indent: 2em;\n}\n.containBox_45-_wW .middle_UTh0rs {\n  font-size: 20px;\n  font-family: Roboto-regular;\n  color: #122B88;\n  font-weight: bold;\n}\n.containBox_45-_wW .middle1_32AuT- {\n  font-style: italic;\n  color: lightgray;\n}\n", "",{"version":3,"sources":["webpack://src/pages/edit_order/edit_order.module.less"],"names":[],"mappings":"AAAA;EAGI,kBAAA;EACA,eAAA;EACA,2BAAA;EACA,cAAA;EACA,iBAAA;AADJ;AANA;EAWI,eAAA;EACA,gBAAA;AAFJ;AAVA;EAmBI,eAAA;EACA,2BAAA;EACA,cAAA;EACA,iBAAA;AANJ;AAhBA;EA0BG,kBAAA;EACC,gBAAA;AAPJ","sourcesContent":[".containBox {\n\n  .headTitle{\n    text-align: center;\n    font-size: 26px;\n    font-family: Roboto-regular;\n    color: #122B88;\n    font-weight: bold;\n  }\n\n  .headTitle1{\n    font-size: 18px;\n    text-indent:2em;\n  }\n\n  .headTitle2{\n  }\n\n  .middle{\n    font-size: 20px;\n    font-family: Roboto-regular;\n    color: #122B88;\n    font-weight: bold;\n  }\n\n  .middle1{\n   font-style: italic;\n    color: lightgray;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containBox": "containBox_45-_wW",
	"headTitle": "headTitle_39QBym",
	"headTitle1": "headTitle1_3GpNXh",
	"middle": "middle_UTh0rs",
	"middle1": "middle1_32AuT-"
};
export default ___CSS_LOADER_EXPORT___;
