import React, {useEffect} from 'react';
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Button, Col, Divider, message, Radio, Row} from "antd";
import css from './cancel_order.module.less';
import {useHistory} from "react-router-dom";
import {useParams, useRouteMatch, useLocation} from "react-router";
import payResultCss from "../pay_result/pay_result.module.less";

let store = require('../../store');
let data = store.cancel_order;
let api = require('src/api');
let moment = require('moment');
let history;
let config = require('commonProject/config/config');
let commonUtils = require('commonProject/utils/Common');

function getOrder(id) {
    api.order.get_order_list({_id: id}).then(res => {
        if (res.code != 0 || res.data.length == 0) return;
        data.order = res.data.item[0];
    })
}

function cancelOrder(id) {
    let params = {_id: id, status: 0};
    api.order.update_order(params).then(res => {
        if (res.code != 0) return;
        message.success('cancel order success');
        commonUtils.historyPushRefresh(history, '/');
    });
}

// create observer
let Cancel_order = observer(function () {
    history = useHistory();
    let params = commonUtils.query2ObjParams(useLocation().search);
    let order_id = params.id;
    useEffect(() => {
        getOrder(order_id);
    }, []);

    if (commonUtils.isEmpty(data.order)) return <div/>
    let timeLength = moment.duration(data.order.subscribe_time_length, 'minutes');
    return (
        <Col className={css.containBox}>
            <p className={css.headTitle}>Cancel Your Pre-booking</p>
            <p className={css.headTitle1}>summary</p>
            <div>
                <p className={css.headTitle2}>{data.order.interpret_lang_user_need.language_name + '(Language)'}</p>
                <p className={css.headTitle2}>{data.order.certs[0]?.cert_name + '(certification)'}</p>
                <p className={css.headTitle4}>{data.order.support_service_type.type_name + '(format)'}</p>
                <p className={css.headTitle2}>{moment(data.order.subscribe_time).format('MM/DD/yyyy dddd') + '(format)'}</p>
                <p className={css.headTitle2}>{timeLength.hours() + ' hr ' + timeLength.minutes() + ' min' + '(duration)'}</p>
                <p className={css.headTitle2}>{moment(data.order.subscribe_time).format('HH:mm:ss') + '(start time)'}</p>
            </div>
            <Button type='primary' onClick={() => {
                cancelOrder(order_id)
            }} className={css.headTitle5}>Continue to cancel</Button>
        </Col>
    );
})

export default Cancel_order;