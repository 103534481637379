let store = {
    login: require('./login'),
    register: require('./register'),
    order_list: require('./order_list'),
    add_order: require('./add_order'),
    edit_order: require('./edit_order'),
    cancel_order: require('./cancel_order'),
    prepare_order_start: require('./prepare_order_start'),
    order_active: require('./order_active'),
    order_evaluate: require('./order_evaluate'),
    order_detail: require('./order_detail'),
    reset_psw: require('./reset_psw'),

    persist: require('./persist').persistState,//只有要保存的持久化的数据才放到persist里面
};
module.exports = store