import React, {useState, useEffect} from 'react';
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Button, Card, Checkbox, Col, Divider, Image, List, message, Radio, Row} from 'antd';
import {Link, useHistory} from "react-router-dom";
import css from './choose_cert.module.less'

let store = require('src/store');
let config = require('commonProject/config/config');
let api = require('src/api');
let commonUtils = require('commonProject/utils/Common');
let formData;

function initData() {
    getCerts();
    let lastOrder = store.persist.user_last_order;
    if (!commonUtils.isEmpty(lastOrder)) {
        formData.chooseCerts = lastOrder.certs;
    }
    // console.log('chooseCerts', JSON.parse(JSON.stringify(formData.chooseCerts)), JSON.parse(JSON.stringify(lastOrder)))
}

function getCerts() {
    api.order.get_cert_list().then(res => {
        store.add_order.chooseCerts = res.data;
    })
}

//是否是默认证书
function isDefaultCert(cert) {
    if (commonUtils.isEmpty(store.persist.user_last_order)) return;
    for (let itemCert of store.persist.user_last_order.certs) {
        if (itemCert._id == cert._id) {
            console.log('is default cert', true)
            return true;
        }
    }
    return false;
}

// create observer
let ChooseCert = observer(function () {
    let history = useHistory();
    formData = store.persist.add_order.formData;

    useEffect(() => {
        initData();
    }, [])

    if (commonUtils.isEmpty(store.add_order.chooseCerts)) return <div/>
    return (
        <Col className={css.containBox}>
            <p className={css.headTitle}>step 2 of 4</p>
            <span className={css.headTitle1}>Specialty certificate</span>
            <Divider/>
            <p>{`Language Chosen: `}
                <span className={css.headTitle2}>{`${formData.chooseLang?.language_name}`}</span>
            </p>
            <Divider/>
            <p>Choose a specialty certificate if required, or skip:</p>
            {store.add_order.chooseCerts.length > 0 ?
                (
                    store.add_order.chooseCerts?.map(item => {
                        return (
                            <Checkbox className={css.certItem}
                                      defaultChecked={isDefaultCert(item)}
                                      onChange={(e) => {
                                          if (e.target.checked) {
                                              if (!formData.chooseCerts.includes(item)) formData.chooseCerts.push(item)
                                          } else formData.chooseCerts.remove(item)
                                      }}
                                      value={item}>{`${item.cert_desc}(${item.cert_name})`}</Checkbox>
                        )
                    })
                ) : null
            }
            {/*{*/}
            {/*    store.add_order.chooseCerts.length > 0 ?(*/}
            {/*        <Checkbox.Group className={css.certsBox} onChange={arr => {*/}
            {/*            formData.chooseCerts = arr;*/}
            {/*        }}>*/}
            {/*            {*/}
            {/*                store.add_order.chooseCerts.map(item => {*/}
            {/*                    return (*/}
            {/*                        <Checkbox className={css.certItem} defaultChecked={isDefaultCert(item)}*/}
            {/*                                  value={item}>{`${item.cert_desc}(${item.cert_name})`}</Checkbox>*/}
            {/*                    )*/}
            {/*                })*/}
            {/*            }*/}
            {/*        </Checkbox.Group>*/}
            {/*    ):null*/}
            {/*}*/}

            <Col offset={6} className={css.btnBox}>
                <Button type='primary' disabled={formData.chooseCerts.length == 0}
                        className={css.btnLeft}
                        onClick={e => {
                            history.push('/add_order/choose_support_service');
                        }}>{'Sure'}</Button>
                <Button type='primary' disabled={formData.chooseCerts.length > 0} onClick={e => {
                    history.push('/add_order/choose_support_service');
                }}>{'Skip'}</Button>
            </Col>
            <p/>
        </Col>
    );
})

export default ChooseCert;