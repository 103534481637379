import React from "react";
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Col, Dropdown, Image, Menu, message, Modal, Row} from "antd";
// import './header.module.less';
import headerCss from './header.module.less'
import menuSvg from 'src/assets/img/header/menu.svg';
import {useHistory} from "react-router-dom";
import {useLocation} from "react-router";
import WhiteIpAuth from "../white_ip_auth/white_ip_auth";

let store = require('src/store');
let history;
let location;
let menu;
let commonUtils = require('commonProject/utils/Common');
let loginHelper = require('../../utils/helper/login_helper');

function init() {
    let userInfo = store.persist.userInfo;
    menu = (
        <Menu>
            <Menu.Item>
                {!store.persist.token ? (
                    <div>
                        <p onClick={() => {
                            history.push('/company_home')
                        }}>Company home</p>
                    </div>
                ) : (
                    <div>
                        <p onClick={() => {
                            if (!userInfo.user_role_type) return history.push('/register_is_admin');
                            switch (userInfo.user_role_type) {
                                case 'owner':
                                    history.push('/user_func_menu')
                                    break;
                                case 'team_admin':
                                    history.push('/register_add_info_admin?type=only_read')
                                    break;
                                case 'employee':
                                    history.push('/register_add_info?type=only_read')
                                    break;
                                case 'visitor':
                                    message.info('visitor no setting');
                                    break;
                            }
                        }}>{userInfo.user_role_type == 'owner' ? 'Owner Settings' : (userInfo.user_role_type == 'admin' ? 'Admin Settings' : 'Settings')}</p>
                        <p onClick={() => {
                            history.push('/company_home')
                        }}>Company home</p>
                        {userInfo.user_role_type == 'owner' ? (
                            <p onClick={() => {
                                history.push('/payment')
                            }}>Payment</p>
                        ) : null}
                        <p onClick={logout}>Log Out</p>
                    </div>
                )}

            </Menu.Item>
        </Menu>
    );
}

function logout() {
    loginHelper.logout(history);
}

let Header = observer(function () {
    history = useHistory();
    location = useLocation();
    console.log(location.pathname);
    let isCompanyHome = false;//是否是公司主页
    if (['/company_home'].includes(location.pathname)) {
        isCompanyHome = true;
    }
    init();
    if (isCompanyHome) return null;
    return (
        <Row className={headerCss.headerContainBox1}>
            <span className={headerCss.flexGrow1}/>
            <WhiteIpAuth/>
            <Dropdown overlay={menu} trigger={['click']}>
                <Image src={menuSvg} preview={false} className={headerCss.menuIcon}/>
            </Dropdown>
        </Row>
    )
})

export default Header;
