// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".containBox_28Y047 {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.containBox_28Y047 .logo_Vii_O6 {\n  width: 220px;\n  margin-top: 35px;\n}\n.containBox_28Y047 .title_taEzag {\n  color: green;\n  margin-top: 45px;\n}\n.containBox_28Y047 .btnBox_2HdVas {\n  display: flex;\n  justify-content: space-around;\n  width: 85vw;\n  position: absolute;\n  left: 40px;\n  top: 320px;\n}\n.containBox_28Y047 .font_1cNWc7 {\n  font-size: 25px;\n}\n.containBox_28Y047 .title1_2FVtI0 {\n  color: blue;\n}\n.containBox_28Y047 .btnBox1_3r6_zO {\n  display: flex;\n  justify-content: space-around;\n  width: 90vw;\n  position: absolute;\n  left: 35px;\n  top: 600px;\n}\n.containBox_28Y047 .title2_2w9KkK {\n  color: purple;\n}\n", "",{"version":3,"sources":["webpack://src/pages/order_detail/order_detail.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;AAJA;EAMI,YAAA;EACA,gBAAA;AACJ;AARA;EAWI,YAAA;EACA,gBAAA;AAAJ;AAZA;EAgBI,aAAA;EACA,6BAAA;EACA,WAAA;EACA,kBAAA;EACA,UAAA;EACA,UAAA;AADJ;AApBA;EAyBG,eAAA;AAFH;AAvBA;EA6BI,WAAA;AAHJ;AA1BA;EAiCI,aAAA;EACA,6BAAA;EACA,WAAA;EACA,kBAAA;EACA,UAAA;EACA,UAAA;AAJJ;AAlCA;EA0CI,aAAA;AALJ","sourcesContent":[".containBox {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  .logo {\n    width: 220px;\n    margin-top: 35px;\n  }\n\n  .title {\n    color: green;\n    margin-top: 45px;\n  }\n\n  .btnBox {\n    display: flex;\n    justify-content: space-around;\n    width: 85vw;\n    position: absolute;\n    left: 40px;\n    top: 320px;\n  }\n\n  .font {\n   font-size: 25px\n  }\n\n  .title1 {\n    color: blue;\n  }\n\n  .btnBox1 {\n    display: flex;\n    justify-content: space-around;\n    width: 90vw;\n    position: absolute;\n    left: 35px;\n    top: 600px;\n  }\n\n  .title2 {\n    color: purple;\n\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containBox": "containBox_28Y047",
	"logo": "logo_Vii_O6",
	"title": "title_taEzag",
	"btnBox": "btnBox_2HdVas",
	"font": "font_1cNWc7",
	"title1": "title1_2FVtI0",
	"btnBox1": "btnBox1_3r6_zO",
	"title2": "title2_2w9KkK"
};
export default ___CSS_LOADER_EXPORT___;
