// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".containBox_3fL-SA .listItem_1-Xvkz {\n  display: flex;\n  flex-wrap: wrap;\n  color: red;\n}\n.containBox_3fL-SA .sendBox_3GwjBy {\n  position: fixed;\n  bottom: 60px;\n  justify-content: space-around;\n  width: 100vw;\n}\n.containBox_3fL-SA .sendBox_3GwjBy .inputTxt_1I0x3s {\n  width: 70vw;\n}\n.containBox_3fL-SA .bottomBtnBox_3CQ4Z_ {\n  position: fixed;\n  bottom: 10px;\n  justify-content: space-around;\n  width: 100vw;\n}\n.containBox1_2rwtKz {\n  display: flex;\n  background-color: blue;\n}\n.containBox1_2rwtKz .box2_3HnVez {\n  background: red;\n}\n.containBox1_2rwtKz .box2_3HnVez .box3_nlv7WM {\n  background: green;\n}\n.containBox1_2rwtKz .box3_nlv7WM {\n  background: yellow;\n}\n", "",{"version":3,"sources":["webpack://src/pages/order_active/order_active.module.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,eAAA;EACA,UAAA;AAAJ;AAJA;EAQI,eAAA;EACA,YAAA;EACA,6BAAA;EACA,YAAA;AADJ;AAVA;EAcM,WAAA;AADN;AAbA;EAmBI,eAAA;EACA,YAAA;EACA,6BAAA;EACA,YAAA;AAHJ;AAOA;EACE,aAAA;EAEA,sBAAA;AANF;AAGA;EAMI,eAAA;AANJ;AAAA;EASM,iBAAA;AANN;AAHA;EAcI,kBAAA;AARJ","sourcesContent":[".containBox {\n  .listItem {\n    display: flex;\n    flex-wrap: wrap;\n    color: red;\n  }\n\n  .sendBox {\n    position: fixed;\n    bottom: 60px;\n    justify-content: space-around;\n    width: 100vw;\n\n    .inputTxt {\n      width: 70vw;\n    }\n  }\n\n  .bottomBtnBox {\n    position: fixed;\n    bottom: 10px;\n    justify-content: space-around;\n    width: 100vw;\n  }\n}\n\n.containBox1 {\n  display: flex;\n  //flex-direction: column;\n  background-color: blue;\n\n  .box2 {\n    background: red;\n\n    .box3 {\n      background: green;\n    }\n  }\n\n  .box3 {\n    background: yellow;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containBox": "containBox_3fL-SA",
	"listItem": "listItem_1-Xvkz",
	"sendBox": "sendBox_3GwjBy",
	"inputTxt": "inputTxt_1I0x3s",
	"bottomBtnBox": "bottomBtnBox_3CQ4Z_",
	"containBox1": "containBox1_2rwtKz",
	"box2": "box2_3HnVez",
	"box3": "box3_nlv7WM"
};
export default ___CSS_LOADER_EXPORT___;
