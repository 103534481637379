import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {Button, Card, Col, DatePicker, Divider, Image, InputNumber, List, message, Radio, Row} from 'antd';
import {useHistory} from "react-router-dom";
import css from './prebooking.module.less'

let store = require('src/store');
let formData;
let data = store.add_order;
let config = require('commonProject/config/config');
let api = require('src/api');
let moment = require('moment');
let history;
let commonUtils = require('commonProject/utils/Common');

function submit() {
    let formData = store.persist.add_order.formData;
    console.log('subscribe_time',formData.subscribe_time);
    if (!formData.subscribe_time || !formData.subscribe_time_length) return message.error('you should choose time');
    history.push('/add_order/book_confirm');
}

async function getUsableTime() {
    if (!formData.subscribe_date || !formData.subscribe_time_length) return message.error('subscribe_time and time length not be empty')
    let params = {
        subscribe_time: moment(formData.subscribe_date).format('yyyy-MM-DD'),
        subscribe_time_length: formData.subscribe_time_length,
        times: data.request_times
    };
    let result = await api.order.get_interpret_usable_time(params);
    if (result.code != 0) return;
    data.usable_times = result.data;
    data.request_times += 1;
}

// create observer
let PrebookingTime = observer(function () {
    history = useHistory();
    formData = store.persist.add_order.formData;

    useEffect(() => {
        formData.subscribe_time = null;
    }, [])

    let prebookingTimeData = store.add_order.prebooking_time;
    return (
        <Col className={css.containBox}>
            <p className={css.headTitle}>step 4 of 4:<span className={css.headTitle1}>Pre-booking</span></p>
            <p>{`You chose:`}<p className={css.headTitle5}>
                {`${formData.chooseLang.language_name}-${formData.support_service_type.type_name}`}
                {formData.chooseCerts.length > 0 ? `-${formData.chooseCerts[0].cert_desc.split(' ')[0]}` : ``}</p>
            </p>
            <Divider className={`gray ${css.divider}`}/>
            <p className={css.headTitle4}>Select the Service Date:</p>
            <DatePicker defaultValue={moment()}
                        disabledDate={commonUtils.disabledDate}
                        suffixIcon={null}
                        onChange={date => {
                            formData.subscribe_date = date;
                            data.usable_times = [];
                            message.success('choose time:' + moment(date).format('yyyy-MM-DD'))
                        }}/>
            <Divider/>
            <p>Try to find an available time slot:</p>
            <Row>
                <span>Estimate the duration:</span>
                <InputNumber size='small' min={0} max={24} onChange={value => {
                    prebookingTimeData.hour = value
                }}/>
                <span>hr,</span>
                <InputNumber size='small' min={0} max={60} onChange={value => {
                    prebookingTimeData.minute = value
                }}/>
                <span>min</span>
            </Row>
            <p></p>
            <Radio.Group onChange={e => {
                let time = moment(e.target.value.startTime, 'yyyy-MM-DD HH:mm').format('yyyy-MM-DD HH:mm:ss');
                formData.subscribe_time = time;
                console.log('subscribe_time2',formData.subscribe_time);
                message.success(time);
            }}>
                {
                    store.add_order.usable_times.map(item => {
                        return (
                            <Radio value={item}
                                   style={{'display': 'block'}}>
                                {moment(item.startTime).format('HH:mm') + '~' + moment(item.endTime).format('HH:mm')}
                            </Radio>
                        )
                    })
                }
            </Radio.Group>
            <Col>
                <Button className={css.headTitle3 + ' greyBtn'} onClick={() => {
                    formData.subscribe_time_length = prebookingTimeData.hour * 60 + prebookingTimeData.minute;
                    getUsableTime();
                    // history.push('')
                }}>show more time</Button>
            </Col>
            <p></p>
            <Button className={css.headTitle3 + ' greyBtn'} onClick={submit} disabled={commonUtils.isEmpty(formData.subscribe_time)}>Next</Button>
        </Col>
    );
})

export default PrebookingTime;
