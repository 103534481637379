import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {Button, Card, Col, DatePicker, Divider, Image, InputNumber, List, message, Modal, Radio, Row} from 'antd';
import {useHistory} from "react-router-dom";
import {useLocation} from "react-router";
import css from "./book_confirm.module.less";

let store = require('src/store');
let config = require('commonProject/config/config');
let api = require('src/api');
let moment = require('moment');
let history;
let commonUtils = require('commonProject/utils/Common');
let queryParams;
let data;

function submit() {
    if (data.is_show_ok_dialog) {
        return commonUtils.historyPushRefresh(history, '/home_login');
    }
    let formData = store.persist.add_order.formData;
    let values = Object.values(formData);
    values.map(item => {
        if (!item) return message.error('params is error');
    })
    let certs = formData.chooseCerts.map(item => {
        return item._id;
    });
    let params = {
        interpret_lang_user_need: formData.interpret_lang_user_need,
        support_service_type: formData.support_service_type._id,
        subscribe_type: 1,
        subscribe_time_length: formData.subscribe_time_length,
        subscribe_time: formData.subscribe_time,
        certs: certs.join(','),
    };
    //有订单id表示是进行更改订单数据
    if (queryParams.id) {
        let params2 = {
            subscribe_time_length: params.subscribe_time_length,
            subscribe_time: params.subscribe_time
        }
        params2._id = queryParams.id;
        api.order.update_order(params2).then(res => {
            if (res.code != 0) return;
            Modal.success({content: 'update order message success'});
            clearFormData();
            commonUtils.historyPushRefresh(history, '/home_login');
        })
    } else {
        api.order.add_order(params).then(res => {
            if (res.code == 0) {
                Modal.success({content: 'Thank you for pre-booking. Now you can either pre-book another service here, or manage your existing ones.'});
                clearFormData();
                data.is_show_ok_dialog = true;
                commonUtils.historyPushRefresh(history, '/home_login');
            } else if (res.code == 2) {
                Modal.warn({content: 'booking failed. You need to ask your admin/supervisor to add and authorizel your account first before booking'})
            }
        });
    }

}

function clearFormData() {
    let formData = store.persist.add_order.formData;
    let keys = Object.keys(formData);
    for (let key of keys) {
        formData[key] = null;
    }
}

// create observer
let BookConfirm = observer(function () {
    history = useHistory();
    let formData = store.persist.add_order.formData;
    queryParams = commonUtils.query2ObjParams(useLocation().search);
    data = store.add_order.book_confirm;

    useEffect(() => {
    }, [])

    let prebookingTimeData = store.add_order.prebooking_time;
    let duration = moment.duration(formData.subscribe_time_length, 'minutes');
    if (commonUtils.isEmpty(formData.chooseLang)) return <div/>
    return (
        <Col className={css.containBox}>
            <p className={css.headTitle}>step 4 of 4: <span className={css.headTitle1}>Pre-booking</span></p>
            <br/>
            <p className={css.headTitle2}>You successfully booked:</p>
            <div className={css.leftStart}>
                <div>{`You chose: `}
                    <span className={css.headTitle3}>
                {`${formData.chooseLang.language_name}-${formData.support_service_type.type_name}`}
                        {formData.chooseCerts.length > 0 ? `-${formData.chooseCerts[0].cert_name}` : ``}
                    </span>
                </div>
                <div>{`Service Date: `}
                    <span
                        className={css.headTitle3}>{`${moment(formData.subscribe_time).format('MM/DD ddd. ')}`}
                    </span>
                    <span
                        className={`${css.headTitle3} ${css.headTitle4}`}>{`${moment(formData.subscribe_time).format('hh:mm a ')}`}
                    </span>
                </div>
                <div>{`Service Duration: `}<span
                    className={css.headTitle3}>{`${duration.hours()} hr ${duration.minutes()}`}min</span></div>
                <p>{`Service user: `}<span
                    className={css.headTitle3}>{store.persist.userInfo.user_name}</span></p>
            </div>
            <Divider/>
            <p className={css.headTitle6}>What's next?</p>
            <p className={` ${css.leftStart}`}>* To apply pre-booking discount, please log in and
                activate the call within 5 minutes of your pre-booked starting time.
            </p>
            <p className={ ` ${css.leftStart}`}>* You can request a service without pre-booking. In this
                case no discount applies, and you may need to wait for available interpreters.</p>
            <Button onClick={submit} className={css.headTitle3 + ' greyBtn'}>Complete</Button>
        </Col>
    );
})

export default BookConfirm;