// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".containBox_1HdEX8 .topTitle_3a2kw4 {\n  font-size: 28px;\n  color: #122b88;\n  font-weight: bold;\n  text-align: center;\n}\n.containBox_1HdEX8 .menuListBox_BD_vOD {\n  padding: 25px 25px 42px 0;\n}\n.containBox_1HdEX8 .menuListBox_BD_vOD .menuBox_fLzxWu {\n  margin-bottom: 30px;\n  display: flex;\n  align-items: center;\n}\n.containBox_1HdEX8 .menuListBox_BD_vOD .menuBox_fLzxWu .leftTitle_XUU0C3 {\n  color: #122b88;\n  font-size: 20px;\n  text-align: left;\n  width: 55vw;\n}\n.containBox_1HdEX8 .menuListBox_BD_vOD .menuBox_fLzxWu .rightBox_3BsrY0 {\n  display: flex;\n  flex-direction: column;\n}\n.containBox_1HdEX8 .menuListBox_BD_vOD .menuBox_fLzxWu .rightBox_3BsrY0 .btn_oUfejK {\n  margin-bottom: 10px;\n  width: 120px;\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://src/pages/user_func_menu/user_func_menu.module.less"],"names":[],"mappings":"AAAA;EAEI,eAAA;EACA,cAAA;EACA,iBAAA;EACA,kBAAA;AAAJ;AALA;EASI,yBAAA;AADJ;AARA;EAYM,mBAAA;EACA,aAAA;EACA,mBAAA;AADN;AAbA;EAiBQ,cAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;AADR;AAnBA;EAyBQ,aAAA;EACA,sBAAA;AAHR;AAvBA;EA6BU,mBAAA;EACA,YAAA;EACA,kBAAA;AAHV","sourcesContent":[".containBox {\n  .topTitle {\n    font-size: 28px;\n    color: rgba(18, 43, 136, 100);\n    font-weight: bold;\n    text-align: center;\n  }\n\n  .menuListBox {\n    padding: 25px 25px 42px 0;\n\n    .menuBox {\n      margin-bottom: 30px;\n      display: flex;\n      align-items: center;\n\n      .leftTitle {\n        color: rgba(18, 43, 136, 90);\n        font-size: 20px;\n        text-align: left;\n        width: 55vw;\n      }\n\n      .rightBox {\n        //margin-left: 32px;\n        display: flex;\n        flex-direction: column;\n\n        .btn {\n          margin-bottom: 10px;\n          width: 120px;\n          text-align: center;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containBox": "containBox_1HdEX8",
	"topTitle": "topTitle_3a2kw4",
	"menuListBox": "menuListBox_BD_vOD",
	"menuBox": "menuBox_fLzxWu",
	"leftTitle": "leftTitle_XUU0C3",
	"rightBox": "rightBox_3BsrY0",
	"btn": "btn_oUfejK"
};
export default ___CSS_LOADER_EXPORT___;
