import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {Button, Col, Divider, Input, message, Row, Select} from "antd";
import css from './order_active.module.less';
import {useHistory} from "react-router-dom";
import {useLocation} from "react-router";
import {Option} from "antd/es/mentions";
import homeLoginCss from "../home_login/home_login.module.less";

let store = require('../../store');
let data;
let api = require('src/api');
let moment = require('moment');
let history;
let config = require('commonProject/config/config');
let commonUtils = require('commonProject/utils/Common');
let agoraHelper = require('commonProject/helper/AgoraHelper');
let websocketHelper = require('commonProject/helper/websocket_helper');
let orderHelper = require('commonProject/helper/order_helper');

let roleType;//自身角色类型
let preeRoleType;//对方角色类型
let peerId;

async function getOrder(id) {
    let res = await api.order.get_order_list({_id: id});
    if (res.code != 0 || res.data.length == 0) return;
    data.order = res.data.item[0];
}

async function onClickBottomBtn(type) {
    let status;
    if (type == 'start') {
        status = roleType == 'user' ? 5 : 4;
        let isUpdateOk = await updateOrderStatus(status);
        if (isUpdateOk) data.recordList.push('You confirms started. ');
    } else if (type == 'pause') {
        let isUpdateOk = await updateOrderStatus(9);
        if (isUpdateOk) data.recordList.push(`You paused,It doesn't count time`);
    } else if (type == 'resume') {
        let isUpdateOk = await updateOrderStatus(3);
        if (isUpdateOk) data.recordList.push(`You resume,continue count time`);
    } else if (type == 'end') {
        status = roleType == 'user' ? 7 : 6;
        let isUpdateOk = await updateOrderStatus(status);
        if (isUpdateOk) data.recordList.push(`You confirm end,please wait interpreter confirm end too;`);
    } else if (type == 'next') {
        history.push(`/order_evaluate?id=${data.order._id}`)
    }
}

async function onComponentUnmount() {
    agoraHelper.logout();
}

//发送消息时的回调
async function onClickSendMsg() {
    // let resSend = await agoraHelper.sendMessageToPeer(data.inputTxt, peerId);
    // if (!resSend.hasPeerReceived) return message.error('send message failed');
    let res = await api.order.send_msg_to({_id: peerId, role_type: preeRoleType, msg: data.inputTxt});
    if (res.code != 0) return message.error('send message failed');
    data.recordList.push(data.inputTxt);
}

//获取暂停和继续的字符串
function getPauseOrResumeMsg() {
    //3状态是订单执行中，9是订单暂停中。
    if (data.order.status == 3) return 'pause';
    if (data.order.status == 9) return 'resume';
    return 'pause';
}

//更新订单状态
async function updateOrderStatus(status) {
    let res = await api.order.update_order({_id: data.order._id, status: status});
    if (res.code != 0) return false;
    //重新获取订单数据
    getOrder(data.order._id);
    let resSendOrderChangeToOther = await api.order.send_msg_to({
        _id: peerId, role_type: preeRoleType, msg: {
            id_order: data.order._id, status: status,
        },
        action_type: config.websocket.action_type.receive_order_change
    });
    return true;
}

//接收到聊天消息的回调
async function cb_receive_chat_msg(strMsg) {
    data.recordList.push(preeRoleType + ':' + strMsg);
}

//订单状态改变的回调
async function cb_receive_order_change(order) {
    // data.recordList.push(`order change:${preeRoleType}:${order.status}:${orderHelper.getStatusName(order.status)}`);
    data.recordList.push(`${orderHelper.getStatusDesc(order.status)}`);
    getOrder(data.order._id);
}

async function initData(order_id) {
    await getOrder(order_id);
    if (commonUtils.isEmpty(data.order)) return;
    if (data.order.status == 2) {
        data.recordList.push('Please press "Confirm Start" below to start the service.');
    } else data.recordList.push(`order status is ${orderHelper.getStatusName(data.order.status)}`);
    agoraHelper.login(store.persist.userInfo._id);
    agoraHelper.on('MessageFromPeer', ({text}, peerId) => {
        data.recordList.push('other:' + text);
    })
    peerId = preeRoleType == 'user' ? data.order.user._id : data.order.interpreter._id;

    websocketHelper.cb_receive_chat_msg = cb_receive_chat_msg;
    websocketHelper.cb_receive_order_change = cb_receive_order_change;
    websocketHelper.initWebsocket({...store.persist.userInfo, token: store.persist.token});
}

// 订单正在执行页面
let Order_active = observer(function () {
    history = useHistory();
    data = store.order_active;
    let params = commonUtils.query2ObjParams(useLocation().search);
    let order_id = params.id;
    roleType = store.persist.userInfo.role_type;
    preeRoleType = roleType == 'user' ? 'interpreter' : 'user';
    useEffect(async () => {
        await initData(order_id);
        return onComponentUnmount;//useEffect return一个方法对象，会在组件被销毁的时候调用
    }, []);

    if (commonUtils.isEmpty(data.order)) return <div/>
    let order = data.order;
    let user = data.order.user;
    if (roleType == 'user') {
        return (
            <Col className={css.containBox}>
                <p>{`You chose:${data.order.interpret_lang_user_need.language_name}-${data.order.support_service_type.type_name}`}</p>
                <p>{`Your callback number:${data.order.contact.phone_num} (${data.order.contact.contact_nick_name})`}</p>
                <p>{`Interpreter ID:${data.order.interpreter.id2}  ${data.order.interpreter.user_name}`}</p>
                <p>{`Starting time:${moment(data.order.subscribe_time).format('MM/DD/YYYY HH:mm a dddd')}.`}</p>
                <Divider/>
                <p>Event Recoding:</p>
                {data.recordList.map(item => {
                    return (
                        <p>{item}</p>
                    )
                })}
                <Row className={css.sendBox}>
                    <Input className={css.inputTxt} onChange={e => {
                        data.inputTxt = e.target.value;
                    }}/>
                    <Button type='primary' disabled={commonUtils.isEmpty(data.inputTxt)}
                            onClick={onClickSendMsg}>send</Button>
                </Row>
                <Row className={css.bottomBtnBox}>
                    <Button disabled={![2, 4].includes(order.status)}
                            onClick={() => onClickBottomBtn('start')}
                            type='primary'>Confirm Start</Button>
                    <Button disabled={![3, 9].includes(order.status)}
                            onClick={() => onClickBottomBtn(getPauseOrResumeMsg())}
                            type='primary'>{getPauseOrResumeMsg()}</Button>
                    <Button disabled={![3, 6].includes(order.status)} onClick={() => onClickBottomBtn('end')}
                            type='primary'>Confirm End</Button>
                    <Button disabled={order.status != 8} onClick={() => onClickBottomBtn('next')}
                            type='primary'>Next</Button>
                </Row>
            </Col>
        );
    } else if (roleType == 'interpreter') {
        return (
            <Col className={css.containBox}>
                <p>User: {user.user_name}</p>
                <p>{order.interpret_lang_user_need.language_name}</p>
                <p>{order.support_service_type.type_name}: {order.contact.phone_num}</p>
                <p>Time now: {Date.now()}</p>
                <Divider/>
                <p>Event Recoding:</p>
                {data.recordList.map(item => {
                    return (
                        <p>{item}</p>
                    )
                })}
                <Row className={css.sendBox}>
                    <Input className={css.inputTxt} onChange={e => {
                        data.inputTxt = e.target.value;
                    }}/>
                    <Button type='primary' disabled={commonUtils.isEmpty(data.inputTxt)}
                            onClick={onClickSendMsg}>send</Button>
                </Row>
                <Row className={css.bottomBtnBox}>
                    <Button disabled={![2, 5].includes(order.status)}
                            onClick={() => onClickBottomBtn('start')}
                            type='primary'>Confirm Start</Button>
                    <Button disabled={![3, 9].includes(order.status)}
                            onClick={() => onClickBottomBtn(getPauseOrResumeMsg())}
                            type='primary'>{getPauseOrResumeMsg()}</Button>
                    <Button disabled={![3, 7].includes(order.status)} onClick={() => onClickBottomBtn('end')}
                            type='primary'>Confirm End</Button>
                    <Button disabled={order.status != 8} onClick={() => onClickBottomBtn('next')}
                            type='primary'>Next</Button>
                </Row>
            </Col>
        );
    }
    return (
        <div/>
    );
})

export default Order_active;
