import React, {useContext, createContext, useState} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation
} from "react-router-dom";
import PrivateRoute from "./private_route";
import Login from "../pages/login/login";
import HomeLogin from "../pages/home_login/home_login";
import Register from "../pages/register/register";
import Home from "../pages/home/home";
import Header from "../component/header/header";
import AddOrder from "../pages/add_order/add_order";
import EditOrder from "../pages/edit_order/edit_order";
import Person from "../pages/person/person";
import AddPaySubscribe from "../pages/person/add_pay_subscribe/add_pay_subscribe";
import PayResult from "../pages/pay_result/pay_result";
import ChangeBookTime from "../pages/edit_order/change_book_time/change_book_time";
import CancelOrder from "../pages/cancel_order/cancel_order";
import PrepareOrderStart from "../pages/prepare_order_start/prepare_order_start";
import OrderActive from "../pages/order_active/order_active";
import OrderEvaluate from "../pages/order_evaluate/order_evaluate";
import OrderDetail from "../pages/order_detail/order_detail";
import ResetPsw from "../pages/reset_psw/reset_psw";
import RegisterResult from "../pages/register/register_result/register_result";
import RegisterIsAdmin from "../pages/register/register_is_admin/register_is_admin";
import RegisterAddInfo from "../pages/register/register_add_info/register_add_info";
import OrderList from "../pages/order_list/order_list";
import RegisterAddInfoAdmin from "../pages/register/register_add_info_admin/register_add_info_admin";
import CompanyHome from "../pages/company_home/company_home";
import RegisterAddInfoOwner from "../pages/register/register_add_info_owner/register_add_info_owner";
import AccountOwnerSetting from "../pages/account_owner_setting/account_owner_setting";
import TeamAdminSetting from "../pages/team_admin_setting/team_admin_setting";
import UserFuncMenu from "../pages/user_func_menu/user_func_menu";
import PotentialUserRegistered from "../pages/register/potential_user_registed/potential_user_registered";
import Payment from "../pages/payment/payment";


function MyRoute() {
    let route =
        <Router>
            <div className='appContainer'>
                <Header/>
                <Switch>
                    <PrivateRoute path="/login/:roleType">
                        <Login/>
                    </PrivateRoute>
                    <PrivateRoute path="/register">
                        <Register/>
                    </PrivateRoute>
                    <PrivateRoute path="/register_is_admin">
                        <RegisterIsAdmin/>
                    </PrivateRoute>
                    <PrivateRoute path="/potential_user_registered">
                        <PotentialUserRegistered/>
                    </PrivateRoute>
                    <PrivateRoute path="/register_add_info">
                        <RegisterAddInfo/>
                    </PrivateRoute>
                    <PrivateRoute path="/register_add_info_admin">
                        <RegisterAddInfoAdmin/>
                    </PrivateRoute>
                    <PrivateRoute path="/register_add_info_owner">
                        <RegisterAddInfoOwner/>
                    </PrivateRoute>
                    <PrivateRoute path="/account_owner_setting">
                        <AccountOwnerSetting/>
                    </PrivateRoute>
                    <PrivateRoute path="/home_login">
                        <HomeLogin/>
                    </PrivateRoute>
                    <PrivateRoute path="/order_list">
                        <OrderList/>
                    </PrivateRoute>
                    <PrivateRoute path="/add_order/:stepType">
                        <AddOrder/>
                    </PrivateRoute>
                    <PrivateRoute path="/edit_order">
                        <EditOrder/>
                    </PrivateRoute>
                    <PrivateRoute path="/cancel_order">
                        <CancelOrder/>
                    </PrivateRoute>
                    <PrivateRoute path="/prepare_order_start">
                        <PrepareOrderStart/>
                    </PrivateRoute>
                    <PrivateRoute path="/change_book_time">
                        <ChangeBookTime/>
                    </PrivateRoute>
                    <PrivateRoute path="/person">
                        <Person/>
                    </PrivateRoute>
                    <PrivateRoute path="/add_pay_subscribe">
                        <AddPaySubscribe/>
                    </PrivateRoute>
                    <PrivateRoute path="/order_active">
                        <OrderActive/>
                    </PrivateRoute>
                    <Router path="/pay_result">
                        <PayResult/>
                    </Router>
                    <PrivateRoute path="/order_evaluate">
                        <OrderEvaluate/>
                    </PrivateRoute>payment_read
                    <PrivateRoute path="/order_detail">
                        <OrderDetail/>
                    </PrivateRoute>
                    <PrivateRoute path="/team_admin_setting">
                        <TeamAdminSetting/>
                    </PrivateRoute>
                    <PrivateRoute path="/user_func_menu">
                        <UserFuncMenu/>
                    </PrivateRoute>
                    <PrivateRoute path="/payment">
                        <Payment/>
                    </PrivateRoute>
                    <Router path="/company_home">
                        <CompanyHome/>
                    </Router>

                    <Router path="/reset_psw">
                        <ResetPsw/>
                    </Router>
                    <Router path="/register_result">
                        <RegisterResult/>
                    </Router>
                    <PrivateRoute path="/home">
                        <Home/>
                    </PrivateRoute>

                    <PrivateRoute path="/">
                        <Home/>
                    </PrivateRoute>
                    <Route render={() => <Redirect to="/"/>}/>
                </Switch>
            </div>
        </Router>;
    return route;
}

export default MyRoute;
