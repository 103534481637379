import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {useLocation, useParams} from "react-router";
import {useHistory} from "react-router-dom";
import css from './register_add_info_owner.module.less';
import {Button, Col, Input, Row, message, Image, Form, Radio, Select, Checkbox, InputNumber} from "antd";
import {Link} from "react-router-dom";
import {Option} from "antd/es/mentions";
import del_icon from 'src/assets/img/common/del_icon.svg';


let store = require('../../../store');
let mobx = require('mobx');
let api = require('src/api');
let data = store.register;
let dataPersist = {};//缓存数据
let userInfo = {};
// eslint-disable-next-line react-hooks/rules-of-hooks
let history;
let commonUtils = require('commonProject/utils/Common');
let moment = require('moment-timezone');
let queryParams;

function submitUpdateUser() {
    let formData = data.formData;
    // console.log('formData', JSON.parse(JSON.stringify(formData)));
    //如果设置了defaultValue然后直接使用没有调用onChange的话，就需要这里配置了
    if (!formData.email && userInfo.email) formData.email = userInfo.email;
    if (!formData.phone_num && userInfo.phone_num) formData.phone_num = userInfo.phone_num;
    if (!formData.first_name) formData.first_name = dataPersist.first_name;
    if (!formData.second_name) formData.second_name = dataPersist.second_name;
    if (!formData.company_name) formData.company_name = dataPersist.company_name;
    if (!formData.street_address) formData.street_address = dataPersist.street_address;
    if (!formData.street_address2) formData.street_address2 = dataPersist.street_address2;
    if (!formData.city) formData.city = dataPersist.city;
    if (!formData.state) formData.state = dataPersist.state;
    if (!formData.zip_code) formData.zip_code = dataPersist.zip_code;
    if (!formData.website) formData.website = dataPersist.website;
    if (formData.is_summer_time != 0) formData.is_summer_time = 1;
    formData.user_name = formData.email;
    console.log('formData', JSON.parse(JSON.stringify(formData)));
    if (!formData.first_name
        || !formData.second_name
        || !formData.email
        || !formData.company_name
        || !formData.street_address
        || !formData.city
        || !formData.state
        || !formData.zip_code
        || !formData.phone_num
        || !formData.time_zone
    ) return message.error('You have some field missing');
    if (!commonUtils.isEmail(formData.email)) return message.error('email format is error');
    if (!commonUtils.isUSAPhone(formData.phone_num)) return message.error('phone number format error');
    if (!commonUtils.isUSAZipCode(formData.zip_code)) return message.error('zip code format error');
    if (!formData.is_agree) return message.error('Please check off the terms and conditions before continue');
    api.user.register_add_info(formData).then(res => {
        let resData = res;
        if (resData.code !== 0) return;
        message.success(resData.msg);
        store.persist.register_add_info_owner = {};
        history.push('/register_result?type=success');
    })
}


function getCerts() {
    api.order.get_cert_list().then(res => {
        data.chooseCerts = res.data;
    })
}

function getLanguage() {
    api.order.get_interpret_lang_list().then(res => {
        data.chooseLangs = res.data;
    })
}

//获取联系方式
async function getContacts() {
    let res = await api.order.get_contact_list();
    if (res.code != 0) return;
    data.contacts = res.data;
}

//添加联系方式
async function add_contact() {
    let res = await api.order.add_contact({
        contact_nick_name: data.new_admin_contact_nick_name,
        phone_num: data.new_admin_phone_num,
    });
    if (res.code != 0) return;
    message.success('add contact success');
    refresh();
}

//删除联系方式
async function del_contact(_id) {
    let res = await api.order.del_contact({_id: _id});
    if (res.code != 0) return;
    message.success('del contact success');
    refresh();
}

//获取子用户
async function getSubUser() {
    if (!userInfo.is_admin && !userInfo.is_super_admin) return;
    let res = await api.user.get_sub_users();
    if (res.code != 0) return;
    data.sub_users = res.data;
}

async function admin_add_sub_user(phoneOrEmail) {
    let res = await api.user.admin_add_sub_user({account: phoneOrEmail});
    if (res.code != 0) return;
    message.success('add sub_user success');
    refresh();
}

async function admin_del_sub_user(phoneOrEmail) {
    let res = await api.user.admin_del_sub_user({account: phoneOrEmail});
    if (res.code != 0) return;
    message.success('del sub_user success');
    refresh();
}

async function onClickEdit() {
    history.push('/register_add_info_admin?type=some_edit');
}

function refresh() {
    getCerts();
    getLanguage();
    getContacts();
    getSubUser();
}

//添加试用
async function add_try_out() {
    let res = await api.user.add_try_out();
    if (res.code == 0) return message.success('add try out use success');
}

//保存已经编辑的数据,如果页面刷新了也能显示
async function saveEdit() {
    store.persist.register_add_info_owner = data.formData;
    message.success('save success')
}


function getViewType(type) {
    switch (type) {
        case 'all_edit':
            return getAllEditView();
        case 'only_read':
            return getOnlyReadView();
        case 'some_edit':
            return getSomeEditView();
        default:
            return getAllEditView();
    }
}

function getAllEditView() {
    // if (commonUtils.isEmpty(data.chooseCerts)
    //     || commonUtils.isEmpty(data.chooseLangs)
    //     || commonUtils.isEmpty(dataPersist)) return <div>no data</div>

    return (
        <Col aligin='middle'>
            <p className={css.headTitleBox + ' darkBlue'}>Account Owner Profile</p>
            <Form name='registerForm' initialValues={{}} onFinish={submitUpdateUser}>
                <Form.Item className={css.headTitle} label='About You:' name='user_name'>
                    <div className={'flex'}>
                        <label className={'required'}/>
                        <Input placeHolder='First Name' defaultValue={dataPersist.first_name}
                               onChange={(e) => {
                                   data.formData.first_name = e.target.value
                               }}/>
                    </div>
                    <div className={'flex'}>
                        <label className={'required'}/>
                        <Input placeHolder='Last Name' defaultValue={dataPersist.second_name} onChange={(e) => {
                            data.formData.second_name = e.target.value
                        }}/>
                    </div>
                    <p className={css.hint + ' hintGray'}>Your name at your workplace.</p>
                </Form.Item>
                <Form.Item className={css.headTitle} name='email'>
                    <div className={'flex'}>
                        <label className={'required'}/>
                        <Input placeHolder='email' defaultValue={userInfo.email}
                               value={data.formData.email}
                               readOnly={!!userInfo.email}
                               onChange={(e) => {
                                   data.formData.email = e.target.value
                               }}/>
                    </div>
                    <p className={css.hint + ' hintGray'}>We'll email you for essential communications and billing
                        information.</p>
                </Form.Item>
                <Form.Item className={css.headTitle} label='About Your Business：' name='company_info'>
                    <div className={'italic hintGray'}>We'll verify you are who you say you are.</div>
                    <div className={'flex'}>
                        <label className={'required'}/>
                        <Input placeHolder='Business or Company Name' defaultValue={dataPersist.company_name}
                               onChange={(e) => {
                                   data.formData.company_name = e.target.value
                               }}/>
                    </div>
                    <div className={'flex'}>
                        <label className={'required'}/>
                        <Input placeHolder='Street Address' defaultValue={dataPersist.street_address} onChange={(e) => {
                            data.formData.street_address = e.target.value
                        }}/>
                    </div>
                    <div className={'flex'}>
                        <label className={'noRequired'}/>
                        <Input placeHolder='Street Address Line #2' defaultValue={dataPersist.street_address2}
                               onChange={(e) => {
                                   data.formData.street_address2 = e.target.value
                               }}/>
                    </div>

                    <div className={'flex'}>
                        <label className={'required'}/>
                        <Input placeHolder='City' defaultValue={dataPersist.city} onChange={(e) => {
                            data.formData.city = e.target.value
                        }}/>
                    </div>
                    <div className={'flex'}>
                        <label className={'required'}/>
                        <Select defaultValue={dataPersist.state}
                                placeholder={<div className={css.inputPlaceHolder}>State</div>}
                                onChange={(item) => {
                                    data.formData.state = item;
                                }}>
                            {commonUtils.stateUSA.map(item => {
                                return (
                                    <Select.Option value={item.label}>
                                        {item.label + ''}
                                    </Select.Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div className={'flex'}>
                        <label className={'required'}/>
                        <Input placeHolder='Zip Code' defaultValue={dataPersist.zip_code} onChange={(e) => {
                            data.formData.zip_code = e.target.value
                        }}/>
                    </div>
                    <div className={'flex'}>
                        <label className={'required'}/>
                        <Input placeHolder='Business Phone (10 digits format)' defaultValue={userInfo.phone_num}
                               onChange={(e) => {
                                   data.formData.phone_num = e.target.value
                               }}/>
                    </div>
                    <div className={'flex'}>
                        <label className={'noRequired'}/>
                        <Input placeHolder='Business Website' defaultValue={userInfo.phone_num} onChange={(e) => {
                            data.formData.website = e.target.value
                        }}/>
                    </div>

                </Form.Item>
                <Form.Item className={css.headTitle} label='Your Team Name：' name='team_name'>
                    <p className={css.hint + ' hintGray'}>Your department name, or your site name, etc.</p>
                    <div className={'flex'}>
                        <label className={'required'}/>
                        <Input placeHolder='Your Team or Department Name'
                               value={data.formData.team_name}
                               onChange={(e) => {
                                   data.formData.team_name = e.target.value
                               }}/>
                    </div>
                </Form.Item>
                <Form.Item className={css.headTitle}
                           label={
                               <label className={'required'}>
                                   {`Your Time Zone ${data.formData.time_zone ? `:${data.formData.time_zone} ${moment().tz(data.formData.time_zone).format('YYYY-MM-DD HH:mm z')}` : ''}`}
                               </label>
                           }
                           name='time_zone'>
                </Form.Item>
                {/*<Form.Item className={css.headTitle}*/}
                {/*           label='Does your place observe Daylight Saving Time (or DST) in summer?'*/}
                {/*           name='is_summer_time'>*/}
                {/*    <Radio.Group defaultValue={1} onChange={(e) => {*/}
                {/*        data.formData.is_summer_time = e.target.value*/}
                {/*    }}>*/}
                {/*        <Radio value={1}>Yes, we observe DST in summer.</Radio>*/}
                {/*        <Radio value={0}>No, we do not observe DST in summer.</Radio>*/}
                {/*    </Radio.Group>*/}
                {/*</Form.Item>*/}
                <Form.Item>
                    <Checkbox onChange={(e) => {
                        data.formData.is_agree = e.target.checked
                    }}>
                        In order to complete your registration, You are required to electronically accept these
                        <a href="/company_home?type=termsAndConditions"  target="_blank" className={'underline'}>Terms and Conditions</a>
                        . You acknowledge that You have carefully reviewed these terms, to which You a
                        party, fully understand and are aware of these terms, and understand your rights, obligations
                        and responsibilities hereunder. You acknowledge that Company has presented You with the
                        opportunity to review and agree to these terms. By continuing, You agree to these Terms and
                        Conditions of Our Service, and <a href="/company_home?type=privacyPolicy" target="_blank" className={'underline'}>Privacy Policy</a>.
                    </Checkbox>
                </Form.Item>
                <Button className={css.btn + ' greyBtn'} htmlType="submit">
                    Agree and continue
                </Button>
                {/*<Button onClick={() => {*/}
                {/*    var jun = moment("2014-06-01T12:00:00Z");*/}
                {/*    var dec = moment("2014-12-01T12:00:00Z");*/}

                {/*    let a = jun.tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm z');  // 5am PDT*/}
                {/*    let b = dec.tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm z');  // 4am PST*/}
                {/*    console.log(a, b)*/}
                {/*}}>*/}
                {/*    test time zone*/}
                {/*</Button>*/}
                {/*<Row align='center'>*/}
                {/*    <Button className={css.btnSaveAll} onClick={saveEdit}>*/}
                {/*        Decline*/}
                {/*    </Button>*/}
                {/*</Row>*/}
            </Form>
        </Col>
    )
}

function getOnlyReadView() {
    return (
        <Col aligin='middle' className={css.onlyReadBox}>
            <p className={css.headTitleBox + ' darkBlue'}>Team Admin Settings</p>
            <div>
                {
                    ![1, 2].includes(userInfo.try_out_status) ?
                        <Button type='primary' onClick={add_try_out}>try out use one month</Button> : null
                }
            </div>
            <div>
                <div className={css.firstTitle}>Admin:</div>
                <div className={css.subTitle}>Team Name:</div>
                <div className={css.subTitle}>{`Full Name:`}
                    <span className={css.desc}>{userInfo.user_name}</span>
                </div>
                <div className={css.subTitle}>{`Email:`}
                    <span className={css.desc}>{userInfo.email}</span>
                </div>
            </div>
            <div>
                <div className={css.title}>Time Zone:</div>
                <div className={css.desc}>{userInfo.time_zone}</div>
                <div>Your location observes Daylight Saving Time in summer.</div>
            </div>
            <div>
                <div className={css.title}>
                    <span className={css.title}>Landline for Interpreting Service</span>
                    <Button className={'greyBtn ' + css.btnEdit} onClick={onClickEdit}>edit</Button>
                </div>
                {
                    !commonUtils.isEmpty(data.contacts) ? data.contacts.map(item => {
                        return (
                            <div>
                                <div>{`Landline #:`}
                                    <span className={css.desc}>{item.phone_num}</span>
                                </div>
                                <div className={css.nickName}>{`Nick Name:`}
                                    <span className={css.desc}>{item.contact_nick_name}</span>
                                </div>
                            </div>
                        )
                    }) : null
                }
            </div>
            <div>
                <div className={css.title}>
                    <span className={css.title}>Team Members & Log in IDs</span>
                    <Button className={'greyBtn ' + css.btnEdit} onClick={onClickEdit}>edit</Button>
                </div>
                {
                    data.sub_users.map(item => {
                        return (
                            <div className={css.subUserBox}>
                                <div>{`Name:`}
                                    <span className={css.desc}>{item.user_name}</span>
                                </div>
                                <div>{`email:`}
                                    <span className={css.desc}>{item.email}</span>
                                </div>
                                <div>{`phone:`}
                                    <span className={css.desc}>{item.phone_num}</span>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </Col>
    )
}

function getSomeEditView() {
    return (
        <Col aligin='middle' className={css.someEditBox}>
            <div className={css.headTitleBox + ' darkBlue'}>Team Admin Settings</div>
            <div className={css.title}>Landline for Interpreting Service</div>
            <div className={'hintGrayItalic'}>we will double check the number for each call to prevent misuse or
                fraud.
            </div>
            {
                data.contacts.map(item => {
                    return (
                        <div className={css.contactBox}>
                            <Image src={del_icon} preview={false} onClick={() => {
                                del_contact(item._id);
                            }}/>
                            <span className={css.desc}>{`${item.phone_num} —— ${item.contact_nick_name}`}</span>
                        </div>
                    )
                })
            }
            <div className={'hintGrayItalic'}>* This number cannot be a personal number.</div>
            <Input placeholder={'landline number (with area code)'} onChange={(e) => {
                data.new_admin_phone_num = e.target.value
            }}/>
            <Input placeholder={'Nick name, e.g. treatment room'} onChange={(e) => {
                data.new_admin_contact_nick_name = e.target.value
            }}/>
            <Button className={css.btnAdd} onClick={add_contact}>Add</Button>

            <div className={css.title}>Your Team Member & Log in ID</div>
            <span className={css.subUserHintDesc}>* Adding their log in accounts is equivalent to authorizing them to use the interpreting service with your payment.
* Please remember to remove account(s) when there is employee(s) resign, leaving, etc.
* Each email or cell number or landline number will be considered as a unique person or account, which will be used for user rating for statistics and improving service quality.</span>
            {
                data.sub_users.map(item => {
                    return (
                        <div className={css.subUserBox}>
                            <Image src={del_icon} preview={false} onClick={() => {
                                admin_del_sub_user(item.email);
                            }}/>
                            <div>
                                <div className={css.desc}>{`Name:${item.user_name}`}</div>
                                <div className={css.desc}>{`email:${item.email}`}</div>
                                <div className={css.desc}>{`phone:${item.phone_num}`}</div>
                            </div>
                        </div>
                    )
                })
            }
            <div>
                <Input placeholder={'input email or phone_num'} onChange={(e) => {
                    data.new_sub_user_email_or_phone_num = e.target.value
                }}/>
                <Button className={css.btnAdd} onClick={() => {
                    admin_add_sub_user(data.new_sub_user_email_or_phone_num);
                }}>Add</Button>
            </div>

            <Col align={'middle'}>
                <Button className={'greyBtn ' + css.btnSave} onClick={() => {
                    history.push('/register_add_info_admin?type=only_read')
                }}>Save</Button>
            </Col>
        </Col>
    )
}

// create observer
let RegisterAddInfoOwner = observer(function () {
    history = useHistory();
    userInfo = store.persist.userInfo;
    dataPersist = store.persist.register_add_info_owner ?? {};
    queryParams = commonUtils.query2ObjParams(useLocation().search);
    // queryParams.type 三种类型 all_edit填写所有，only_read显示内容，some_edit显示点击编辑的内容。
    queryParams.type = queryParams.type ?? 'all_edit';
    data.formData.user_role_type = queryParams.user_role_type ?? 'employee';
    data.formData.time_zone = commonUtils.getClientTimeZone();

    useEffect(() => {
        Object.assign(data.formData, JSON.parse(JSON.stringify(dataPersist)));
        getCerts();
        getLanguage();
        getContacts();
        getSubUser();
    }, []);

    return getViewType(queryParams.type);
});

export default RegisterAddInfoOwner;
