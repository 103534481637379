let config = require('commonProject/config/config');
let axios = require('src/utils/request').default;

let interpreter = {
    login(params) {
        return axios.post('/webapp/interpreter/login', params);
    },
    register(params) {
        return axios.post('/webapp/interpreter/register', params);
    },
    verify_phone_send_code(params) {
        params.role_type = 'user';
        return axios.post('/webapp/interpreter/verify_phone_send_code', params,{timeout: 25 * 1000});
    },
    verify_email_send_code(params) {
        params.role_type = 'user';
        return axios.post('/webapp/interpreter/verify_email_send_code', params,{timeout: 25 * 1000});
    },
    verify_email(params) {
        return axios.post('/webapp/interpreter/verify_email', params);
    },
    verify_phone(params) {
        return axios.post('/webapp/interpreter/verify_phone', params);
    },
}

module.exports = interpreter;
