// import AgoraRTM from 'agora-rtm-sdk'
let AgoraRTM = require('agora-rtm-sdk');
let appId = '15077d6fa57c4fa8ac3ad712866ea350';
let client = AgoraRTM.createInstance(appId);
client.on('ConnectionStateChanged', (newState, reason) => {
    console.log('on connection state changed to ' + newState + ' reason: ' + reason);
});
// class AgoraHelper {
//     static init() {
//
//     }
//
//     /**
//      * 登录实时通讯的服务
//      * @param uid 用户id
//      */
//     static async loginRtm(uid) {
//         try {
//             let res = await client.login({uid});
//             console.log('AgoraRTM client login success,uid:' + uid);
//         } catch (e) {
//             console.log(e.message);
//         }
//     }
//
//     static async logoutRtm() {
//         await client.logout();
//     }
// }

module.exports = client;