// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".containBox_1BRPi0 {\n  display: flex;\n  flex-direction: column;\n}\n.containBox_1BRPi0 .headTitle_he5kZX {\n  color: #122B88;\n  font-size: 20px;\n  font-weight: bold;\n}\n.containBox_1BRPi0 .headTitle1_3Yijfp {\n  color: #122B88;\n  font-size: 30px;\n  text-align: center;\n  font-family: Roboto-regular;\n  font-weight: bold;\n}\n.containBox_1BRPi0 .headTitle2_3VMJjq {\n  text-align: center;\n  background-color: #D8BFD8;\n}\n.containBox_1BRPi0 .headTitle3_2oH0ob {\n  text-align: center;\n  background-color: #BBBBBB;\n}\n.containBox_1BRPi0 .primaryLang_3V4UR0 {\n  text-align: center;\n  background-color: #d9ced9;\n}\n", "",{"version":3,"sources":["webpack://src/pages/add_order/choose_lang/choose_lang.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AAHA;EAKI,cAAA;EACA,eAAA;EACA,iBAAA;AACJ;AARA;EAWI,cAAA;EACA,eAAA;EACA,kBAAA;EACA,2BAAA;EACA,iBAAA;AAAJ;AAfA;EAmBI,kBAAA;EACA,yBAAA;AADJ;AAnBA;EAyBI,kBAAA;EACA,yBAAA;AAHJ;AAvBA;EA8BI,kBAAA;EACA,yBAAA;AAJJ","sourcesContent":[".containBox {\n  display: flex;\n  flex-direction: column;\n\n  .headTitle {\n    color: #122B88;\n    font-size: 20px;\n    font-weight: bold;\n  }\n\n  .headTitle1 {\n    color: #122B88;\n    font-size: 30px;\n    text-align: center;\n    font-family: Roboto-regular;\n    font-weight: bold;\n  }\n\n  .headTitle2 {\n    text-align: center;\n    background-color: #D8BFD8;\n  }\n\n\n  .headTitle3 {\n    text-align: center;\n    background-color: #BBBBBB;\n  }\n\n  .primaryLang {\n    text-align: center;\n    background-color: rgb(217, 206, 217);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containBox": "containBox_1BRPi0",
	"headTitle": "headTitle_he5kZX",
	"headTitle1": "headTitle1_3Yijfp",
	"headTitle2": "headTitle2_3VMJjq",
	"headTitle3": "headTitle3_2oH0ob",
	"primaryLang": "primaryLang_3V4UR0"
};
export default ___CSS_LOADER_EXPORT___;
