// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".containBox_1mm-gU {\n  display: flex;\n  flex-direction: column;\n}\n.containBox_1mm-gU .headTitle_2MCGr3 {\n  font-size: 20px;\n  color: #122B88;\n  font-weight: bold;\n}\n.containBox_1mm-gU .headTitle1_nZeL36 {\n  font-size: 26px;\n  color: #122B88;\n  text-align: center;\n  font-weight: bold;\n}\n.containBox_1mm-gU .headTitle2_33GdQm {\n  font-size: 20px;\n  font-weight: bold;\n}\n.containBox_1mm-gU .certsBox_3kBM-p .certItem_2-cFXe:first-child {\n  margin-left: 8px;\n}\n.containBox_1mm-gU .btnBox_2xq5V5 {\n  margin-top: 40px;\n}\n.containBox_1mm-gU .btnBox_2xq5V5 .btnLeft_3Y-dYL {\n  margin-right: 30px;\n}\n", "",{"version":3,"sources":["webpack://src/pages/add_order/choose_cert/choose_cert.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AAHA;EAKI,eAAA;EACA,cAAA;EACA,iBAAA;AACJ;AARA;EAWI,eAAA;EACA,cAAA;EACA,kBAAA;EACA,iBAAA;AAAJ;AAdA;EAkBI,eAAA;EACA,iBAAA;AADJ;AAlBA;EAwBM,gBAAA;AAHN;AArBA;EA6BI,gBAAA;AALJ;AAxBA;EA+BM,kBAAA;AAJN","sourcesContent":[".containBox {\n  display: flex;\n  flex-direction: column;\n\n  .headTitle {\n    font-size: 20px;\n    color: #122B88;\n    font-weight: bold;\n  }\n\n  .headTitle1 {\n    font-size: 26px;\n    color: #122B88;\n    text-align: center;\n    font-weight: bold;\n  }\n\n  .headTitle2 {\n    font-size: 20px;\n    font-weight: bold;\n  }\n\n  .certsBox {\n    .certItem:first-child {\n      margin-left: 8px;\n    }\n  }\n\n  .btnBox {\n    margin-top: 40px;\n    .btnLeft {\n      margin-right: 30px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containBox": "containBox_1mm-gU",
	"headTitle": "headTitle_2MCGr3",
	"headTitle1": "headTitle1_nZeL36",
	"headTitle2": "headTitle2_33GdQm",
	"certsBox": "certsBox_3kBM-p",
	"certItem": "certItem_2-cFXe",
	"btnBox": "btnBox_2xq5V5",
	"btnLeft": "btnLeft_3Y-dYL"
};
export default ___CSS_LOADER_EXPORT___;
