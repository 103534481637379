// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".containBox_3R7Iv7 .listItem_1bQ0qp {\n  display: flex;\n  flex-wrap: wrap;\n  color: red;\n}\n.containBox_3R7Iv7 .bottomBtnBox_2zXqWe {\n  justify-content: space-between;\n}\n.containBox1_2dlREd {\n  display: flex;\n  background-color: blue;\n}\n.containBox1_2dlREd .box2_37of3O {\n  background: red;\n}\n.containBox1_2dlREd .box2_37of3O .box3_12e8Ej {\n  background: green;\n}\n.containBox1_2dlREd .box3_12e8Ej {\n  background: yellow;\n}\n", "",{"version":3,"sources":["webpack://src/pages/order_evaluate/order_evaluate.module.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,eAAA;EACA,UAAA;AAAJ;AAJA;EAOI,8BAAA;AAAJ;AAIA;EACE,aAAA;EAEA,sBAAA;AAHF;AAAA;EAMI,eAAA;AAHJ;AAHA;EASM,iBAAA;AAHN;AANA;EAcI,kBAAA;AALJ","sourcesContent":[".containBox {\n  .listItem {\n    display: flex;\n    flex-wrap: wrap;\n    color: red;\n  }\n  .bottomBtnBox{\n    justify-content: space-between;\n  }\n}\n\n.containBox1 {\n  display: flex;\n  //flex-direction: column;\n  background-color: blue;\n\n  .box2 {\n    background: red;\n\n    .box3 {\n      background: green;\n    }\n  }\n\n  .box3 {\n    background: yellow;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containBox": "containBox_3R7Iv7",
	"listItem": "listItem_1bQ0qp",
	"bottomBtnBox": "bottomBtnBox_2zXqWe",
	"containBox1": "containBox1_2dlREd",
	"box2": "box2_37of3O",
	"box3": "box3_12e8Ej"
};
export default ___CSS_LOADER_EXPORT___;
