// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".containBox_1uLQ_T {\n  margin-bottom: 50px;\n  text-align: center;\n}\n.containBox_1uLQ_T .title_1Fy6eY {\n  font-size: 28px;\n  text-align: center;\n  font-weight: bold;\n}\n.containBox_1uLQ_T .divider_d4LGg5 {\n  height: 2px;\n}\n.containBox_1uLQ_T .btnTop_2oE62Y {\n  font-size: 28px;\n  width: 260px;\n  height: 50px;\n  margin-top: 99px;\n  margin-bottom: 100px;\n  font-weight: bold;\n}\n.containBox_1uLQ_T .btnBottom_1gl6t7 {\n  font-size: 22px;\n  width: 260px;\n  height: 50px;\n  font-weight: bold;\n}\n", "",{"version":3,"sources":["webpack://src/pages/home_login/home_login.module.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,kBAAA;AACF;AAHA;EAKI,eAAA;EACA,kBAAA;EACA,iBAAA;AACJ;AARA;EAWI,WAAA;AAAJ;AAXA;EAeI,eAAA;EACA,YAAA;EACA,YAAA;EACA,gBAAA;EACA,oBAAA;EACA,iBAAA;AADJ;AAnBA;EAwBI,eAAA;EACA,YAAA;EACA,YAAA;EACA,iBAAA;AAFJ","sourcesContent":[".containBox {\n  margin-bottom: 50px;\n  text-align: center;\n\n  .title {\n    font-size: 28px;\n    text-align: center;\n    font-weight: bold;\n  }\n\n  .divider{\n    height: 2px;\n  }\n\n  .btnTop {\n    font-size: 28px;\n    width: 260px;\n    height: 50px;\n    margin-top: 99px;\n    margin-bottom: 100px;\n    font-weight: bold;\n  }\n\n  .btnBottom {\n    font-size: 22px;\n    width: 260px;\n    height: 50px;\n    font-weight: bold;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containBox": "containBox_1uLQ_T",
	"title": "title_1Fy6eY",
	"divider": "divider_d4LGg5",
	"btnTop": "btnTop_2oE62Y",
	"btnBottom": "btnBottom_1gl6t7"
};
export default ___CSS_LOADER_EXPORT___;
