import React from "react";
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Button, Col, Image, Row} from 'antd';
import {Link} from "react-router-dom";
import {useParams} from "react-router";
import ChooseLang from "./choose_lang/choose_lang";
import ChooseCert from "./choose_cert/choose_cert";
import ChooseSupportService from "./choose_support_service/choose_support_service";
import ChoosePrebooking from "./choose_prebooking/choose_prebooking";
import PrebookingTime from "./choose_prebooking/prebooking_time/prebooking_time";
import BookConfirm from "./choose_prebooking/book_confirm/book_confirm";

let store = require('../../store');
let loginData = store.login;
let config = require('commonProject/config/config');


function getStepPage(step) {
    if (step == 'choose_lang') return <ChooseLang/>;
    if (step == 'choose_cert') return <ChooseCert/>;
    if (step == 'choose_support_service') return <ChooseSupportService/>;
    if (step == 'choose_prebooking') return <ChoosePrebooking/>;
    if (step == 'prebooking_time') return <PrebookingTime/>;
    if (step == 'book_confirm') return <BookConfirm/>;
}

// create observer
let AddOrder = observer(function () {
    let urlParams = useParams();
    let step = urlParams.stepType;
    return (
        <div>
            {getStepPage(step)}
        </div>
    );
})

export default AddOrder;