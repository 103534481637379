import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {useLocation, useParams} from "react-router";
import {useHistory} from "react-router-dom";
import {Button, Col, Input, Row, message, Form, Radio, Select, Checkbox, Divider, Modal, Image} from "antd";
import {Link} from "react-router-dom";
import css from "./register_result.module.less";
import CountDown from "ant-design-pro/lib/CountDown";
import right_svg from 'src/assets/img/common/right.svg';

let store = require('../../../store');
let api = require('src/api');
let data = store.persist.register;
// eslint-disable-next-line react-hooks/rules-of-hooks
let history;
let commonUtils = require('commonProject/utils/Common');
let moment = require('moment');
let loginHelper = require('../../../utils/helper/login_helper');

function initData() {
    // data = store.persist.register;
}

// create observer
let RegisterResult = observer(function () {
    history = useHistory();
    let params = commonUtils.query2ObjParams(useLocation().search);
    let register_type = params.type ? params.type : 'success';//注册结果
    useEffect(() => {
        initData();
    }, []);

    return (
        <Col className={css.containBox}>
            {
                register_type == 'success' ? (
                    <div>
                        <Col align={'middle'}><Image src={right_svg} preview={false} className={css.successLogo}/></Col>
                        <div className={css.title}>Your profile is completed!</div>
                        <div className={css.ques}>What's next?</div>
                        <p className={css.stepDesc}><span className={'bold'}>Step 1:</span> We will call you and verify
                            you are real.</p>
                        <p className={css.stepDesc}><span className={'bold'}>Step 2:</span> (only needed if you want the
                            risk-free trial) You send us your past bills. We assign you one-week-equivalent trial credit
                            valid for 90 days according to your average minutes per week in the past. The "90 days"
                            start from the day you start to use our service.</p>
                        <p className={css.stepDesc}><span className={'bold'}>Step 3:</span> You add all your team
                            members (or we add them for you), set up a payment account, then you are ready to go. No
                            need to purchase equipment or install an app.</p>
                        <p className={css.stepDesc}><span className={'bold'}>Note:</span> You will need to pay for the risk-free credits only when you decide to keep using our services.</p>
                        <Col align={'middle'}>
                            <Button className={css.doneBtn + ' greyBtn'} onClick={() => {
                                if (store.persist.userInfo.status == 2) {
                                    loginHelper.logout(history);
                                } else {
                                    commonUtils.historyPushRefresh(history, '/');
                                }
                            }}>Done</Button>
                        </Col>
                    </div>
                ) : (
                    <div>

                    </div>
                )
            }
        </Col>
    );
});

export default RegisterResult;
