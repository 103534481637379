import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Button, Card, Col, Collapse, Divider, Image, Input, List, Menu, message, Row, Select, Upload} from 'antd';
import {Link, useHistory} from "react-router-dom";
import languages_logo from '../../assets/img/company_home/languages.png';
import company1 from '../../assets/img/company_home/company1.png';
import company2 from '../../assets/img/company_home/company2.png';
import company3 from '../../assets/img/company_home/company3.png';
import company4 from '../../assets/img/company_home/company4.png';
import company5 from '../../assets/img/company_home/company5.png';
import company6 from '../../assets/img/company_home/company6.png';
import company7 from '../../assets/img/company_home/company7.png';
import logo from '../../assets/img/company_home/logo.png';
import starImg from '../../assets/img/company_home/price/md-star_border.svg';
import css from './company_home.module.less';
import {useLocation} from "react-router";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import TextArea from "antd/lib/input/TextArea";

let store = require('../../store');
let api = require('src/api');
let moment = require('moment');
let history;
let config = require('commonProject/config/config');
let commonUtils = require('commonProject/utils/Common');
let mobx = require('mobx');

let data = mobx.observable({
    selectIndex: 'Home',
    questions: [
        {
            question: 'Why is your rate much lower than the market price?',
            answer: ['We use a patent pending innovative business model to cut costs and make the interpreting service very affordable—and that is why we were born.'],
        },
        {
            question: 'Why is your company address just a mailbox?',
            answer: ['Virtual mailbox is recognized by the IRS and governing bodies as a legal company address and can fulfill all its legal purposes, and its use is common practice for many modern technology companies. 100% of our innovative workforce work from home to save even more, and we pass on the savings to you. We do not and will not hire sales, which saves even more. It’s all about cutting costs.'],
        },
        {
            question: 'Is the interpreting service quality of your platform reliable?',
            answer: ['Certificated and qualified interpreters are key to interpreting service quality, not us. They are the ones who talk to you and your LEPs every time; we are just supporting them. We pay interpreters higher than market prices and aim at recruiting better quality interpreters. We screen all interpreter candidates strictly, keep reviewing their service quality, and may suspend or remove interpreters from our platform for under-performing. '],
        },
        {
            question: 'What equipment do I need for VRI (Video-Remote-Interpreting)?',
            answer: ['1) Any mobile device will work, e.g., laptop, iPad, iPhone, cell phone, etc. A desktop PC with a speaker and microphone is fine too. No app installation needed. ',
                '2) Stable high-speed internet within your facility for VRI, and access to the Chrome browser (our app is not optimized for other browsers yet).'],
        },
        {
            question: 'What equipment do I need for OPI (Over-Phone-Interpreting)?',
            answer: ['Any landline phone will work. You may prefer a cordless phone for mobility, with a DECT 6.0 standard handset for HIPAA compliance. Consult your internal compliance officer for rules about HIPAA compliance.'],
        },
        {
            question: 'How long do I need to train my team members to use your platform?',
            answer: ['We hope each employee should take no more than 10 minutes to learn how to use our platform following our instruction. The account owner and/or team admin may take a couple of hours to set up everything and make the whole team(s) ready to go.'],
        },
        {
            question: 'Is your interpreting service HIPAA compliant?',
            answer: ['Yes, and we say that with high confidence. Your remote video interpreting service is directly provided to you by the interpreters on our platform using the best-in-class HIPAA compliant "Zoom for Healthcare\'\' (or other equivalent if “Zoom for Healthcare” is down in rare cases). All the interpreters on our platform can provide BAA whenever needed, and we help you collect them. The system is intentionally designed so that the direct connection line between the interpreter and you does not pass through our server at all, for better and safer HIPAA compliance. This is just part of our patent pending innovations. More details can be found in our Terms and Conditions and Privacy Policy.'],
        },
        {
            question: 'Can I use my own TeleHealth tools?',
            answer: ['Good news is that you can use whatever TeleHealth tool you prefer at your organization. Simply send your TeleHealth link to the interpreter on our platform after the interpreter is paired with you.'],
        },
        {
            question: 'Do I have to pre-book the VRI or OPI service?',
            answer: ['1) It is highly encouraged to pre-book interpreting services whenever possible. You will get up to a 3% discount depending on how many hours in advance you pre-book a service.',
                '2) For less common languages or rare languages, the interpreters may work as little as an hour a day, so pre-booking can hugely help reduce your waiting time.'],
        },
        {
            question: 'Which one is better? VRI or OPI?',
            answer: ['VRI is likely better and more economical, especially with our super low VRI price.',
                '1) The average duration of a VRI service is often about 20–30% shorter than that of OPI, for unknown reasons. You actually save more with VRI since we charge OPI and VRI at the same rate.',
                '2) VRI is more preferred for users due to additional communication through facial expressions and body language.',
                '3) Note, you do need to speak louder when using VRI for the interpreter to hear clearly compared to the OPI format, because a VRI device is typically put further away from you than a phone handset.',
                '4) You need a stable and fast internet connection for VRI to work well. In the rare case that the app does not work for complicated technical reasons that are hard to solve immediately, switching to OPI is surely a better option.'],
        },
        {
            question: 'Can I choose an interpreter that I favor?',
            answer: ['Absolutely! Anytime, as long as the interpreter is available when you choose him/her using his/her ID (4 letters for language + 4 numbers). And this is at no additional cost to you. Just be aware that your interpreter may not be willing to wait at a specific time only for you, because the calls come in randomly at varying durations and they may simply get stuck in another call.'],
        },
        {
            question: 'Is your payment system safe?',
            answer: ['All payments on our platform are handled by Stripe, the industry-leading payment solution that is used by close to two million online businesses. We do not and will not store any of your payment information. All common payment options are available through Stripe.'],
        },
        {
            question: 'Do you provide on-site interpreting services?',
            answer: ['With our super low rate, and especially our super low Video-Remote Interpreting (VRI) rate, you may need to re-consider if you really prefer On-Site Interpreting (OSI). Saying that, we will provide the OSI function when there is enough demand from our users. Please let us know your thoughts on the Contact Us page; we are always listening.'],
        },
        {
            question: 'How do I start with InterpreTown?',
            answer: ['Step 1: You sign up with basic business info, and we verify you are real. Step 2: (only needed if you want a risk-free trial). You send us your bills from the past four weeks. We assign you "one-week-equivalent-minutes" risk-free credit according to your average minutes per week, valid for 90 days. You can hide the pricing information on your bills if needed. Step 3: You add your team members, sign up for a payment method, then you are ready to go. No special equipment or app installation needed. It\'s that simple. Note: You will need to pay for the risk-free credits only when you decide to keep using our services. If not satisfied, you can just walk away and won\'t be charged.'],
        },
        {
            question: 'Can I contact an interpreter offline and pay the interpreter?',
            answer: ['As innovative as we are, the answer is yes, but it depends.',
                'Although we discourage you to do so for good reasons, you are indeed allowed to do so if you follow the rules below:',
                '1) You have to get the interpreter\'s voluntary written permit with expiration on the platform before contacting them offline. Details in Terms and Conditions. Users violating this rule will be warned, suspended, or removed from our platform. We aim to build a platform of mutual trust between users and interpreters, and we keep only decent players on both sides.',
                '2) In case there are activities that happen offline (e.g., on-site interpreting services or similar), you pay the interpreter according to your mutual agreement, and we are excluded from any related responsibilities or liabilities. You also need to send all interpreters 1099 Tax Forms as per relevant tax laws.',
                'With our innovative business model, we can survive by charging a super low 15% service fee on average, unlike many other companies who charge an 80–100% service fee. When you contact an interpreter offline, we believe the effort and cost you put in are likely to make it a more costly solution than using our curated platform due to the small size of each transaction and our super low service fee. We provide integrated and comprehensive services to save all your efforts, e.g., sending annual 1099 Tax Forms to all interpreters, collecting BAAs, payment convenience, guarantees and disputes, facilitating liability resolution, screening candidates, continuous interpreting service quality controls, interpreters’ integration and flexibility on the platform, etc. '],
        },
        {
            question: 'Can I pre-book a specific interpreter?',
            answer: ['Yes and No.',
                '1) You are highly encouraged to pre-book an interpreter, but you cannot pre-book a specific interpreter from our platform, or not yet. Saying that, you can still try to find a specific interpreter at the moment you request a call, as long as the interpreter is available.',
                '2) Please understand that a specific interpreter is not really willing to be pre-booked and wait for your appointment since he/she has to log out of the platform about 30 mins beforehand in order to avoid being stuck on another call. Saying that, if your call will last at least two hours or longer, an interpreter may still agree to take your appointment call. Just ask him/her to log into the platform at your appointed time.'],
        },
        {
            question: 'How can I save further on interpretation service costs?',
            answer: ['Glad you asked! As a user, you can significantly improve the interpreting service quality and shorten the duration in the following ways:',
                '1) Speaking louder and/or moving the device closer to you. The microphone is notorious for causing the voice to drop when it is more than 2 feet away from your mouth, in which case you need to speak louder to avoid voice breakup. Your interpreter will be very appreciative if you do.',
                '2) Using less jargon and speaking clearly will greatly help your interpreters, as will speaking slower when the interpreter cannot catch up.',
                '3) Sharing your screen with the interpreter during a VRI when using a long questionnaire form, so that you may not even need to read the questions one by one.'],
        },
        {
            question: 'Do you have LEP or interpreting procurement resources that can help me?',
            answer: ['There are many excellent online resources for language interpreting services-related topics and procurement. We collected and listed them on our website (click the Resources link at the bottom of this page). '],
        },
    ],
    query: {},
    isLoadByClickHeaderMenu: false,//是否点击顶部菜单加载的页面
    contact: {
        uploading: false,//正在上传
        urgencys: [
            {
                key: 'Not Urgent (reply in 1 to 2 week)',
                value: 'Not Urgent (reply in 1 to 2 week)',
            },
            {
                key: 'Attention (reply in 1 to 2 days)',
                value: 'Attention (reply in 1 to 2 days)',
            },
            {
                key: 'Urgent (reply in less than 2 hours)',
                value: 'Urgent (reply in less than 2 hours)',
            },
        ],
        topics: ['User Side Related ',
            'Future Users Questions',
            'General Questions and/or Suggestions',
            'Interpreter Related',
            'Others (please specify in Comments).',
        ],
        files: [],
        params: {
            name: '',
            email: '',
            phone: '',
            organization: '',
            title: '',
            urgency: '',
            topic: '',
            describe: '',
            file_id: '',
        }
    }
})

function getTypeView() {
    if (!data.isLoadByClickHeaderMenu) {
        switch (data.query.type) {
            case 'services':
                return getServiceView();
            case 'aboutUs':
                return getAboutUsView();
            case 'userTutorials':
                return getUserTutorialsView();
            case 'userResources':
                return getUserResourcesView();
            case 'workWithUs':
                return getWorkWithUsView();
            case 'becomingAnInterpreter':
                return getBecomingAnInterpreterView();
            case 'termsAndConditions':
                return getTermsAndConditionsView();
            case 'privacyPolicy':
                return getPrivacyPolicyView();
        }
    }

    switch (data.selectIndex) {
        case "Home":
            return getHomeView();
        case 'Price':
            return getPriceView();
        case 'FAQ':
            return getFAQView();
        case 'Contact':
            return getContactView();
        case 'Login':
            return;
    }
}

function getHomeView() {
    return (
        <div className={css.homeBox}>
            <div className={css.contentBox}>
                <div className={css.contentLeft}>
                    <p>Still Paying $53/hr (88¢/min) for phone interpreting? Don't overpay, we cut the price by 30%.</p>
                    <p>Still paying $62/hr (103¢/min) for video interpreting? Do not overpay, we cut your cost by
                        40%.</p>
                    <div className={'hintGray'} style={{textAlign: 'right'}}>* For details see Pricing page</div>
                </div>

                <Image className={css.logo}
                       src={company1} width={100}
                       preview={false}/>
            </div>
            <div className={css.contentBox}>
                <Image className={css.logo}
                       src={company2} width={100}
                       preview={false}/>
                <span className={css.contentRight}>Big businesses enjoy big discounts but not you? We use flat rates for businesses of all sizes. You’ll enjoy discounts the same as big ones.</span>

            </div>
            <div className={css.contentBox}>
                <span className={css.contentLeft}>We integrate the best-in-class "Zoom for healthcare" video solutions.
Still prefer to use your own TeleHealth system? Not a problem.</span>
                <Image className={css.logo}
                       src={company4} width={100}
                       preview={false}/>
            </div>
            <div className={css.contentBox}>
                <Image className={css.logo}
                       src={company3} width={100}
                       preview={false}/>
                <span className={css.contentRight}>Have preferred interpreters? Feel free to choose your favorite interpreters at any time, at no additional cost. Why not?</span>
            </div>
            <div className={css.contentBox}>
                <span className={css.contentLeft}>Initial investment? Forget it. Just use your existing devices (e.g., laptop, tablet, smartphone, landline phone, desktop, etc.) to start right away.</span>
                <Image className={css.logo}
                       src={company5} width={100}
                       preview={false}/>
            </div>
            <div className={css.contentBox}>
                <Image className={css.logo}
                       src={company7} width={100}
                       preview={false}/>
                <span className={css.contentRight}>Not satisfied with the interpreting quality? We keep monitoring it and suspend or remove under-performing interpreters periodically.</span>
            </div>
            <div className={css.contentBox + ' ' + css.lastContentBox}>
                <span className={css.contentLeft}>With our risk-free trial*, pay only if you are satisfied!  No setup fee, no monthly fee, no hidden fee — you pay only when you use it.</span>
                <Image className={css.logo}
                       src={company6} width={100}
                       preview={false}/>
            </div>

            <Row justify='center'>
                <div className={'hintGray'}>* You pay risk-free credits only when you want to continue using our
                    services after the trial.
                </div>
                <div className={'hintGray ' + css.oneWeekEquivalent}>* One-week-equivalent credits after we verify your
                    normal usage.
                </div>
            </Row>


            {getHomeLoginAndContactBottom()}
        </div>
    )
}

function getHomeLoginAndContactBottom() {
    return (
        <div>
            <p className={'wineRed font18'}>We will formally launch 40 languages interpreting services on Nov 19th.</p>
            <p className={'darkBlue font20'}>Sign up now to qualify for your risk-free trial, or for email updates
                only. </p>
            <Button className={'greyBtn darkBlue bold'}>Sign Up</Button>
            <div className={'wineRed font18 ' + css.hurry}>Hurry! First 100 registered users enjoy two-weeks-equivalent
                credits instead of one-week!
            </div>
            <p className={css.ques + ' font18'}>Questions, comments, suggestions? We are always listening. </p>
            <Button className={'greyBtn darkBlue bold'}>Contact Us</Button>
            <Divider/>
            <div className={css.descBox}>
                <h3 className={css.descTitle}>Starting with us is Easy</h3>
                <p className={css.desc}><span className={'bold'}>Step 1:</span> You signup with basic business info, and
                    we verify you are real.</p>
                <p className={css.desc}><span className={'bold'}>Step 2:</span>(only needed if you want the
                    risk-free trial) You send us your past bills. We assign you one-week-equivalent trial credit valid
                    for 90 days according to your average minutes per week in the past. The "90 days" start from the day
                    you start to use our service.</p>
                <p className={css.desc}><span className={'bold'}>Step 3:</span> You add all your team members
                    (or we add them for you), set up a payment account, then you are ready to go. It's that simple! No
                    need to purchase equipment or install an app.</p>
                <p className={css.desc}><span className={'bold'}>Note: </span> You will need to pay for the
                    risk-free credits only when you decide to keep using our services. Not satisfied? Just walk away and
                    you won't be charged!</p>
            </div>
        </div>
    )
}

function getPriceView() {
    return (
        <div className={css.priceBox}>
            <h3 className={css.topTitle}>Interpreting Service Pricing</h3>
            <p className={css.titleDesc}>Our patent pending business model is aimed at drastically cutting your
                costs!</p>
            <p className={css.titleDesc}>We treat small businesses the same as big ones with the same price.</p>
            <p className={css.titleDesc}>We love price transparency and never hide our prices—plus, no fees.</p>
            <p className={css.simple}>Simple, Universal, Dual Flat Rates</p>
            <Row align={'center'} className={css.langBoxBig}>
                <div className={css.langBox}>
                    <span className={css.langName}>Spanish</span>
                    <span className={css.price}>$0.54/min</span>
                    <span className={css.priceDesc}>(=$32.40/hr)</span>
                </div>
                <div className={css.langBox}>
                    <span className={css.langName}>Other Languages</span>
                    <span className={css.price}>$0.76/min</span>
                    <span className={css.priceDesc}>(=$32.40/hr)</span>
                </div>
                <div className={css.langBox}>
                    <span className={css.langName}>ASL</span>
                    <span className={css.price}>$0.9/min</span>
                    <span className={css.priceDesc}>(=$54.00/hr)</span>
                </div>
            </Row>
            <span
                className={'hintGray'}>
                <span className={css.calcDesc}>* Our equivalent flat-rate</span>
                = ($0.54/min)*65%+($0.76/min)*35% =
                <span className={css.calcDesc}>$0.62/min ($37.02/hr)</span>
            </span>
            <div/>
            <span
                className={'hintGray'}>* ASL is unique and counted separately because it is not a spoken language.</span>
            <Row align={'center'} className={css.descItemBox}>
                <Image src={starImg}/>
                <p className={css.descItem}>The rate applies to both <span className={'bold'}>VRI</span> (Video-Remote)
                    and <span className={'bold'}>OPI</span> (Over-Phone). VRI is available for <span
                        className={'bold'}>all</span> languages using the best-in-class "Zoom for Healthcare".</p>
            </Row>
            <Row align={'center'} className={css.descItemBox}>
                <Image src={starImg}/>
                <p className={css.descItem}>Our <span className={'bold'}>flat-rates</span> (except ASL) is equivalent
                    to <span className={'bold'}>$37.02/hr ($0.62/min)</span>, assuming your Spanish call minutes account
                    for 65% or more of your total call minutes, which is most common. </p>
            </Row>
            <Row align={'center'} className={css.descItemBox}>
                <Image src={starImg}/>
                <p className={css.descItem}>$37.02/hr is 30% lower than $53/hr and 40% lower than $62/hr! Market price
                    range is mostly around $53/hr to $62/hr for VRI and/or OPI.</p>
            </Row>
            <Row align={'center'} className={css.descItemBox}>
                <Image src={starImg}/>
                <p className={css.descItem}>Enjoy our transparent pricing: no setup fee, no recurring fee, no hidden
                    fee. You pay only when you use the services, billed weekly (if more than $30).</p>
            </Row>
            <Row align={'center'} className={css.descItemBox}>
                <Image src={starImg}/>
                <p className={css.descItem}><span className={'bold'}>ASL</span> (American Sign Language) interpreting
                    service rate is <span className={'bold'}>$0.90/min</span>. Market rate is around $1.25/min, which is
                    38% higher than our price.</p>
            </Row>

            {getHomeLoginAndContactBottom()}

            <div className={css.langTableBox}>
                <h3 className={css.title}>List of 40 languages we provide VRI & OPI services</h3>
                <span className={'hintGray'}>(generally covering 95%+ of all your need)</span>

                <div>
                    <Image src={languages_logo}/>
                </div>
            </div>

        </div>
    );
}

function getFAQView() {
    return (
        <div className={css.faqBox}>
            <h1 className={css.title}>User FAQ's</h1>
            <Collapse ghost style={{textAlign:'left',width:'900px'}}>
                {
                    data.questions.map(itemQues => {
                        return (
                            <CollapsePanel key={itemQues.question} header={itemQues.question} className={css.ques}>
                                {
                                    itemQues.answer.map(item => {
                                        return <div className={css.answer}>{item}</div>
                                    })
                                }
                            </CollapsePanel>
                        )
                    })
                }
            </Collapse>
        </div>
    );
}

function getContactView() {

    return (
        <div className={css.contactBox}>
            <h1 className={css.title}>Contact Us</h1>
            <Input className={css.inputStyle} placeholder='Name' onChange={(e) => {
                data.contact.params.name = e.target.value
            }}/>
            <Input className={css.inputStyle} placeholder='Email' onChange={(e) => {
                data.contact.params.email = e.target.value
            }}/>
            <Input className={css.inputStyle} placeholder='Phone' onChange={(e) => {
                data.contact.params.phone = e.target.value
            }}/>
            <Input className={css.inputStyle} placeholder='Organization' onChange={(e) => {
                data.contact.params.organization = e.target.value
            }}/>
            <Input className={css.inputStyle} placeholder='title' onChange={(e) => {
                data.contact.params.title = e.target.value
            }}/>
            <div className={css.selectBox}>
                <span className={css.inputDesc}>Urgency:</span>
                <Select placeholder={'choose urgency'} className={css.selectType} onChange={(value) => {
                    data.contact.params.urgency = value;
                }}>
                    {data.contact.urgencys.map(item => {
                        return (
                            <Select.Option value={item.value}>
                                {item.key + ''}
                            </Select.Option>
                        )
                    })}
                </Select>
            </div>
            <div className={css.selectBox}>
                <span className={css.inputDesc}>Topic:</span>
                <Select placeholder={'choose topic'} className={css.selectType} onChange={(value) => {
                    data.contact.params.topic = value;
                }}>
                    {data.contact.topics.map(item => {
                        return (
                            <Select.Option value={item}>
                                {item + ''}
                            </Select.Option>
                        )
                    })}
                </Select>
            </div>
            <span className={css.inputDesc}>Comments:</span>
            <TextArea rows={4} placeHolder='' onChange={e => {
                data.contact.params.describe = e.target.value;
            }}/>
            {/*<div className={css.uploadFileBox}>*/}
            {/*    <span>Attachment:</span>*/}
            {/*    <Upload fileList={data.contact.files}*/}
            {/*            onRemove={file => {*/}
            {/*                const index = data.contact.files.indexOf(file);*/}
            {/*                const newFileList = data.contact.files.slice();*/}
            {/*                newFileList.splice(index, 1);*/}
            {/*                return {*/}
            {/*                    fileList: newFileList,*/}
            {/*                };*/}
            {/*            }}*/}
            {/*            beforeUpload={file => {*/}
            {/*                data.contact.files = [...data.contact.files, file]*/}
            {/*                return false;*/}
            {/*            }}>*/}
            {/*        <Button>Select File</Button>*/}
            {/*    </Upload>*/}
            {/*    <Button*/}
            {/*        type="primary"*/}
            {/*        onClick={async () => {*/}
            {/*            const formData = new FormData();*/}
            {/*            data.contact.files.forEach(file => {*/}
            {/*                formData.append('files[]', file);*/}
            {/*            });*/}
            {/*            data.contact.uploading = true;*/}
            {/*            // You can use any AJAX library you like*/}
            {/*            let res = await api.user.add_file(formData);*/}
            {/*            if (res.code == 0) message.success('upload success');*/}
            {/*            data.contact.params.file_id = res.data._id;*/}
            {/*            data.contact.uploading = false;*/}
            {/*        }}*/}
            {/*        disabled={data.contact.files.length === 0}>*/}
            {/*        {data.contact.uploading ? 'Uploading' : 'Start Upload'}*/}
            {/*    </Button>*/}
            {/*</div>*/}
            {/*<p className={'hintGray'}>File Type: MS Word, Google Doc, PDF, JPEG, PNG.</p>*/}
            <Col align={'center'}>
                <Button className={'greyBtn'} onClick={async () => {
                    if (!commonUtils.isEmail(data.contact.params.email)) return message.error('email format is error');
                    if (!commonUtils.isUSAPhone(data.contact.params.phone)) return message.error('phone format is error');
                    let res = await api.user.contact_us(data.contact.params);
                    if (res.code == 0) return message.success('send success');
                }}>Submit</Button>
            </Col>
        </div>
    );
}

function getServiceView() {
    return (
        <div className={css.servicesBox}>
            <h1 className={css.title}>Services Available</h1>
            <p>Our platform hosts VRI (Video-Remote Interpreting) and OPI (Over-Phone Interpreting) services with very
                affordable prices for healthcare, financial, legal, community, and industrial organizations.</p>
            <p>We provide certified and qualified interpreters covering the most common 40 languages in the United
                States. The VRI and OPI services are HIPAA compliant, and BAAs for VRI are available upon
                request.</p>
            <p> Our super low-priced VRI is very economical and convenient compared to the OSI (On-Site
                Interpreting) service; you may think again if you really need OSI or not.</p>
            <p>We may provide OSI in the future if there is enough demand from users. Currently, we still have an
                alternative to OSI. Check out the FAQ page.</p>
        </div>
    );
}


function getAboutUsView() {
    return (
        <div className={css.servicesBox}>
            <h1 className={css.title}>About Us</h1>
            <p>Our mission is to support millions of LEPs that require accurate English communications by constantly
                cutting down the language interpreting cost with quality service.
            </p>
            <p>We aim to build mutual trust between interpreting service users and language interpreters on our platform
                to facilitate all special needs.</p>
            <p> We don't hear—we listen. We listen to all our users and interpreters. Please contact us for any
                questions, suggestions, or concerns. We would love to hear from you.</p>
            <div className={'hintGray'}>
                *. LEP stands for Limited English Proficiency population (~21 millions) in the US.
            </div>
            <div className={'hintGray'}>
                *. https://www.lep.gov/ is the official government website for LEP-related topics and support.
            </div>
        </div>
    );
}

function getUserTutorialsView() {
    return (
        <div className={css.servicesBox}>
            <h1 className={css.title}>User Tutorials</h1>
            <p>This content is only visible to validated users. Please sign up first.</p>
        </div>
    );
}

function getUserResourcesView() {
    return (
        <div className={css.userResourcesBox}>
            <h1 className={css.title}>User Resources</h1>
            <p>There are many great online resources related to providing language interpreting services to LEPs. If a
                link is not working, searching the key words in the link often gets you to the original webpages. Let us
                know if you want us to provide more links on this page.</p>
            <div className={css.contentBox}>
                <p>** https://www.LEP.gov Official government website for supporting LEPs.</p>

                <p> ** <span className={'underline'}>AAFP</span>: https://www.aafp.org/fpm/2014/0300/p16.html
                    <span className={'underline'}>Incorporating Medical Interpretation Into YourPractice</span>(2014).
                </p>
                <p>** <span className={'underline'}>Medicaid and SCHIP reimbursement models for language services</span>.
                    Washington, D.C. National Health
                    Law Program; 2007 (2009 update).</p>
                <p> https://healthlaw.org/resource/medicaid-and-chip-reimbursement-models-for-language-services/
                </p>
                <p>** <span className={'underline'}>National Council on Interpreting in Healthcare</span>.
                    https://www.ncihc.org/home NCIHC's mission is to
                    promote and enhance language access in healthcare in the United States.</p>

                <p>** https://www.gsa.gov/cdnstatic/Foreign_Language_Services_%281%29.pdf
                    <span className={'underline'}>Foreign Language Services Ordering Guide</span> by Members of the
                    Language Services
                    Procurement Committee. August 2016. Updated July 2020</p>

                <p>** <span className={'underline'}>Interpreting industry report</span>.
                    https://www.nimdzi.com/category/interpreting/</p>
            </div>
        </div>
    );
}

function getWorkWithUsView() {
    return (
        <div className={css.servicesBox}>
            <h1 className={css.title}>Work with Us</h1>
            <p>Are you interested in working with a startup company and advancing your career? We are recruiting
                self-motivated, self-disciplined, and culture-fit employees, with most positions giving you the
                convenience of working from home.</p>
            <p>Our culture is "Listen, Innovate, Share, and be Transparent". The list below is for current position
                openings, but you are always encouraged to send a resume to us on our Contact Us page for future
                considerations even if the position is not listed, as we will periodically review our talent pool.</p>
            <p>IT Helpdesk</p>
            <p>Marketing Manager</p>
        </div>
    );
}

function getBecomingAnInterpreterView() {
    return (
        <div className={css.becomingBox}>
            <h1 className={css.title}>Becoming an Interpreter</h1>
            <p>At InterpreTown, we always try to design a better and innovative pay scheme and strive to attract the
                most qualified interpreters through various incentives, and higher and stable call volumes for
                contractor interpreters. Currently, 40 languages make up our recruiting list (see the list below).
                Working with us will bring you a very different experience and you will find it highly rewarding.
            </p>
            <p> If you are interested, please send us your resume and/or your questions directly to interpreter at
                interpretown dot com. Minimum one year full-time equivalent interpreting experience is required;
                medically and/or legally certified interpreters highly preferred. Currently, work-from-home
                contractors are being recruited.
            </p>
            <p> A Qualified Interpreter for an individual with limited English proficiency means an interpreter who,
                via a remote interpreting service or an on-site appearance,
            </p>
            <p> i. adheres to generally accepted interpreter ethics and principles, including client
                confidentiality;
            </p>
            <p> ii. has demonstrated proficiency in speaking and understanding both spoken English and at least one
                other spoken language; and
            </p>
            <p> iii. is able to interpret effectively, accurately, and impartially, both receptively and expressly,
                to and from such language(s) and English, using any necessary specialized vocabulary, terminology,
                and phraseology.
            </p>

            <div className={css.payBox}>
                <div className={css.itemLine}>
                    <span>Pay Scale:</span>
                    <span>Level 1  </span>
                    <span>Level 2  </span>
                    <span>Level 3  </span>
                    <span>Level 4  </span>
                </div>
                <div className={css.itemLine}>
                    <span>Span (OPI & VRI)</span>
                    <span> $0.367/min </span>
                    <span>$0.403/min  </span>
                    <span>$0.460/min  </span>
                    <span>$0.524/min  </span>
                </div>
                <div className={css.itemLine}>
                    <span>Others (OPI & VRI)</span>
                    <span>$0.550/min  </span>
                    <span>$0.605/min  </span>
                    <span>$0.690/min  </span>
                    <span>$0.786/min  </span>
                </div>
                <div className={css.itemLine}>
                    <span>ASL</span>
                    <span>$0.633/min  </span>
                    <span>$0.697/min  </span>
                    <span>$0.787/min  </span>
                    <span>$0.786/min  </span>
                </div>
            </div>

            <p>Bonuses: we have comprehensive bonus system. For example, most languages have language type bonus up to
                60%, the lesser call volume the higher bonus. In addition, all languages have various bonus up to 16%.
                Please send us your resume to find our more.</p>
            <div className={css.languageBox}>
                <h1 className={css.title}>List of 40 languages on our platform:</h1>
                <span className={'hintGrayItalic'}>(minimum one year full time equivalent experience required before you apply)</span>
                {
                    ['Albanian',
                        'Amharic',
                        'Arabic',
                        'Armenian',
                        'ASL',
                        'Bengali',
                        'Bosnian',
                        'Bulgarian',
                        'Burmese',
                        'Cambodian/Khmer',
                        'Cantonese',
                        'Croatian',
                        'Farsi',
                        'French',
                        'German',
                        'Greek',
                        'Gujarati',
                        'Haitian Creole',
                        'Hindi',
                        'Hmong',
                        'Italian',
                        'Japanese',
                        'Karen',
                        'Korean',
                        'Kurdish',
                        'Laotian',
                        'Lithuanian',
                        'Mandarin',
                        'Nepali',
                        'Oromo',
                        'Pashto',
                        'Polish',
                        'Portuguese (Brazil)',
                        'Portuguese (EU)',
                        'Punjabi',
                        'Romanian',
                        'Russian',
                        'Serbian',
                        'Somali',
                        'Spanish',
                        'Swahili',
                        'Tagalog',
                        'Thai',
                        'Tigrinya/Tigrigna',
                        'Turkish',
                        'Ukranian',
                        'Urdu',
                        'Vietnamese'].map(item => {
                        return (
                            <div>
                                {item}
                            </div>
                        )
                    })
                }
            </div>

        </div>
    );
}

function getTermsAndConditionsView() {
    return (
        <div className={css.termsAndConditionsBox}>
            <h1 className={css.title}>Terms and Conditions</h1>
            <p>Terms and Conditions</p>
            <p>Last updated: October 1, 2022</p>
            <div className={css.secondTitle}>Interpretation and Definitions</div>
            <div className={css.secondTitle}>Interpretation</div>
            <p>The words of which the initial letter is capitalized are specifically defined herein by the Company. The
                following definitions shall have the same meaning regardless of whether they appear in singular or in
                plural.</p>
            <div className={css.secondTitle}>Definitions</div>
            <p>For the purposes of these Terms and Conditions:</p>
            <p>
                <span className={css.thirdTitle}>Affiliate</span> means an entity that controls, is controlled by or is
                under common control with a party, where "control"
                means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for
                election of directors or other managing authority.</p>
            <p>
                <span className={css.thirdTitle}>Account</span> means a unique account created for You to access our
                Service or parts of our Service.</p>
            <p>
                <span className={css.thirdTitle}>Country</span> refers to the United States of America.</p>
            <p>
                <span className={css.thirdTitle}>Company</span> (referred to as either "the Company", "We", "Us" or
                "Our" in this Agreement) refers to InterpreTown LLC, 3409 N 7th Ave C109, Unit #414. Phoenix, AZ 85013.
            </p>
            <p>
                <span className={css.thirdTitle}>Content</span> refers to information conveyed in the medium of text,
                images, audio, video, or other information posted, uploaded, linked to or otherwise made available by
                You or to You, through Our Service regardless of the form of medium or method of availability.</p>
            <p>
                <span className={css.thirdTitle}>Device</span> means any device by which You access the Service such as
                a computer, a cellphone or a digital tablet.</p>
            <p>
                <span className={css.thirdTitle}>Feedback</span> means feedback, innovations or suggestions sent by You
                regarding the attributes, performance or features of Our Service.</p>
            <p>
                <span className={css.thirdTitle}>Promotions</span> Promotions refer to contests, sweepstakes or other
                promotions offered through the Service.</p>
            <p>
                <span className={css.thirdTitle}>Service</span> refers to all functions and features available
                exclusively on Our Website, facilitating an interaction between You and an Interpreting Service. Under
                no circumstance should Our Service be treated or considered an Interpreting Service.</p>
            <p>
                <span className={css.thirdTitle}>Interpreting Service</span> refers to the third-party translation
                service requested by You, facilitated by Our Website. This Interpreting Service encompasses the
                relationship You have with the independent contractor(s) listed on Our Website, and the service You
                receive through the Third-Party Communication Service Provider utilized between You and your chosen
                contractor.</p>
            <p>
                <span className={css.thirdTitle}>Interpreting Service Provider</span> refers to the independent
                contractor(s) who You select as your preferred provider of the Interpreting Service, as they may appear
                on Our Website.</p>
            <p>Third-Party Communication Service Provider refers to the company You and your Interpreting Service
                Provider have chosen to facilitate a secure conduit for electronic communication. </p>
            <p>
                <span className={css.thirdTitle}>Terms and Conditions</span> (also referred as "Terms") mean these Terms
                and Conditions that form the entire agreement between You and the Company regarding the use of the
                Service.</p>
            <p>
                <span className={css.thirdTitle}>Third-party Social Media Service</span> means any services or content
                (including data, information, products or services) provided by a third-party that may be displayed,
                included or made available by the Service.</p>
            <p>
                <span className={css.thirdTitle}>Website</span> refers to InterpreTown, accessible from
                https://www.interpretown.com</p>
            <p>
                <span className={css.thirdTitle}>You</span> means any individual, company, or other legal entity on
                behalf of which such individual is accessing or using the Service.</p>

            <div className={css.secondTitle}>Acknowledgment</div>
            <p>These are the Terms and Conditions governing the use of this Service and the agreement that operates
                between You and the Company. These Terms and Conditions set out the rights and obligations of all users
                regarding the use of the Service.
            </p>
            <p>Your access to and use of the Service is conditioned on Your acceptance of and compliance with these
                Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or
                use the Service.
            </p>
            <p>By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree
                with any part of these Terms and Conditions then You may not access the Service.
            </p>
            <p>You represent that you are over the age of 18. The Company does not permit those under 18 to use the
                Service.
            </p>
            <p>Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the
                Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the
                collection, use and disclosure of Your personal information when You use the Application or the Website
                and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy
                carefully before using Our Service.
            </p>
            <div className={css.secondTitle}>Promotions</div>
            <p>Any Promotions made available through the Service may be governed by rules that are separate from these
                Terms.
            </p>
            <p>If You participate in any Promotions, please review the applicable rules as well as our Privacy policy.
                If the rules for a Promotion conflict with these Terms, the Promotion rules will apply.
            </p>
            <div className={css.secondTitle}>User Accounts</div>
            <p>When You create an account with Us, You must provide Us information that is accurate, complete, and
                current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate
                termination of Your account on Our Service.
            </p>
            <p>You are responsible for safeguarding the password that You use to access the Service and for any
                activities or actions under Your login credentials, whether Your password is with Our Service or a
                Third-Party.
            </p>
            <p>You agree not to disclose Your password to any third-party. You must notify Us immediately upon becoming
                aware of any breach of security or unauthorized use of Your account.
            </p>
            <p>You understand all usernames are a unique alpha-numeric identifier. Your username will be visible to
                other users who benefit from Our Service, You may not use as a username the name of another person or
                entity’s likeness or that which is not lawfully available for use, without appropriate authorization. We
                withhold the right to refuse registration or discontinue Service for any username We find to be
                offensive, vulgar or obscene.
            </p>
            <div className={css.secondTitle}>Content</div>
            <div className={css.thirdTitle}>Your Right to Post Content</div>
            <p>Our Service allows You to post Content. You are responsible for the Content that You post to the Service,
                including its legality, reliability, and appropriateness.
            </p>
            <p>By posting Content to the Service, You grant Us the perpetual, irrevocable, non-exclusive, transferable,
                sublicensable, royalty free license to use, adapt, synchronize and otherwise exploit Your Content in any
                manner and any media, formats, and channels now known or later developed or discovered throughout the
                universe in perpetuity, in order to provide the Service to You(including monitoring and improving the
                same), without notice or payment to You. You retain any and all non-waivable privacy rights to any
                Content You submit, post or display on or through the Service, as applicable in Your jurisdiction, and
                You are responsible for protecting those rights. You agree that this license includes the right for Us
                to make Your Content available to other users of the Service, who may also use Your Content subject to
                these Terms.
            </p>
            <p>You understand, due to the nature of Our Service, any Content You submit through Our Website should be
                treated as if it is not encrypted and/or does not meet HIPAA or other prevailing data standards as would
                be applicable to an Interpreting Service.
            </p>
            <p>You represent and warrant that: (i) the Content is Yours (You own it) or You have the right to use it and
                grant Us the rights and license as provided in these Terms, and (ii) the posting of Your Content on or
                through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights
                or any other rights of any person.
            </p>
            <div className={css.thirdTitle}>Content Restrictions</div>
            <p>The Company is not responsible for the actions of users who benefit from Our Service. You expressly
                understand and agree that You are solely responsible for the Content and for all activity that occurs
                under your account, whether done so by You or any third person using Your account.
            </p>
            <p>You may not transmit any Content that is unlawful, offensive, upsetting, intended to disgust,
                threatening, libelous, defamatory, obscene or that We find otherwise objectionable. Examples of such
                objectionable Content include, but are not limited to, the following:
            </p>
            <p>●Conducting or promoting unlawful activity.
            </p>
            <p>●Defamatory, discriminatory, or mean-spirited content, including references or commentary about religion,
                race, sexual orientation, gender, national/ethnic origin, or other targeted groups.
            </p>
            <p>●Spam, machine – or randomly – generated, constituting unauthorized or unsolicited advertising, chain
                letters, any other form of unauthorized solicitation, or any form of lottery or gambling.
            </p>
            <p>●Containing or installing any viruses, worms, malware, trojan horses, or other content that is designed
                or intended to disrupt, damage, or limit the functioning of any software, hardware or telecommunications
                equipment or to damage or obtain unauthorized access to any data or other information of a third person.
            </p>
            <p>●Infringing on any proprietary rights of any party, including patent, trademark, trade secret, copyright,
                right of publicity or other rights.
            </p>
            <p>●Impersonating any person or entity including the Company, its employees, or representatives.
            </p>
            <p>●Violating the privacy of any person.
            </p>
            <p>●False information and features.
            </p>
            <p>The Company reserves the right, but not the obligation, to, in its sole discretion, determine whether or
                not any Content is appropriate and complies with this Terms, refuse or remove this Content. The Company
                further reserves the right to make formatting and edits and change the manner of any Content. The
                Company can also limit or revoke the use of the Service if You post such objectionable Content. As the
                Company cannot control all content posted by users and/or third parties on the Service, you agree to use
                the Service at your own risk. You understand that by using the Service You may be exposed to content
                that You may find offensive, indecent, incorrect or objectionable, and You agree that under no
                circumstances will the Company be liable in any way for any content, including any errors or omissions
                in any content, or any loss or damage of any kind incurred as a result of your use of any content. You
                agree that if you discover Content on Our Service, You believe is in violation of these Terms, You will
                submit evidence such content, and the Terms believed to be violated, to Legal@interpretown.com within
                twenty-four (24) hours of discovery.
            </p>
            <p>
                You must not remove, copy, or memorize any Content obtained from the Website about other users without
                expressed permission. This Content includes, phone numbers, usernames, meeting links, or other
                personally identifiable information made aware through the use of Our Service. Under no circumstances
                may any user attempt to contact any other user outside of this Website without expressed permission. A
                request for written permission may be available on your account, if granted, such requests carry a
                revocable expiration period of one year. Any violation of this policy may result in an indefinite
                suspension or termination of your account.
            </p>
            <div className={css.thirdTitle}>Content Backups</div>
            <p>Although backups of Content are performed in a commercially reasonable manner, the Company does not
                guarantee there will be no loss or corruption of data.
            </p>
            <p>Corrupt or invalid backup points may be caused by, without limitation, Content that is corrupted prior to
                being backed up or that changes during the time a backup is performed.
            </p>
            <p>The Company will provide support and attempt to troubleshoot any known or discovered issues that may
                affect the backups of Content. But You acknowledge that the Company has no liability related to the
                integrity of Content or the failure to successfully restore Content to a usable state.
            </p>
            <p>You agree to maintain a complete and accurate copy of any Content in a location independent of the
                Service.
            </p>
            <div className={css.secondTitle}>Copyright Policy</div>
            <div className={css.thirdTitle}>Intellectual Property Infringement</div>
            <p>We respect the intellectual property rights of others. It is Our policy to respond to any claim that
                Content posted on the Service infringes a copyright or other intellectual property infringement of any
                person or entity.
            </p>
            <p>If You are a copyright owner, or authorized on behalf of one, and You believe that the copyrighted work
                has been copied in a way that constitutes copyright infringement that is taking place through the
                Service, You must submit Your notice in writing to the attention of our copyright agent via email at
                Legal@interpretown.com and include in Your notice a detailed description of the alleged infringement.
            </p>
            <p>You may be held accountable for damages (including costs and attorneys' fees) for misrepresenting that
                any Content is infringing Your copyright.
            </p>
            <div className={css.thirdTitle}>DMCA Notice and DMCA Procedure for Copyright Infringement Claims
            </div>
            <p>You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing Our
                copyright agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):
            </p>
            <p>●An electronic or physical signature of the person authorized to act on behalf of the owner of the
                copyright's interest.
            </p>
            <p>●A description of the copyrighted work that You claim has been infringed, including the URL (i.e., web
                page address) of the location where the copyrighted work exists or a copy of the copyrighted work.
            </p>
            <p>●Identification of the URL or other specific location on the Service where the material that You claim is
                infringing is located.
            </p>
            <p>●Your address, telephone number, and email address.
            </p>
            <p>●A statement by You that You have a good faith belief that the disputed use is not authorized by the
                copyright owner, its agent, or the law.
            </p>
            <p>●A statement by You, made under penalty of perjury, that the above information in Your notice is accurate
                and that You are the copyright owner or authorized to act on the copyright owner's behalf.
            </p>
            <p>You can contact our copyright agent via email at Legal@interpretown.com. Upon receipt of a notification,
                the Company will take whatever action, in its sole discretion, it deems appropriate, including removal
                of the challenged content from the Service.
            </p>

            <div className={css.secondTitle}>Intellectual Property</div>
            <p>The Service and its original content (excluding Content provided by You or other users), features and
                functionality are and will remain the exclusive property of the Company and its licensors.
            </p>
            <p>The Service is protected by copyright, trademark, and other laws of both the Country and foreign
                countries.
            </p>
            <p>Our trademarks and trade dress may not be used in connection with any product or service without the
                prior written consent of the Company.
            </p>

            <div className={css.secondTitle}>Your Feedback to Us</div>
            <p>You assign all rights, title and interest in any Feedback You provide the Company. If for any reason such
                assignment is ineffective, You agree to grant the Company a non-exclusive, perpetual, irrevocable,
                royalty free, worldwide right and license to use, reproduce, disclose, sub-license, distribute, modify
                and exploit such Feedback without restriction.
            </p>

            <div className={css.secondTitle}>Links to Other Websites</div>
            <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by
                the Company.
            </p>
            <p>The Company has no control over, and assumes no responsibility for, the content, privacy policies, or
                practices of any third-party web sites or services. You further acknowledge and agree that the Company
                shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to
                be caused by or in connection with the use of or reliance on any such content, goods or services
                available on or through any such web sites or services.
            </p>
            <p>We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites
                or services that You visit.
            </p>

            <div className={css.secondTitle}>Refund</div>

            <p>All sales are final, and the Company does not offer any money-back guarantees. You recognize and agree
                that you shall not be entitled to a refund for any purchase under any circumstances. All sales and
                transactions are final. Subscription fees, as applicable, are fully earned on payment and there are no
                refunds or credits for partially used periods.But the Company may approve a refund in the form of a
                credit on request if exceptional circumstances exist. The amount and form of a refund and the decision
                to provide it is at the Company's sole discretion. The provision of a refund in one instance does not
                entitle you to a refund in the future for similar instances; nor does it obligate the Company to provide
                refunds in the future, under any circumstance.</p>

            <div className={css.secondTitle}>Termination</div>
            <p>We may terminate or suspend Your Account immediately, without prior notice or liability, for any reason
                whatsoever, including without limitation if You breach these Terms and Conditions.
            </p>
            <p>Upon termination, Your right to use the Service will cease immediately. If You wish to terminate Your
                Account, You may simply discontinue using the Service.
            </p>

            <div className={css.secondTitle}>Limitation of Liability</div>
            <div className={css.thirdTitle}>Limited Liability Policy</div>
            <p>Our Service is not a language Interpreting Service, rather, a means to make a language Interpreting
                Service more accessible to You. You understand, any Interpreting Service listed on Our Website is an
                independent contractor representing themself before you. It is Your duty and obligation to perform
                proper due diligence before engaging in any relationship with an Interpreting Service Provider or
                Third-Party Communication Service Provider pursuant an Interpreting Service. We make no representations
                or warranties as to any Interpreting Services, and any provider thereof or therefor, with respect to
                their accuracy, timeliness, reliability, completeness, security, privacy, or otherwise. </p><p>Although
            We screen Interpreting Service Provider before he/she is listed on Our Website, to verify they are certified
            or qualified Interpreters, You understand and agree that the certification and qualification of various
            Interpreting Service Providers is a complicated and industry wide challenging task, and by using Our
            Service, You acknowledge and consent to this risk, and thereby release the Company, its agent, an assignees
            from any and all liabilities, errors, and/or omissions that result from verifying the Interpreting Service
            Providers eligibility to perform through Our Service.</p><p>While We endeavor to maintain an accurate rating
            system to improve the quality of Your Interpreting Service experience, You understand the ratings are based
            on user participation and input, which may or may not be an accurate reflection of Your Interpreting Service
            experience. Although the Company may use these reviews to suspend or terminate the poorly rated Interpreting
            Service Provider from Our Service, We withhold this right, in our sole discretion, and are not bound by duty
            or obligation to take any action based on user reviews or rating. Understanding this, You waive any and all
            claims in equity or in law, of, or relating to, any symbolically expressed or implied representations by Us
            or other users on our rating system. </p><p>Notwithstanding any damages that You might incur, the entire
            liability of the Company and any of its Affiliates under any provision of these Terms and Your exclusive
            remedy for all of the foregoing shall be limited to the amount actually paid by You to Us and for Us through
            the Service, or 100 USD if You haven't purchased anything through the Service.</p><p>To the maximum extent
            permitted by applicable law, in no event shall the Company or its Affiliates be liable for any special,
            incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss
            of profits, loss of data or other information, for business interruption, for personal injury, loss of
            privacy arising out of or in any way related to the use of or inability to use the Service, third-party
            software and/or third-party hardware used with the Service, or otherwise in connection with any provision of
            this Terms), even if the Company or any Affiliate has been advised of the possibility of such damages and
            even if the remedy fails of its essential purpose.</p><p>Some states do not allow the exclusion of implied
            warranties or limitation of liability for incidental or consequential damages, which means that some of the
            above limitations may not apply. In these states, each party's liability will be limited to the greatest
            extent permitted by law.</p>
            <div className={css.thirdTitle}>"AS IS" and "AS AVAILABLE" Disclaimer</div>
            <p>The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without
                warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own
                behalf and on behalf of its Affiliates and its and their respective licensors and relevant service
                providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with
                respect to the Service, including all implied warranties of merchantability, fitness for a particular
                purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of
                performance, usage or trade practice. Without limitation to the foregoing, the Company provides no
                warranty or undertaking, and makes no representation of any kind that the Service will meet Your
                requirements, achieve any intended results, be compatible or work with any other software, applications,
                systems or services, operate without interruption, meet any performance or reliability standards or be
                error free or that any errors or defects can or will be corrected.</p><p>Without limiting the foregoing,
            neither the Company nor any of the company's agents, assignees, or Affiliates make any representation or
            warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the
            information, content, and materials or products included thereon; (ii) that the Service will be
            uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or
            content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent
            from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or
            other harmful components.</p><p>Some jurisdictions do not allow the exclusion of certain types of warranties
            or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and
            limitations may not apply to You. But in such a case the exclusions and limitations set forth in this
            section shall be applied to the greatest extent enforceable under applicable law.</p>
            <div className={css.thirdTitle}>Interpreting Service Provider Criteria</div>
            <p>Every Interpreting Service Provider listed on the Website is required to be able to provide a BAA
                certificate for his/her personal video meeting account (e.g. Zoom for Healthcare). The Company may help
                to collect the BAA forms from all the Interpreting Service Providers who use the Website and may be made
                available to You upon request.</p><p>Interpreting Service Providers shall provide Interpreting Service
            in a professional manner. You understand and agree that Interpreting Services are inexact disciplines
            inherently and some discrepancies may arise despite Interpreting Service Provider's professional
            certification and/or qualifications of Interpreting Service. User releases Interpreting Service Providers
            from any and all liabilities, except those that cannot be waived by law, for non-negligent errors made by
            Interpreting Service Provider in the provision of Interpreting Service.</p>
            <div className={css.secondTitle}>Disclaimer</div>
            <p>The information contained on the Service is for general information purposes only.</p><p>The Company
            assumes no responsibility for errors or omissions in the contents of the Service.</p><p>In no event shall
            the Company be liable for any special, direct, indirect, consequential, or incidental damages or any damages
            whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with
            the use of the Service or the contents of the Service. The Company reserves the right to make additions,
            deletions, or modifications to the contents on the Service at any time without prior notice.</p><p>The
            Company does not warrant that the Service is free of viruses or other harmful components.</p>
            <div className={css.secondTitle}>External Links Disclaimer</div>
            <p>The Service may contain links to external websites that are not provided or maintained by or in any way
                affiliated with the Company.</p><p>Please note that the Company does not guarantee the accuracy,
            relevance, timeliness, or completeness of any information on these external websites.</p>
            <div className={css.secondTitle}>Advertising Disclaimer</div>
            <p>Third-party advertisements and links to third-party websites may also appear while using the Service. The
                Company does not make any representation as to the accuracy or suitability of any of the information
                contained in those advertisements or websites and does not accept any responsibility or liability for
                the conduct or content of those advertisements and websites and the offerings made by the
                third-parties.</p><p>Third-party advertisements and links to other websites where goods or services are
            advertised are not endorsements or recommendations by the Company of the third-party sites, goods or
            services. The Company takes no responsibility for the content of the ads, promises made, or the
            quality/reliability of the products or services offered in all advertisements.</p>
            <div className={css.secondTitle}>Errors and Omissions Disclaimer</div>
            <p>The information given by the Service is for general guidance on matters of interest only. Even if the
                Company takes every precaution to ensure that the content of the Service is both current and accurate,
                errors can occur. Plus, given the changing nature of laws, rules and regulations, there may be delays,
                omissions or inaccuracies in the information contained on the Service.</p><p>The Company is not
            responsible for any errors or omissions, or for the results obtained from the use of this information.</p>
            <div className={css.secondTitle}>Fair Use Disclaimer</div>
            <p>The Company may use copyrighted material which has not always been specifically authorized by the
                copyright owner. The Company is making such material available for criticism, comment, news reporting,
                teaching, scholarship, or research.</p><p>The Company believes this constitutes a "fair use" of any
            such copyrighted material as provided for in section 107 of the United States Copyright law.</p><p>If You
            wish to use copyrighted material from the Service for your own purposes that go beyond fair use, You must
            obtain permission from the copyright owner.</p>
            <div className={css.secondTitle}>Views Expressed Disclaimer</div>
            <p>The Service may contain views and opinions which are those of the authors and do not necessarily reflect
                the official policy or position of any other author, agency, organization, employer or company,
                including the Company.</p><p>Comments published by users are their sole responsibility and the users
            will take full responsibility, liability and blame for any libel or litigation that results from something
            written in or as a direct result of something written in a comment. The Company is not liable for any
            comment published by users and reserves the right to delete any comment for any reason whatsoever.</p>
            <div className={css.secondTitle}>No Responsibility Disclaimer</div>
            <p>The information on the Service is provided with the understanding that the Company is not herein engaged
                in rendering legal, accounting, tax, or other professional advice and services. As such, it should not
                be used as a substitute for consultation with professional accounting, tax, legal or other competent
                advisers.</p><p>In no event shall the Company or its suppliers be liable for any special, incidental,
            indirect, or consequential damages whatsoever arising out of or in connection with your access or use or
            inability to access or use the Service.</p>
            <div className={css.secondTitle}>Governing Law</div>
            <p>The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of
                the Service. Your use of the Application may also be subject to other local, state, national, or
                international laws.</p>
            <div className={css.secondTitle}>Disputes Resolution</div>
            <p>This section is deemed to be a “written agreement to arbitrate” pursuant to the Federal Arbitration Act.
                You and we agree that we intend that this Section satisfies the “writing” requirement of the Federal
                Arbitration Act</p><p>We believe that arbitration is a faster, more convenient and less expensive way to
            resolve any disputes or disagreements that you may have with us. Therefore, pursuant to these Terms, if You
            have any dispute or disagreement with Us regarding (i) Your use of or interaction with Our Services, (ii)
            any subscriptions or other purchases, transactions or relationships related to Your use of Our Services, or
            (iii) any data or information you may provide to us or that we may gather in connection with such use,
            interaction, subscriptions, purchases, transactions or relationships (collectively, “Transactions or
            Relationships”), you will not have the right to pursue a claim in court, or have a jury decide the claim and
            you will not have the right to bring or participate in any class action or similar proceeding in court or in
            arbitration. By using or interacting with Our Services, or engaging in any other Transactions or
            Relationships with us, you agree to binding arbitration as provided below.</p><p>We will make every
            reasonable effort to informally resolve any complaints, disputes, or disagreements that You may have with
            Us. If those efforts fail, by using Our Services, You agree that any complaint, dispute, or disagreement you
            may have against us, and any claim that We may have against You, arising out of, relating to, or connected
            in any way with these Terms or any Transactions or Relationships shall be resolved exclusively by final,
            confidential and binding arbitration (“Arbitration”) before a single arbitrator administered by JAMS or its
            successor (“JAMS”) and conducted in accordance with the JAMS Streamlined Arbitration Rules And Procedures in
            effect at the time the Arbitration is initiated or, if the amount in controversy exceeds $100,000, in
            accordance with the JAMS Comprehensive Arbitration Rules And Procedures then in effect (respectively, the
            “Applicable Rules”). The Applicable Rules can be found at www.jamsadr.com. If JAMS is no longer in
            existence, the Arbitration shall be administered by the American Arbitration Association or its successor
            (the “AAA”) instead, and conducted in accordance with the AAA Commercial Arbitration Rules in effect at that
            time (which shall be the “Applicable Rules” in such circumstances). If JAMS (or, if applicable, AAA) at the
            time the arbitration is filed has Minimum Standards of Procedural Fairness for Consumer Arbitrations in
            effect that would be applicable to the matter in dispute, we agree to provide the benefit of such Minimum
            Standards to You to the extent they are more favorable than the comparable arbitration provisions set forth
            in this Section, provided, however, that in no event may such Minimum Standards contravene or restrict the
            application of subpart (e) or (i) below. Furthermore, this Section shall not prevent any party from seeking
            provisional remedies (that is, a temporary restraining order or preliminary injunction) from a court of
            appropriate jurisdiction. You further agree that:</p>
            <p>a. Single Arbitrator Presumed</p>
            <p>The Arbitration shall be conducted before a single arbitrator selected in accordance with the Applicable
                Rules or by mutual agreement between you and us (the “Arbitrator”). However, at the request of either
                party, a panel of three arbitrators will conduct the arbitration, with one arbitrator chosen by each of
                the parties and the third appointed by the other two arbitrators.</p>
            <p>b. Arbitrator Will Interpret This Agreement</p><p>The Arbitrator, and not any federal, state or local
            court
            or agency, shall have the exclusive authority to resolve any dispute arising under or relating to the
            validity, interpretation, applicability, enforceability or formation of these Terms and/or these arbitration
            provisions in this Section hereof, including but not limited to any claim that all or any part of these
            Terms is void or voidable.</p>
            <p>c. Location of Arbitration</p>
            <p>The Arbitration shall be held either: (i) at a location within the State of Arizona, determined pursuant
                to the Applicable Rules; or (ii) at such other location as may be mutually agreed upon by You and Us; or
                (iii) at Your election, if the only claims in the arbitration are asserted by you and are for less than
                $10,000 in aggregate, by telephone, virtually, or by written submission.</p>
            <p>d. Governing Law.</p>
            <p>The Arbitrator (i) shall apply internal laws of the State of Arizona consistent with the Federal
                Arbitration Act and applicable statutes of limitations, or, to the extent (if any) that federal law
                prevails, shall apply the law of the U.S., irrespective of any conflict of law principles; (ii) shall
                entertain any motion to dismiss, motion to strike, motion for judgment on the pleadings, motion for
                complete or partial summary judgment, motion for summary adjudication, or any other dispositive motion
                consistent with Delaware or federal rules of procedure, as applicable; (iii) shall honor claims of
                privilege recognized at law; and (iv) shall have authority to award any form of legal or equitable
                relief.</p>
            <p>e. No Class Relief.</p>
            <p>The Arbitration can resolve only Your and/or Our individual claims, and the Arbitrator shall have no
                authority to entertain or arbitrate any claims on a class or representative basis, or to consolidate or
                join the claims of other persons or parties who may be similarly situated.</p><p>THE PARTIES HEREBY
            WAIVE ANY RIGHT TO ASSERT ANY CLAIMS AGAINST THE OTHER PARTY AS A REPRESENTATIVE OR MEMBER IN ANY CLASS OR
            REPRESENTATIVE ACTION, EXCEPT WHERE SUCH WAIVER IS PROHIBITED BY LAW OR DEEMED BY A COURT OF LAW TO BE
            AGAINST PUBLIC POLICY. To the extent either party is permitted by law or court of law to proceed with a
            class or representative action against the other, the parties agree that: (i) the prevailing party shall not
            be entitled to recover attorneys’ fees or costs associated with pursuing the class or representative action
            (notwithstanding any other provision in this agreement); and (ii) the party who initiates or participates as
            a member of the class will not submit a claim or otherwise participate in any recovery secured through the
            class or representative action.</p>
            <p>f. Written Award</p>
            <p>The Arbitrator shall issue a written award supported by a statement of decision setting forth the
                Arbitrator’s complete determination of the dispute and the factual findings and legal conclusions
                relevant to it (an “Award”). Judgment upon the Award may be entered by any court having jurisdiction
                thereof or having jurisdiction over the relevant party or its assets.</p>
            <p>g. Arbitration Costs.</p>
            <p>In any arbitration arising out of or related to this Agreement, the arbitrator shall award to the
                prevailing party, if any, the costs and attorneys’ fees reasonably incurred by the prevailing party in
                connection with the arbitration.</p><p>If the arbitrator determines a party to be the prevailing party
            under circumstances where the prevailing party won on some but not all of the claims and counterclaims, the
            arbitrator may award the prevailing party an appropriate percentage of the costs and attorneys' fees
            reasonably incurred by the prevailing party in connection with the arbitration.</p>
            <p>h. Interpretation and Enforcement of Arbitration Clause</p><p>With the exception of subpart (e) above, if
            any part of this arbitration provision is deemed to be invalid, unenforceable or illegal, or otherwise
            conflicts with the Applicable Rules, then the balance of this arbitration provision shall remain in effect
            and shall be construed in accordance with its terms as if the invalid, unenforceable, illegal or conflicting
            provision were not contained herein. If, however, subpart (e) is found to be invalid, unenforceable or
            illegal, then the entirety of this arbitration provision shall be null and void, and neither you nor we
            shall be entitled to arbitrate any dispute between us and you, and must instead bring any claims subject to
            subsection (k) below.</p>
            <p>i. Modification of Arbitration Clause With Notice</p><p>We may modify these arbitration provisions, but
            such
            modifications shall only become effective thirty (30) days after we have given notice of such modifications
            and only on a prospective basis for claims arising from Transactions or Relationships occurring after the
            effective date of such notification. If any modification pursuant to this subpart (j) is deemed to be
            invalid, unenforceable, or illegal, then the arbitration provisions effective at the time of your agreement
            to these Terms shall govern any dispute or disagreement between You and Us regarding Transactions or
            Relationships.</p>
            <p>j. Small Claims Matters are Excluded. No Class Relief or Joinder of Claims</p><p>Notwithstanding the
            foregoing arbitration provisions, and subject to this Section, at Your option, You may bring any claim for
            damages you have against Us in your local small claims court within the U.S., if Your claim is within such
            court’s jurisdictional limit; provided that such court does not have the authority to entertain any claims
            on a class or representative basis, or to consolidate or join the claims of other persons or parties who may
            be similarly situated in such proceeding.</p>
            <p>k. Confidentiality of Arbitration</p>
            <p>You and We agree to maintain the confidential nature of the Arbitration and shall not disclose the fact
                of the Arbitration, any documents exchanged as part of any mediation, proceedings of the Arbitration,
                the Arbitrator’s decision and the existence or amount of any Award, except as may be necessary to
                prepare for or conduct the Arbitration (in which case anyone becoming privy to such confidential
                information must undertake to preserve its confidentiality), or except as may be necessary in connection
                with a court application for a provisional remedy, a judicial challenge to an Award or its enforcement,
                or unless otherwise required by applicable law or court order.</p>
            <div className={css.secondTitle}>For European Union (EU) Users</div>
            <p>If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the
                country in which you are resident in.</p>
            <div className={css.secondTitle}>United States Legal Compliance</div>
            <p>You represent and warrant that neither You nor, to Your knowledge, any director, officer, agent, employee
                or Affiliate of You is currently subject to any U.S. sanctions administered by the Office of Foreign
                Assets Control of the U.S. Treasury Department (“OFAC”); and the You will not directly or indirectly use
                the Service, to lend, contribute or otherwise make money available to any subsidiary, joint venture
                partner or other person or entity, for the purpose of financing the activities of any person currently
                subject to any U.S. sanctions administered by OFAC.</p>
            <div className={css.secondTitle}>Laws</div>
            <p>Subject to the arbitration provisions above, and other than small claims actions as permitted therein,
                any action or proceeding arising from, relating to or in connection with these Terms will be brought
                exclusively in the federal or state courts located in Maricopa County, Arizona, and You irrevocably
                consent to the personal jurisdiction of such courts and agree that it is a convenient forum and that you
                will not seek to transfer such action or proceeding to any other forum or jurisdiction, under the
                doctrine of forum non conveniens or otherwise. </p><p>User and the Company agree that any cause of
            action arising out of or related to this Service must commence within one (1) year after the cause of action
            arose; otherwise, such cause of action is permanently barred.</p><p>The section titles in this Agreement are
            solely used for the convenience of the parties and have no legal or contractual significance.</p>
            <div className={css.secondTitle}>Severability and Waiver</div>
            <div className={css.thirdTitle}>Severability</div>
            <p>If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed
                and interpreted to accomplish the objectives of such provision to the greatest extent possible under
                applicable law and the remaining provisions will continue in full force and effect.</p>
            <div className={css.thirdTitle}>Waiver</div>
            <p>Except as provided herein, the failure to exercise a right or to require performance of an obligation
                under these Terms shall not effect a party's ability to exercise such right or require such performance
                at any time thereafter nor shall the waiver of a breach constitute a waiver of any subsequent
                breach.</p>
            <div className={css.secondTitle}>Translation Interpretation</div>
            <p>These Terms and Conditions may have been translated if We have made them available to You on our Service.
                You agree that the original English text shall prevail in the case of a dispute.</p>
            <div className={css.secondTitle}>Changes to These Terms and Conditions</div>
            <p>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision
                is material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms
                taking effect. What constitutes a material change will be determined at Our sole discretion.</p><p>By
            continuing to access or use Our Service after those revisions become effective, You agree to be bound by the
            revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and
            the Service.</p>
            <div className={css.secondTitle}>Force Majeure</div>
            <p>Company shall not be deemed to have breached any provision of the Agreement or be liable in any way for
                any delay, failure in performance, loss, damage or interruption of service resulting directly or
                indirectly from acts of God, network failures, acts of civil or military authorities, civil
                disturbances, wars, terrorism, pandemic, energy crises, fires, transportation contingencies,
                interruption or failures of communication systems or Internet equipment or service, other catastrophes,
                equipment failure, acts or omissions of third-party application or service providers or any other
                occurrences which are beyond Our control.</p>
            <div className={css.secondTitle}>Notice for California Users</div>
            <p>Under California Civil Code Section 1789.3, California users of the Our Services are entitled to the
                following specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer
                Services of the California Department of Consumer Affairs may be contacted in writing at 400 R Street,
                Suite 1080, Sacramento, California 95814, or by telephone at (916) 445-1254 or (800) 952-5210.</p>
            <div className={css.secondTitle}>Contact Us</div>
            <p>If you have any questions about these Terms and Conditions, You can contact us:</p>
            <p>By email: Legal@interpretown.com</p>
            <p>By visiting this page on our website: https://www.interpretown.com/contactus</p><p>By mail: 3409 N 7th
            Ave C109, Unit #414. Phoenix, AZ 85013</p>
        </div>
    );
}

function getPrivacyPolicyView() {
    return (
        <div className={css.termsAndConditionsBox}>
            <h1 className={css.title}>Privacy Policy</h1>
            <div className={'bold'}>Privacy Policy</div>
            <div className={'bold'}>Last updated: October 1, 2022</div>
            <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your
                information when You use the Service and tells You about Your privacy rights and how the law protects
                You.</p><p>We use Your Personal data to provide and improve the Service. By using the Service, You agree
            to the collection and use of information in accordance with this Privacy Policy.</p>
            <div className={css.secondTitle}>Interpretation and Definitions</div>
            <div className={css.thirdTitle}>Interpretation</div>
            <p>The words of which the initial letter is capitalized have meanings defined under the following
                conditions. The following definitions shall have the same meaning regardless of whether they appear in
                singular or in plural.</p>
            <div className={css.thirdTitle}>Definitions</div>
            <p>For the purposes of this Privacy Policy:</p><p><span className={'bold'}>Account</span> means a unique
            account created for You to access
            our Service or parts of our Service.</p><p><span className={'bold'}>Business</span>, for the purpose of the
            CCPA (California Consumer
            Privacy Act), refers to the Company as the legal entity that collects Consumers' personal information and
            determines the purposes and means of the processing of Consumers' personal information, or on behalf of
            which such information is collected and that alone, or jointly with others, determines the purposes and
            means of the processing of consumers' personal information, that does business in the State of
            California.</p><p><span className={'bold'}>Company</span> (referred to as either "the Company", "We", "Us"
            or "Our" in this
            Agreement) refers to InterpreTown LLC, 3409 N 7th Ave C109, Unit #414. Phoenix, AZ 85013.</p><p>For the
            purpose of the GDPR, the Company is the Data Controller.</p><p><span className={'bold'}>Consumer</span>, for
            the purpose of the CCPA
            (California Consumer Privacy Act), means a natural person who is a California resident. A resident, as
            defined in the law, includes (1) every individual who is in the USA for other than a temporary or transitory
            purpose, and (2) every individual who is domiciled in the USA who is outside the USA for a temporary or
            transitory purpose.</p><p><span className={'bold'}>Cookies</span> are small files that are placed on Your
            computer, mobile device or any
            other device by a website, containing the details of Your browsing history on that website among its many
            uses.</p><p><span className={'bold'}>Country</span> refers to the United States of America.</p><p><span
            className={'bold'}>Data Controller</span>, for the purposes of the
            GDPR (General Data Protection Regulation), refers to the Company as the legal person which alone or jointly
            with others determines the purposes and means of the processing of Personal Data.</p><p><span
            className={'bold'}>Device</span> means any
            device that can access the Service such as a computer, a cellphone or a digital tablet.</p><p><span
            className={'bold'}>Do Not Track</span>
            (DNT) is a concept that has been promoted by US regulatory authorities, in particular the U.S. Federal Trade
            Commission (FTC), for the Internet industry to develop and implement a mechanism for allowing internet users
            to control the tracking of their online activities across websites.</p><p><span className={'bold'}>Personal Data</span> is
            any information
            that relates to an identified or identifiable individual.</p><p>For the purposes of GDPR, Personal Data
            means any information relating to You such as a name, an identification number, location data, online
            identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic,
            cultural or social identity.</p><p>For the purposes of the CCPA, Personal Data means any information that
            identifies, relates to, describes or is capable of being associated with, or could reasonably be linked,
            directly or indirectly, with You.</p><p><span className={'bold'}>Sale</span>, for the purpose of the CCPA
            (California Consumer Privacy Act),
            means selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise
            communicating orally, in writing, or by electronic or other means, a Consumer's personal information to
            another business or a third party for monetary or other valuable consideration.</p><p><span
            className={'bold'}>Service</span> refers to all
            functions and features available exclusively on Our Website, facilitating an interaction between You and an
            Interpreting Service. Under no circumstance should Our Service be treated or considered an Interpreting
            Service. </p><p><span className={'bold'}>Interpreting Service</span> refers to the third-party translation
            service requested by You,
            facilitated by Our Website. This Interpreting Service encompasses the relationship You have with the
            independent contractor(s) listed on Our Website, and the service You receive through the Third-Party
            Communication Service Provider utilized between You and your chosen contractor.</p><p><span
            className={'bold'}>Interpreting Service Provider</span> refers to the independent contractor(s) who You
            select as your preferred provider of the
            Interpreting Service, as they may appear on Our Website.</p><p><span className={'bold'}>Third-Party Communication Service Provider </span>
            refers to the company You and your Interpreting Service Provider have chosen to facilitate a secure conduit
            for electronic communication. </p><p><span className={'bold'}>Service Provider</span> means any natural or
            legal person who processes the
            data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to
            facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the
            Service or to assist the Company in analyzing how the Service is used. For the purpose of the GDPR, Service
            Providers are considered Data Processors.</p><p><span className={'bold'}>Usage Data</span> refers to data
            collected automatically, either
            generated by the use of the Service or from the Service infrastructure itself (for example, the duration of
            a page visit).</p><p><span className={'bold'}>Website</span> refers to InterpreTown, accessible from
            https://www.interpretown.com</p><p><span className={'bold'}>You </span>
            means any individual, company, or other legal entity on behalf of which such individual is accessing or
            using the Service.</p><p>Under GDPR (General Data Protection Regulation), You can be referred to as the Data
            Subject or as the User as you are the individual using the Service.</p>
            <div className={css.secondTitle}>Collecting and Using Your Personal Data</div>
            <div className={css.thirdTitle}>Types of Data Collected</div>
            <div className={'bold'}>Personal Data</div>
            <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information
                that can be used to contact or identify You. Personally identifiable information may include, but is not
                limited to:</p>
            <div>Email address</div>
            <div>First name and last name</div>
            <div>Phone number</div>
            <div>Address, State, Province, ZIP/Postal code, City</div>
            <p>Usage Data</p>
            <div className={'bold'}>Usage Data</div>
            <p>Usage Data is collected automatically when using the Service.</p><p>Usage Data may include information
            such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages
            of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device
            identifiers and other diagnostic data.</p><p>When You access the Service by or through a mobile device, We
            may collect certain information automatically, including, but not limited to, the type of mobile device You
            use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the
            type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p><p>We may
            also collect information that Your browser sends whenever You visit our Service or when You access the
            Service by or through a mobile device.</p>
            <div className={'bold'}>Tracking Technologies and Cookies</div>
            <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain
                information. Tracking technologies used are beacons, tags, and scripts to collect and track information
                and to improve and analyze Our Service. The technologies We use may include:</p>
            <p><span className={'bold italic'}>Cookies or Browser Cookies.</span> A cookie is a small file placed on
                Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being
                sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service.
                Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use
                Cookies.</p><p><span className={'bold italic'}>Flash Cookies.</span> Certain features of our Service may
            use local stored
            objects (or Flash Cookies) to collect and store information about Your preferences or Your activity on our
            Service. Flash Cookies are not managed by the same browser settings as those used for Browser Cookies. For
            more information on how You can delete Flash Cookies, please read "Where can I change the settings for
            disabling, or deleting local shared objects?" available at
            https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
        </p>
            <p><span className={'bold italic'}>Web Beacons.</span> Certain sections of our Service and our emails may
                contain small electronic files known as
                web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company,
                for example, to count users who have visited those pages or opened an email and for other related
                website statistics (for example, recording the popularity of a certain section and verifying system and
                server integrity).</p><p>Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain
            on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as
            You close Your web browser. You can learn more about cookies on TermsFeed website article.</p><p>We use both
            Session and Persistent Cookies for the purposes set out below:</p>
            <div className={'bold italic'}>Necessary / Essential Cookies</div>
            <div>Type: Session Cookies</div>
            <div>Administered by: Us</div>
            <p>Purpose: These Cookies are essential to provide You with services available through the Website and to
                enable You to use some of its features. They help to authenticate users and prevent fraudulent use of
                user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We
                only use these Cookies to provide You with those services.</p>
            <p className={'bold italic'}>Cookies Policy / Notice Acceptance Cookies</p>
            <div>Type: Persistent Cookies</div>
            <div>Administered by: Us</div>
            <p>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>
            <div className={'bold italic'}>Functionality Cookies</div>
            <div>Type: Persistent Cookies</div>
            <div>Administered by: Us</div>
            <p>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as
                remembering your login details or language preference. The purpose of these Cookies is to provide You
                with a more personal experience and to avoid You having to re-enter your preferences every time You use
                the Website.</p><p>For more information about the cookies we use and your choices regarding cookies,
            please visit our Cookies Policy or the Cookies section of our Privacy Policy.</p>
            <div className={css.thirdTitle}>Use of Your Personal Data</div>
            <p>The Company may use Personal Data for the following purposes:</p><p><span className={'bold'}>To provide and maintain our Service</span>,
            including to monitor the usage of our Service.</p><p><span className={'bold'}>To manage Your Account</span>:
            to manage Your registration as
            a user of the Service. The Personal Data You provide can give You access to different functionalities of the
            Service that are available to You as a registered user.</p><p><span className={'bold'}>For the performance of a contract</span>:
            the
            development, compliance and undertaking of the purchase contract for the products, items or services You
            have purchased or of any other contract with Us through the Service.</p><p><span className={'bold'}>To contact You</span>:
            To contact You by
            email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile
            application's push notifications regarding updates or informative communications related to the
            functionalities, products or contracted services, including the security updates, when necessary or
            reasonable for their implementation.</p><p><span className={'bold'}>To provide You</span> with news, special
            offers and general information
            about other goods, services and events which we offer that are similar to those that you have already
            purchased or enquired about unless You have opted not to receive such information.</p><p><span
            className={'bold'}>To manage Your requests</span>: To attend and manage Your requests to Us.</p><p><span
            className={'bold'}>To deliver targeted advertising to You</span>: We may use
            Your information to develop and display content and advertising (and work with third-party vendors who do
            so) tailored to Your interests and/or location and to measure its effectiveness.</p><p><span
            className={'bold'}>For business transfers</span>: We may use Your information to evaluate or conduct a
            merger, divestiture, restructuring,
            reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going
            concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us
            about our Service users is among the assets transferred.</p><p><span
            className={'bold'}>For other purposes</span>: We may use Your
            information for other purposes, such as data analysis, identifying usage trends, determining the
            effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services,
            marketing and your experience.</p>
            <p>We may share Your personal information in the following situations:</p><p><span className={'bold'}>With Service Providers</span>:
            We may
            share Your personal information with Service Providers to monitor and analyze the use of our Service, to
            advertise on third party websites to You after You visited our Service, for payment processing, to contact
            You.</p><p><span className={'bold'}>For business transfers</span>: We may share or transfer Your personal
            information in connection with, or
            during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of
            Our business to another company.</p><p><span className={'bold'}>With Affiliates</span>: We may share Your
            information with Our affiliates,
            in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent
            company and any other subsidiaries, joint venture partners or other companies that We control or that are
            under common control with Us.</p><p><span className={'bold'}>With business partners</span>: We may share
            Your information with Our business
            partners to offer You certain products, services or promotions.</p><p><span className={'bold'}>With other users</span>:
            when You share
            personal information or otherwise interact in the public areas with other users, such information may be
            viewed by all users and may be publicly distributed outside.</p><p><span className={'bold'}>With Your consent</span>:
            We may disclose Your
            personal information for any other purpose with Your consent.</p>
            <div className={css.thirdTitle}>Retention of Your Personal Data</div>
            <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in
                this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with
                our legal obligations (for example, if we are required to retain your data to comply with applicable
                laws), resolve disputes, and enforce our legal agreements and policies.</p><p>The Company will also
            retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to improve the functionality of Our
            Service, or We are legally obligated to retain this data for longer time periods.</p>
            <div className={css.thirdTitle}>Transfer of Your Personal Data</div>
            <p>Your information, including Personal Data, is processed at the Company's operating offices and in any
                other places where the parties involved in the processing are located. It means that this information
                may be transferred to — and maintained on — computers located outside of Your state, province, country
                or other governmental jurisdiction where the data protection laws may differ than those from Your
                jurisdiction.</p><p>Your consent to this Privacy Policy followed by Your submission of such information
            represents Your agreement to that transfer.</p><p>The Company will take all steps reasonably necessary to
            ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your
            Personal Data will take place to an organization or a country unless there are adequate controls in place
            including the security of Your data and other personal information.</p>
            <div className={css.thirdTitle}>Disclosure of Your Personal Data</div>
            <div className={'bold'}>During an Interpreting Service</div>
            <p>Our Website is not HIPAA compliant and does not need to be. Our Service does not collect, store or
                transfer any protected health information that may arise throughout the language interpreting services
                You obtain through Your independently contracted Interpreting Service Provider. More details are in the
                Limitation of Liability section of the Terms and Conditions of the Website. </p><p>You shall confine any
            and all protected health, proprietary, confidential, or otherwise private personal information, (whether
            through voice, video, picture, text, file, etc.) completely within the Third-Party Communication Service
            Provider’s communication channel (e.g. Zoom for Healthcare, landline, etc.), and should not disclose or
            discuss any of such information within the Website. We exercise commercially reasonable internet security
            measures, but You are responsible for protecting your information with utmost diligence. Any, otherwise
            protected, information You discuss or disclose outside of Your Third-Party Communication Service Provider’s
            secure communication channel will be treated as a voluntary disclosure thereby releasing the Company from
            all liability resulting therefrom. </p>
            <div className={'bold'}>Direct Connection</div>
            <p>Our proprietary technology forms a direct connection, between You and Your chosen Interpreting Service
                Provider, using the secure line of your Third-Party Communication Service Provider. This direct
                connection, allows you to form a secure tunnel between You and Your Interpreting Service Provider, so
                that We and the Internet are removed from the conversation. </p><p>During an OPI (Over Phone
            Interpreting) Service, You and the Interpreting Service Provider may see each other’s phone number, as a
            direct connection is made between the two parties. The direct phone connection makes for easier and safer
            HIPAA compliance. </p><p>During a VRI (Video Remote Interpreting) Service, You may see the Interpreting
            Service Provider’s personal video meeting link with an embedded password due to the direct connection. </p>
            <p>Note: The Terms and Conditions expressly prohibit users from removing, copying, or memorizing another
                user’s phone number or personal meeting link in any format without specific written permission obtained
                through the Website from the other party. All users are specifically prohibited from contacting other
                users for any purpose without specific written permission from those aforementioned. A request for
                written permission may be available on Your account, if granted, such requests carry a revocable
                expiration period of one year. </p><p>You agree to immediately notify Us, and if possible, any affected
            user, in the event of any accidental loss, unauthorized access, use, disclosure or breach of protected,
            proprietary, or personally identifiable information. Interpreting Service Providers shall only disclose
            Confidential Information when legally required, and Interpreting Service Providers shall provide prompt
            notice of such disclosure to any party affected.</p>
            <div className={'bold'}>Business Transactions</div>
            <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred.
                We will provide notice before Your Personal Data is transferred and becomes subject to a different
                Privacy Policy.</p>
            <div className={'bold'}>Law enforcement</div>
            <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do
                so by law or in response to valid requests by public authorities (e.g. a court or a government
                agency).</p>
            <div className={'bold'}>Other legal requirements</div>
            <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary
                to:</p>
            <p>Comply with a legal obligation</p>
            <p>Protect and defend the rights or property of the Company</p><p>Prevent or investigate possible wrongdoing
            in connection with the Service</p><p>Protect the personal safety of Users of the Service or the public</p>
            <p>Protect against legal liability</p>
            <div className={css.thirdTitle}>Security of Your Personal Data</div>
            <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over
                the Internet, or method of electronic storage is 100% secure. While We strive to use commercially
                acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
            <div className={css.secondTitle}>Detailed Information on the Processing of Your Personal Data</div>
            <p>The Service Providers We use may have access to Your Personal Data. These third-party vendors collect,
                store, use, process and transfer information about Your activity on Our Service in accordance with their
                Privacy Policies.</p>
            <div className={css.thirdTitle}>Email Marketing</div>
            <p>We may use Your Personal Data to contact You with newsletters, marketing or promotional materials and
                other information that may be of interest to You. You may opt-out of receiving any, or all, of these
                communications from Us by following the unsubscribe link or instructions provided in any email We send
                or by contacting Us.</p><p>We may use Email Marketing Service Providers to manage and send emails to
            You.</p>
            <div className={'bold'}>Mailchimp</div>
            <p>Mailchimp is an email marketing sending service provided by The Rocket Science Group LLC.</p><p>For more
            information on the privacy practices of Mailchimp, please visit their Privacy policy:
            https://mailchimp.com/legal/privacy/</p>
            <div className={'bold'}>Sendinblue</div>
            <p>Sendinblue is an email marketing sending service provided by Sendinblue company.</p><p>For more
            information on the privacy practices of Sendinblue, please visit their Privacy policy:
            https://www.sendinblue.com/legal/privacypolicy/</p>
            <div className={css.thirdTitle}>Payments</div>
            <p>We may provide paid products and/or services within the Service. In that case, we may use third-party
                services for payment processing (e.g. payment processors).</p><p>We will not store or collect Your
            payment card details. That information is provided directly to Our third-party payment processors whose use
            of Your personal information is governed by their Privacy Policy. These payment processors adhere to the
            standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands
            like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling
            of payment information.</p>
            <div className={'bold'}>Stripe</div>
            <p>Their Privacy Policy can be viewed at https://stripe.com/us/privacy</p>
            <div className={css.thirdTitle}>Behavioral Remarketing</div>
            <p>The Company uses remarketing services to advertise to You after You accessed or visited our Service. We
                and Our third-party vendors use cookies and non-cookie technologies to help Us recognize Your Device and
                understand how You use our Service so that We can improve our Service to reflect Your interests and
                serve You advertisements that are likely to be of more interest to You.</p><p>These third-party vendors
            collect, store, use, process and transfer information about Your activity on Our Service in accordance with
            their Privacy Policies and to enable Us to:</p><p>-- Measure and analyze traffic and browsing activity on
            Our Service</p><p>-- Show advertisements for our products and/or services to You on third-party websites or
            apps</p><p>-- Measure and analyze the performance of Our advertising campaigns</p>
            <p>Some of these third-party vendors may use non-cookie technologies that may not be impacted by browser
                settings that block cookies. Your browser may not permit You to block such technologies. You can use the
                following third-party tools to decline the collection and use of information for the purpose of serving
                You interest-based advertising:</p><p>-- The NAI's opt-out platform:
            http://www.networkadvertising.org/choices/</p><p>-- The EDAA's opt-out platform
            http://www.youronlinechoices.com/</p><p>-- The DAA's opt-out platform:
            http://optout.aboutads.info/?c=2&lang=EN</p><p>You may opt-out of all personalized advertising by enabling
            privacy features on Your mobile device such as Limit Ad Tracking (iOS) and Opt Out of Ads Personalization
            (Android). See Your mobile device Help system for more information.</p><p>We may share information, such as
            hashed email addresses (if available) or other online identifiers collected on Our Service with these
            third-party vendors. This allows Our third-party vendors to recognize and deliver You ads across devices and
            browsers. To read more about the technologies used by these third-party vendors and their cross-device
            capabilities please refer to the Privacy Policy of each vendor listed below.</p>
            <div>The third-party vendors We use are:</div>
            <div className={'bold'}>Google Ads (AdWords)</div>
            <p>Google Ads (AdWords) remarketing service is provided by Google Inc.</p><p>You can opt-out of Google
            Analytics for Display Advertising and customize the Google Display Network ads by visiting the Google Ads
            Settings page: http://www.google.com/settings/ads</p><p>Google also recommends installing the Google
            Analytics Opt-out Browser Add-on - https://tools.google.com/dlpage/gaoptout - for your web browser. Google
            Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being
            collected and used by Google Analytics.</p><p>For more information on the privacy practices of Google,
            please visit the Google Privacy & Terms web page: https://policies.google.com/privacy</p>
            <div className={'bold'}>Facebook</div>
            <p>Facebook remarketing service is provided by Facebook Inc.</p><p>You can learn more about interest-based
            advertising from Facebook by visiting this page: https://www.facebook.com/help/516147308587266</p><p>To
            opt-out from Facebook's interest-based ads, follow these instructions from Facebook:
            https://www.facebook.com/help/568137493302217</p><p>Facebook adheres to the Self-Regulatory Principles for
            Online Behavioral Advertising established by the Digital Advertising Alliance. You can also opt-out from
            Facebook and other participating companies through the Digital Advertising Alliance in the USA
            http://www.aboutads.info/choices/, the Digital Advertising Alliance of Canada in Canada
            http://youradchoices.ca/ or the European Interactive Digital Advertising Alliance in Europe
            http://www.youronlinechoices.eu/, or opt-out using your mobile device settings.</p><p>For more information
            on the privacy practices of Facebook, please visit Facebook's Data Policy:
            https://www.facebook.com/privacy/explanation</p>
            <div className={css.secondTitle}>GDPR Privacy</div>
            <div className={css.thirdTitle}>Legal Basis for Processing Personal Data under GDPR</div>
            <p>We may process Personal Data under the following conditions:</p><p><span
            className={'bold'}>Consent:</span> You have given Your consent
            for processing Personal Data for one or more specific purposes.</p><p><span className={'bold'}>Performance of a contract:</span> Provision
            of Personal Data is necessary for the performance of an agreement with You and/or for any pre-contractual
            obligations thereof.</p><p><span className={'bold'}>Legal obligations:</span> Processing Personal Data is
            necessary for compliance with a legal obligation to which the Company is subject.</p><p><span
            className={'bold'}>Vital interests:</span> Processing Personal Data is necessary in order to protect Your
            vital interests or of another natural person.</p><p><span className={'bold'}>Public interests:</span>
            Processing Personal Data is related to a task that is carried out in the public interest or in the exercise
            of official authority vested in the Company.</p><p><span
            className={'bold'}>Legitimate interests:</span> Processing Personal Data is
            necessary for the purposes of the legitimate interests pursued by the Company.</p><p>In any case, the
            Company will gladly help to clarify the specific legal basis that applies to the processing, and in
            particular whether the provision of Personal Data is a statutory or contractual requirement, or a
            requirement necessary to enter into a contract.</p>
            <div className={css.thirdTitle}>Your Rights under the GDPR</div>
            <p>The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can
                exercise Your rights.</p><p>You have the right under this Privacy Policy, and by law if You are within
            the EU, to:</p><p><span className={'bold'}>Request access to Your Personal Data.</span> The right to access,
            update or delete the
            information We have on You. Whenever made possible, you can access, update or request deletion of Your
            Personal Data directly within Your account settings section. If you are unable to perform these actions
            yourself, please contact Us to assist You. This also enables You to receive a copy of the Personal Data We
            hold about You.</p><p><span className={'bold'}>Request correction of the Personal Data that We hold about You.</span> You
            have the right to have any incomplete or inaccurate information We hold about You corrected.</p><p>
            <span className={'bold'}>Object to processing of Your Personal Data. </span>This
            right exists where We are relying on a legitimate interest as the legal basis for
            Our processing and there is something about Your particular situation, which makes You want to object to our
            processing of Your Personal Data on this ground. You also have the right to object where We are processing
            Your Personal Data for direct marketing purposes.</p><p><span className={'bold'}>Request erasure of Your Personal Data.</span> You
            have the
            right to ask Us to delete or remove Personal Data when there is no good reason for Us to continue processing
            it.</p><p><span className={'bold'}>Request the transfer of Your Personal Data.</span> We will provide to
            You, or to a third-party You have
            chosen, Your Personal Data in a structured, commonly used, machine-readable format. Please note that this
            right only applies to automated information which You initially provided consent for Us to use or where We
            used the information to perform a contract with You.</p><p><span
            className={'bold'}>Withdraw Your consent.</span> You have the right to
            withdraw Your consent on using your Personal Data. If You withdraw Your consent, We may not be able to
            provide You with access to certain specific functionalities of the Service.</p>
            <div className={css.thirdTitle}>Exercising of Your GDPR Data Protection Rights</div>
            <p>You may exercise Your rights of access,
                rectification, cancellation and opposition by contacting Us. Please note that we may ask You to verify
                Your
                identity before responding to such requests. If You make a request, We will try our best to respond to
                You
                as soon as possible.</p><p>You have the right to complain to a Data Protection Authority about Our
            collection and use of Your Personal Data. For more information, if You are in the European Economic Area
            (EEA), please contact Your local data protection authority in the EEA.</p>
            <div className={css.secondTitle}>CCPA Privacy</div>
            <p>This privacy notice section for California residents supplements the information contained in Our Privacy
                Policy and it applies solely to all visitors, users, and others who reside in the State of
                California.</p>
            <div className={css.thirdTitle}>Categories of Personal Information Collected</div>
            <p>We collect information that
                identifies, relates to, describes, references, is capable of being associated with, or could reasonably
                be
                linked, directly or indirectly, with a particular Consumer or Device. The following is a list of
                categories
                of personal information which we may collect or may have been collected from California residents within
                the
                last twelve (12) months.</p><p>Please note that the categories and examples provided in the list below
            are
            those defined in the CCPA. This does not mean that all examples of that category of personal information
            were in fact collected by Us, but reflects our good faith belief to the best of our knowledge that some of
            that information from the applicable category may be and may have been collected. For example, certain
            categories of personal information would only be collected if You provided such personal information
            directly to Us.</p>
            <div className={'bold'}>Category A: Identifiers.</div>
            <p>Examples: A real name, alias, postal address, unique personal identifier, online identifier, Internet
                Protocol address, email address, account name, driver's license number, passport number, or other
                similar identifiers.</p>
            <p>Collected: Yes.</p>
            <div className={'bold'}>Category B: Personal information categories listed in the California
                Customer Records statute (Cal. Civ.
                Code § 1798.80(e)).
            </div>
            <p>Examples: A name, signature, Social Security number, physical characteristics
                or description, address, telephone number, passport number, driver's license or state identification
                card
                number, insurance policy number, education, employment, employment history, bank account number, credit
                card
                number, debit card number, or any other financial information, medical information, or health insurance
                information. Some personal information included in this category may overlap with other categories.</p>
            <p>Collected: Yes.</p>
            <div className={'bold'}>Category C: Protected classification characteristics under California or
                federal law.
            </div>
            <p>Examples: Age
                (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital
                status,
                medical condition, physical or mental disability, sex (including gender, gender identity, gender
                expression,
                pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status,
                genetic information (including familial genetic information).</p>
            <p>Collected: No.</p>
            <div className={'bold'}>Category D: Commercial information.</div>
            <p>Examples: Records and history of products or services purchased or considered.</p>
            <p>Collected: Yes.</p>
            <div className={'bold'}>Category E: Biometric information.</div>
            <p>Examples: Genetic, physiological, behavioral, and biological characteristics, or activity patterns used
                to extract a template or other identifier or identifying information, such as, fingerprints, faceprints,
                and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health,
                or exercise data.</p>
            <p>Collected: No.</p>
            <div className={'bold'}>Category F: Internet or other similar network activity.</div>
            <p>Examples: Interaction with our Service or
                advertisement.</p>
            <p>Collected: Yes.</p>
            <div className={'bold'}>Category G: Geolocation data.</div>
            <p>Examples: Approximate physical location.</p>
            <p>Collected: No.</p>
            <div className={'bold'}>Category H: Sensory data.</div>
            <p>Examples: Audio, electronic, visual, thermal, olfactory, or similar information.</p>
            <p>Collected: No.</p>
            <div className={'bold'}>Category I: Professional or employment-related information.</div>
            <p>Examples: Current or past job history
                or performance evaluations.</p>
            <p>Collected: No.</p>
            <div className={'bold'}>Category J: Non-public education information (per the Family Educational
                Rights and Privacy Act (20
                U.S.C. Section 1232g, 34 C.F.R. Part 99)).
            </div>
            <p>Examples: Education records directly related to a
                student maintained by an educational institution or party acting on its behalf, such as grades,
                transcripts,
                class lists, student schedules, student identification codes, student financial information, or student
                disciplinary records.</p>
            <p>Collected: No.</p>
            <div className={'bold'}>Category K: Inferences drawn from other personal information.</div>
            <p>Examples: Profile reflecting a
                person's preferences, characteristics, psychological trends, predispositions, behavior, attitudes,
                intelligence, abilities, and aptitudes.</p>
            <p>Collected: No.</p>
            <p>Under CCPA, personal information does not include:</p><p>-- Publicly available information from
            government records</p><p>-- Deidentified or aggregated consumer information</p><p>-- Information excluded
            from the CCPA's scope, such as: </p><p> * Health or medical information covered by the Health Insurance
            Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information
            Act (CMIA) or clinical trial data</p><p> * Personal Information covered by certain sector-specific privacy
            laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California
            Financial Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994</p>
            <div className={css.thirdTitle}>Sources of Personal Information</div>
            <p>We obtain the categories of personal information listed above from the following categories of
                sources:</p><p><span className={'bold'}>Directly from You.</span> For example, from the forms You
            complete on our Service, preferences
            You express or provide through our Service, or from Your purchases on our Service.</p><p><span
            className={'bold'}>Indirectly fromYou. </span>For example, from observing Your activity on our Service.</p>
            <p><span className={'bold'}>Automatically from You.</span> For example,
                through cookies We or our Service Providers set on Your Device as You navigate through our Service.</p>
            <p><span className={'bold'}>From Service Providers.</span> For example, third-party vendors to deliver
                targeted advertising to You,
                third-party vendors for payment processing, or other third-party vendors that We use to provide the
                Service to You.</p>
            <div className={css.thirdTitle}>Use of Personal Information for Business Purposes or Commercial Purposes
            </div>
            <p>We may use or disclose
                personal information We collect for "business purposes" or "commercial purposes" (as defined under the
                CCPA), which may include the following examples:</p><p>-- To operate our Service and provide You with
            our
            Service.</p><p>-- To provide You with support and to respond to Your inquiries, including to investigate and
            address Your concerns and monitor and improve our Service.</p><p>-- To fulfill or meet the reason You
            provided the information. For example, if You share Your contact information to ask a question about our
            Service, We will use that personal information to respond to Your inquiry. If You provide Your personal
            information to purchase a product or service, We will use that information to process Your payment and
            facilitate delivery.</p><p>-- To respond to law enforcement requests and as required by applicable law,
            court order, or governmental regulations.</p><p>-- As described to You when collecting Your personal
            information or as otherwise set forth in the CCPA.</p><p>-- For internal administrative and auditing
            purposes.</p><p>-- To detect security incidents and protect against malicious, deceptive, fraudulent or
            illegal activity, including, when necessary, to prosecute those responsible for such activities.</p>
            <p>Please note that the examples provided above are illustrative and not intended to be exhaustive. For more
                details on how we use this information, please refer to the "Use of Your Personal Data" section.</p>
            <p>If We decide to collect additional categories of personal information or use the personal information We
                collected for materially different, unrelated, or incompatible purposes We will update this Privacy
                Policy.</p>
            <div className={css.thirdTitle}>Disclosure of Personal Information for Business Purposes or Commercial
                Purposes
            </div>
            <p>We may use or
                disclose and may have used or disclosed in the last twelve (12) months the following categories of
                personal
                information for business or commercial purposes:</p>
            <p>Category A: Identifiers</p>
            <p>Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ.
                Code § 1798.80(e))</p>
            <p>Category D: Commercial information</p>
            <p>Category F: Internet or other similar network activity</p><p>Please note that the categories listed above
            are those defined in the CCPA. This does not mean that all examples of that category of personal information
            were in fact disclosed, but reflects our good faith belief to the best of our knowledge that some of that
            information from the applicable category may be and may have been disclosed.</p><p>When We disclose personal
            information for a business purpose or a commercial purpose, We enter a contract that describes the purpose
            and requires the recipient to both keep that personal information confidential and not use it for any
            purpose except performing the contract.</p>
            <div className={css.thirdTitle}>Sale of Personal Information</div>
            <p>As defined in the CCPA, "sell" and "sale" mean selling, renting, releasing, disclosing,
                disseminating, making available, transferring, or otherwise communicating orally, in writing, or by
                electronic or other means, a consumer's personal information by the business to a third party for
                valuable consideration. This means that We may have received some kind of benefit in return for sharing
                personal information, but not necessarily a monetary benefit.</p><p>Please note that the categories
            listed below are those defined in the CCPA. This does not mean that all examples of that category of
            personal information were in fact sold, but reflects our good faith belief to the best of our knowledge that
            some of that information from the applicable category may be and may have been shared for value in
            return.</p><p>We may sell and may have sold in the last twelve (12) months the following categories of
            personal information:</p>
            <p>Category A: Identifiers</p>
            <p>Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ.
                Code § 1798.80(e))</p>
            <p>Category D: Commercial information</p>
            <p>Category F: Internet or other similar network activity</p>
            <div className={css.thirdTitle}>Share of Personal Information</div>
            <p>We may share Your personal information identified in the above categories with the following categories
                of third parties:</p>
            <p>-- Service Providers</p>
            <p>-- Payment processors</p>
            <p>-- Our affiliates</p>
            <p>-- Our business partners</p>
            <p>-- Third party vendors to whom You or Your agents authorize Us to disclose Your personal information in
                connection with products or services We provide to You</p>
            <div className={css.thirdTitle}>Sale of Personal Information of Minors Under 16 Years of Age</div>
            <p>We do not knowingly collect personal
                information from minors under the age of 16 through our Service, although certain third party websites
                that
                we link to may do so. These third-party websites have their own terms of use and privacy policies and we
                encourage parents and legal guardians to monitor their children's Internet usage and instruct their
                children
                to never provide information on other websites without their permission.</p><p>We do not sell the
            personal
            information of Consumers We actually know are less than 16 years of age, unless We receive affirmative
            authorization (the "right to opt-in") from either the Consumer who is between 13 and 16 years of age, or
            the parent or guardian of a Consumer less than 13 years of age. Consumers who opt-in to the sale of personal
            information may opt-out of future sales at any time. To exercise the right to opt-out, You (or Your
            authorized representative) may submit a request to Us by contacting Us.</p><p>If You have reason to believe
            that a child under the age of 13 (or 16) has provided Us with personal information, please contact Us with
            sufficient detail to enable Us to delete that information.</p>
            <div className={css.thirdTitle}>Your Rights under the CCPA</div>
            <p>The CCPA provides California residents with specific rights regarding their personal information. If You
                are a resident of California, You have the following rights:</p><p><span className={'bold'}>The right to notice.</span> You
            have the
            right to be notified which categories of Personal Data are being collected and the purposes for which the
            Personal Data is being used.</p><p><span className={'bold'}>The right to request.</span> Under CCPA, You
            have the right to request that We
            disclose information to You about Our collection, use, sale, disclosure for business purposes and share of
            personal information. Once We receive and confirm Your request, We will disclose to You: </p><p>-- The
            categories of personal information We collected about You</p><p>-- The categories of sources for the
            personal information We collected about You</p><p>-- Our business or commercial purpose for collecting or
            selling that personal information</p><p>-- The categories of third parties with whom We share that personal
            information</p><p>-- The specific pieces of personal information We collected about You</p><p>-- If we sold
            Your personal information or disclosed Your personal information for a business purpose, We will disclose to
            You: </p><p> * The categories of personal information categories sold</p><p> * The categories of personal
            information categories disclosed</p><p><span className={'bold'}>The right to say no to the sale of Personal Data (opt-out).</span> You
            have
            the right to direct Us to not sell Your personal information. To submit an opt-out request please contact
            Us.</p><p><span className={'bold'}>The right to delete Personal Data.</span> You have the right to request
            the deletion of Your Personal
            Data, subject to certain exceptions. Once We receive and confirm Your request, We will delete (and direct
            Our Service Providers to delete) Your personal information from our records, unless an exception applies. We
            may deny Your deletion request if retaining the information is necessary for Us or Our Service Providers
            to: </p><p>-- Complete the transaction for which We collected the personal information, provide a good or
            service that You requested, take actions reasonably anticipated within the context of our ongoing business
            relationship with You, or otherwise perform our contract with You.</p><p>-- Detect security incidents,
            protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for
            such activities.</p><p>-- Debug products to identify and repair errors that impair existing intended
            functionality.</p><p>-- Exercise free speech, ensure the right of another consumer to exercise their free
            speech rights, or exercise another right provided for by law.</p><p>-- Comply with the California Electronic
            Communications Privacy Act (Cal. Penal Code § 1546 et. seq.).</p><p>-- Engage in public or peer-reviewed
            scientific, historical, or statistical research in the public interest that adheres to all other applicable
            ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair
            the research's achievement, if You previously provided informed consent.</p><p>-- Enable solely internal
            uses that are reasonably aligned with consumer expectations based on Your relationship with Us.</p>
            <p>-- Comply with a legal obligation.</p>
            <p>-- Make other internal and lawful uses of that information that are compatible with the context in which
                You provided it.</p>
            <p><span className={'bold'}>The right not to be discriminated against.</span> You have the right not to be
                discriminated against for
                exercising any of Your consumer's rights, including by: </p>
            <p>-- Denying goods or services to You</p>
            <p>-- Charging different prices or rates for goods or services, including the use of discounts or other
                benefits or imposing penalties</p><p>-- Providing a different level or quality of goods or services to
            You</p><p>-- Suggesting that You will receive a different price or rate for goods or services or a different
            level or quality of goods or services</p>
            <div className={css.thirdTitle}>Exercising Your CCPA Data Protection Rights</div>
            <p>In order to exercise any of Your rights under the CCPA,
                and if You are a California resident, You can contact Us:</p>
            <p> By email: Legal@interpretown.com</p>
            <p> By visiting this page on our website: https://www.interpretown.com/contactus</p><p> By mail: 3409 N 7th
            Ave C109, Unit #414. Phoenix, AZ 85013</p><p>Only You, or a person registered with the California Secretary
            of State that You authorize to act on Your behalf, may make a verifiable request related to Your personal
            information.</p>
            <p>Your request to Us must:</p>
            <p>-- Provide sufficient information that allows Us to reasonably verify You are the person about whom We
                collected personal information or an authorized representative</p><p>-- Describe Your request with
            sufficient detail that allows Us to properly understand, evaluate, and respond to it</p><p>We cannot respond
            to Your request or provide You with the required information if We cannot:</p><p>-- Verify Your identity or
            authority to make the request</p><p>-- And confirm that the personal information relates to You</p><p>We
            will disclose and deliver the required information free of charge within 45 days of receiving Your
            verifiable request. The time period to provide the required information may be extended once by an
            additional 45 days when reasonably necessary and with prior notice.</p><p>Any disclosures We provide will
            only cover the 12-month period preceding the verifiable request's receipt.</p><p>For data portability
            requests, We will select a format to provide Your personal information that is readily usable and should
            allow You to transmit the information from one entity to another entity without hindrance.</p>
            <div className={css.thirdTitle}>Do Not Sell My Personal Information</div>
            <p>You have the right to opt-out of the sale of Your personal information. Once We receive and confirm a
                verifiable consumer request from You, we will stop selling Your personal information. To exercise Your
                right to opt-out, please contact Us.</p><p>The Service Providers we partner with (for example, our
            analytics or advertising partners) may use technology on the Service that sells personal information as
            defined by the CCPA law. If you wish to opt out of the use of Your personal information for interest-based
            advertising purposes and these potential sales as defined under CCPA law, you may do so by following the
            instructions below.</p><p>Please note that any opt out is specific to the browser You use. You may need to
            opt out on every browser that You use.</p>
            <div className={'bold'}>Website</div>
            <p>You can opt out of receiving ads that are personalized as served by our Service Providers by following
                our instructions presented on the Service:</p><p>-- The NAI's opt-out platform:
            http://www.networkadvertising.org/choices/</p><p>-- The EDAA's opt-out platform
            http://www.youronlinechoices.com/</p><p>-- The DAA's opt-out platform:
            http://optout.aboutads.info/?c=2&lang=EN</p><p>The opt out will place a cookie on Your computer that is
            unique to the browser You use to opt out. If you change browsers or delete the cookies saved by your
            browser, You will need to opt out again.</p>
            <div className={'bold'}>Mobile Devices</div>
            <p>Your mobile device may give You the ability to opt out of the use of information about the apps You use
                in order to serve You ads that are targeted to Your interests:</p><p>-- "Opt out of Interest-Based
            Ads" or "Opt out of Ads Personalization" on Android devices</p>
            <p>-- "Limit Ad Tracking" on iOS devices</p>
            <p>You can also stop the collection of location information from Your mobile device by changing the
                preferences on Your mobile device.</p>
            <div className={css.secondTitle}>"Do Not Track" Policy as Required by California Online Privacy Protection
                Act (CalOPPA)
            </div>
            <p>Our Service does not respond to Do Not Track signals.</p><p>However, some third party websites do keep
            track of Your browsing activities. If You are visiting such websites, You can set Your preferences in Your
            web browser to inform websites that You do not want to be tracked. You can enable or disable DNT by visiting
            the preferences or settings page of Your web browser.</p>
            <div className={css.secondTitle}>Children's Privacy</div>
            <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally
                identifiable information from anyone under the age of 13. If You are a parent or guardian and You are
                aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We
                have collected Personal Data from anyone under the age of 13 without verification of parental consent,
                We take steps to remove that information from Our servers.</p><p>If We need to rely on consent as a
            legal basis for processing Your information and Your country requires consent from a parent, We may require
            Your parent's consent before We collect and use that information.</p>
            <div className={css.secondTitle}>Your California Privacy Rights (California's Shine the Light law)</div>
            <p>Under California Civil Code
                Section 1798 (California's Shine the Light law), California residents with an established business
                relationship with us can request information once a year about sharing their Personal Data with third
                parties for the third parties' direct marketing purposes.</p><p>If you'd like to request more
            information
            under the California Shine the Light law, and if You are a California resident, You can contact Us using the
            contact information provided below.</p>
            <div className={css.secondTitle}>California Privacy Rights for Minor Users (California Business and
                Professions Code Section 22581)
            </div>
            <p>California Business and Professions Code Section 22581 allows California residents under the age of 18
                who are registered users of online sites, services or applications to request and obtain removal of
                content or information they have publicly posted.</p><p>To request removal of such data, and if You are
            a California resident, You can contact Us using the contact information provided below, and include the
            email address associated with Your account.</p><p>Be aware that Your request does not guarantee complete or
            comprehensive removal of content or information posted online and that the law may not permit or require
            removal in certain circumstances.</p>
            <div className={css.secondTitle}>Links to Other Websites</div>
            <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third
                party link, You will be directed to that third party's site. We strongly advise You to review the
                Privacy Policy of every site You visit.</p><p>We have no control over and assume no responsibility for
            the content, privacy policies or practices of any third party sites or services.</p>
            <div className={css.secondTitle}>Changes to this Privacy Policy</div>
            <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new
                Privacy Policy on this page.</p><p>We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the "Last updated" date at the top of this
            Privacy Policy.</p><p>You are advised to review this Privacy Policy periodically for any changes. Changes to
            this Privacy Policy are effective when they are posted on this page.</p>
            <div className={css.secondTitle}>Contact Us</div>
            <p>If you have any questions about this Privacy Policy, You can contact us:</p><p> By email:
            ContactUs@interpretown.com</p><p> By visiting this page on our website:
            https://www.interpretown.com/contactus</p><p> By mail: 3409 N 7th Ave C109, Unit #414. Phoenix, AZ 85013</p>
        </div>
    );
}

function getHeader() {
    return (
        <div className={css.headerBox}>
            <div className={css.logoBox}>
                <Image className={css.logoImg}
                       src={logo} width={200}
                       preview={false}/>
                <span className={css.desc}>
                    building mutual trust
                </span>
            </div>
            <Menu
                class={css.menuBox}
                onClick={() => {
                }} selectedKeys={[data.selectIndex]} mode="horizontal">
                <Menu.Item key="Home" onClick={() => {
                    data.selectIndex = 'Home';
                    data.isLoadByClickHeaderMenu = true;
                }}>
                    Home
                </Menu.Item>
                <Menu.Item key="Price" onClick={() => {
                    data.selectIndex = 'Price';
                    data.isLoadByClickHeaderMenu = true;
                }}>
                    Pricing
                </Menu.Item>
                <Menu.Item key="FAQ" onClick={() => {
                    data.selectIndex = 'FAQ';
                    data.isLoadByClickHeaderMenu = true;
                }}>
                    FAQ's
                </Menu.Item>
                <Menu.Item key="Contact" onClick={() => {
                    data.selectIndex = 'Contact';
                    data.isLoadByClickHeaderMenu = true;
                }}>
                    Contact Us
                </Menu.Item>
                <Menu.Item key="Login" onClick={() => {
                    data.isLoadByClickHeaderMenu = true;
                    commonUtils.historyPushRefresh(history, '/');
                }}>
                    Login/Sign up
                </Menu.Item>
            </Menu>
        </div>
    );
}

function getBottom() {
    return (
        <div className={css.bottomBox}>
            <div className={css.bottomContent}>
                <a href="/company_home?type=services" className={css.desc}>Services</a>
                <a href="/company_home?type=userTutorials" className={css.desc}>User Tutorials</a>
                <a href="/company_home?type=userResources" className={css.desc}>User Resources</a>
                <a href="/company_home?type=workWithUs" className={css.desc}>Work with Us</a>
                <a href="/company_home?type=aboutUs" className={css.desc}>About Us</a>
            </div>
            <div className={css.bottomContent}>
                <a href="/company_home?type=becomingAnInterpreter" className={css.desc}>Becoming an Interpreter</a>
                <a href="/company_home?type=termsAndConditions" className={css.desc}>Terms and Conditions</a>
                <a href="/company_home?type=privacyPolicy" className={css.desc}>Privacy Policy</a>
            </div>
            <div className={css.allRightsReserved}>© 2022 InterpreTown. All rights reserved.</div>
        </div>
    )
}

// create observer
let CompanyHome = observer(function () {

    history = useHistory();
    data.query = commonUtils.query2ObjParams(useLocation().search);
    useEffect(() => {
    }, []);

    return (
        <Col align={'middle'}>
            <div className={css.containBox}>
                {getHeader()}
                {getTypeView()}
                {getBottom()}
            </div>
        </Col>
    );
})
export default CompanyHome;
