// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".containBox_37IzGK .listItem_43R4VI {\n  display: flex;\n  flex-wrap: wrap;\n  color: red;\n}\n.containBox_37IzGK .bottomBtnBox_2Xl7eM {\n  justify-content: space-between;\n}\n", "",{"version":3,"sources":["webpack://src/pages/person/add_pay_subscribe/add_pay_subscribe.module.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,eAAA;EACA,UAAA;AAAJ;AAJA;EAQI,8BAAA;AADJ","sourcesContent":[".containBox {\n  .listItem {\n    display: flex;\n    flex-wrap: wrap;\n    color: red;\n  }\n\n  .bottomBtnBox {\n    justify-content: space-between;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containBox": "containBox_37IzGK",
	"listItem": "listItem_43R4VI",
	"bottomBtnBox": "bottomBtnBox_2Xl7eM"
};
export default ___CSS_LOADER_EXPORT___;
