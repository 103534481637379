import React, {useEffect} from 'react';
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Button, Col, Input, message, Modal, Radio, Row} from "antd";
import css from './person.module.less';
import {useHistory} from "react-router-dom";
import {useParams} from "react-router";
import {loadStripe} from '@stripe/stripe-js';

let store = require('../../store');
let data = store.order_list;
let api = require('src/api');
let moment = require('moment');
let history;
let orderHelper = require('commonProject/helper/order_helper');
let config = require('commonProject/config/config')
const stripePromise = loadStripe(config.stripe.publicKey);


//个人页面


function getOrders() {
    api.order.get_order_list().then(res => {
        data.orders = res.data;
        console.log(data.orders);
    });
}

async function checkoutPay() {
    // Get Stripe.js instance
    let stripe = await stripePromise;

    // Call your backend to create the Checkout Session
    let result = await api.user.recharge();
    if (result.code != 0) return;

    let pay_order = result.data;

    // When the customer clicks on the button, redirect them to Checkout.
    let payResult = await stripe.redirectToCheckout({
        sessionId: pay_order.order_id,
    });
}


//获取子用户设置的布局
function getSubUserSettingDialog() {
    return (
        <Modal title="Basic Modal" visible={data.is_show_sub_user_setting_dialog} onCancel={() => {
            data.is_show_sub_user_setting_dialog = false;
        }} footer={null} className={css.subUserDialog}>
            <div className={css.subUserDialog2}>
                <div className={css.addBox}>
                    <Input placeholder='phone or email' onChange={e => {
                        data.account = e.target.value
                    }}/>
                    <Button onClick={addNewSubUser}>add new sub user</Button>
                </div>
                {
                    data.subUsers.map(sub_user => {
                        return (
                            <div className={css.itemUser}>
                                <span>{sub_user.email} - {sub_user.phone_num}</span>
                                <Button onClick={() => {
                                    delSubUser(sub_user)
                                }}>del sub user</Button>
                            </div>
                        )
                    })
                }
            </div>
        </Modal>
    )
}

async function getSubUsers() {
    if (!store.persist.userInfo.is_admin) return;
    let res = await api.user.get_sub_users();
    if (res.code != 0) return;
    data.subUsers = res.data;
}


async function addNewSubUser() {
    let res = await api.user.admin_add_sub_user({account: data.account});
    if (res.code != 0) return;
    message.success(res.msg);
    getSubUsers()
}

async function delSubUser(subUser) {
    let res = await api.user.admin_del_sub_user({account: subUser.email});
    if (res.code != 0) return;
    message.success(res.msg);
    getSubUsers()
}

function init() {
    getSubUsers();
}

// create observer
let Person = observer(function () {
    history = useHistory();
    useEffect(() => {
        // getOrders();
        init();
    }, []);


    return (
        <Col className={css.containBox}>
            <p className={css.headTitle}>My Page</p>
            <p/>
            {store.persist.userInfo.user_role_type == 'owner' ? (
                <div>
                    <Button className={css.headTitle1} onClick={() => {
                        history.push('/add_pay_subscribe');
                    }} type='primary'>add pay subscribe</Button>
                    <p/>
                    <Button className={css.headTitle1} role="link" type='primary' onClick={checkoutPay}>
                        Checkout Pay
                    </Button>
                    <p/>
                    {/*<Button className={css.headTitle1} type='primary' onClick={() => {*/}
                    {/*    data.is_show_sub_user_setting_dialog = true;*/}
                    {/*}}>sub user setting</Button>*/}
                </div>
            ) : null}

            {/*获取子用户对话框*/}
            {getSubUserSettingDialog()}
        </Col>
    );
});

export default Person;
