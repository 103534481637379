const crypto = require('crypto');

const AES_CONFIG = {
    algorithm: 'aes-128-ecb',
    key: '5961748251461861',
    iv: Buffer.alloc(0),
    clearEncoding: 'utf8',
    cipherEncoding: 'base64',
    my_key: '5961748251412361'
};

class Decrypt {
    static md5Encrypt(str) {
        const md5 = crypto.createHash('md5');
        md5.update(str);
        return md5.digest('hex');
    }

    /**
     * aes加密字符串
     * @param str
     * @param key
     * @returns {string}
     */
    static aesEncrypt(str, key = AES_CONFIG.key) {
        //If the next line is uncommented, the final cleartext is wrong.
        var cipher = crypto.createCipheriv(AES_CONFIG.algorithm, key, AES_CONFIG.iv);
        var cipherChunks = [];
        cipherChunks.push(cipher.update(str, AES_CONFIG.clearEncoding, AES_CONFIG.cipherEncoding));
        cipherChunks.push(cipher.final(AES_CONFIG.cipherEncoding));
        let rawData = cipherChunks.join('');
        //全部替换\u0000为空格符，否则无法json正确解析
        rawData = rawData.replace(new RegExp("\u0000", "gm"), "");
        return rawData;
    }

    /**
     * aes解密字符串
     * @param data 上面aes加密后返回的字符串
     * @param key
     * @returns {string} 解密出的明文
     */
    static aesDecrypt(data, key = AES_CONFIG.key) {
        var decipher = crypto.createDecipheriv(AES_CONFIG.algorithm, key, AES_CONFIG.iv);
        let cipherChunks = [];
        cipherChunks.push(decipher.update(data, AES_CONFIG.cipherEncoding, AES_CONFIG.clearEncoding));
        cipherChunks.push(decipher.final(AES_CONFIG.clearEncoding));
        let rawData = cipherChunks.join('');
        //全部替换\u0000为空格符，否则无法json正确解析
        rawData = rawData.replace(new RegExp("\u0000", "gm"), "");
        return rawData;
    }

    static async signBySHA256(str, privateKeys) {
        const sign = crypto.createSign('RSA-SHA256');
        sign.update(str, 'utf-8');
        return await sign.sign(privateKeys, 'base64');
    }

    static async verifysiGNSHA256(str, retuen_sign, publicKey) {
        const verify = crypto.createVerify('RSA-SHA256');
        verify.update(str, 'utf-8');
        return await verify.verify(publicKey, retuen_sign, 'base64');
    }

}

module.exports = Decrypt;



