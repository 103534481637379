import {message} from "antd";

let commonUtils = require('../../../../common/utils/Common');
let api = require('src/api');

class LoginHelper {
    static async login(params, history) {
        let res = await api.user.login(params);
        if (res.code == 0) {
            this.loginAfter(res, history);
        } else message.error(res.msg);
    }

    static async loginAfter(res, history) {
        let store = require('src/store');
        store.persist.userInfo = res.data.user;
        store.persist.token = res.data.token;
        store.is_verify_psw = false;
        message.success('login success');
        if (!store.persist.userInfo.user_role_type) {
            return history.push('/register_is_admin');
        } else if (store.persist.userInfo.status == 2) {//管理员未授权
            return history.push('/register_result?type=success');
        }
        history.push('/user_func_menu');
    }


    static async logout(history) {
        let store = require('src/store');
        store.persist.token = '';
        store.persist.is_verify_psw = false;
        store.persist.register_add_info_owner={};
        store.persist.register_add_info_team_admin={};
        await commonUtils.timeSleep(500);
        commonUtils.historyPushRefresh(history, '/');
    }
}

module.exports = LoginHelper;
