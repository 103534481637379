import React, {useState, useEffect} from 'react';
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Button, Card, Checkbox, Col, Divider, Image, List, message, Radio, Row} from 'antd';
import {Link, useHistory} from "react-router-dom";
import OPISvg from 'src/assets/img/add_order/OPI_logo.svg';
import VRISvg from 'src/assets/img/add_order/VRI_logo.svg';
import OSISvg from 'src/assets/img/add_order/OSI_logo.svg';
import css from './choose_support_service.module.less';

let store = require('src/store');
let config = require('commonProject/config/config');
let api = require('src/api');

function getSupportService() {
    api.order.get_support_service_type_list().then(res => {
        store.add_order.chooseSupportService = res.data;
    })
}

function getSubDesc(support_service_type) {
    let typeName = support_service_type.type_name;
    if (typeName == 'OPI') return 'need only a phone number';
    if (typeName == 'VRI') return 'need devices and stable highspeed internet';
    if (typeName == 'OSI') return 'highest price, and no dispute service';
    return '';
}

function getLogo(support_service_type) {
    let typeName = support_service_type.type_name;
    if (typeName == 'OPI') return OPISvg;
    if (typeName == 'VRI') return VRISvg;
    if (typeName == 'OSI') return OSISvg;
    return '';
}

// create observer
let ChooseSupportService = observer(function () {
    let history = useHistory();
    let formData = store.persist.add_order.formData;

    useEffect(() => {
        getSupportService();
    }, [])

    return (
        <Col className={css.containBox}>
            <p className={css.headTitle}>step 3 of 4</p>
            <span className={css.headTitle1}>Choose format</span>
            <Divider className={`gray ${css.divider}`}/>
            <span>You chose: <span className={css.headTitle2}>{`${formData.chooseLang.language_name}`}
                {formData.chooseCerts.length > 0 ? `-${formData.chooseCerts[0].cert_desc.split(' ')[0]}` : ``}</span>
            </span>
            <Divider/>
            <List dataSource={store.add_order.chooseSupportService}
                  renderItem={item => (
                      <List.Item>
                          <Card style={{width: '100%'}} onClick={e => {
                              formData.support_service_type = item;
                              message.success('choosed ' + item.type_name);
                              history.push('/add_order/choose_prebooking');
                          }} className={css.headTitle3}>
                              <div>
                                  <Row align='middle' className={css.itemBox}>
                                      <Image src={getLogo(item)}/>
                                      <div style={{'margin-left': '10px'}} className={css.desc}>
                                          <span className={css.headTitle4}>{item.type_name}</span>
                                          <p className={css.headTitle5}>{item.type_desc}</p>
                                          <p className={css.headTitle6}>{getSubDesc(item)}</p>
                                      </div>
                                  </Row>
                              </div>
                          </Card>
                      </List.Item>
                  )}
            />
        </Col>
    );
})

export default ChooseSupportService;