import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {Button, Col, Divider, Input, message, Select} from "antd";
import css from './prepare_order_start.module.less';
import {useHistory} from "react-router-dom";
import {useLocation} from "react-router";
import {Option} from "antd/es/mentions";

let store = require('../../store');
let data = store.prepare_order_start;
let api = require('src/api');
let moment = require('moment');
let history;
let config = require('commonProject/config/config');
let commonUtils = require('commonProject/utils/Common');

async function getOrder(id) {
    let res = await api.order.get_order_list({_id: id});
    if (res.code != 0 || res.data.length == 0) return;
    data.order = res.data.item[0];
}

//获取联系方式
async function getContacts() {
    let res = await api.order.get_contact_list();
    if (res.code != 0 || res.data.length == 0) return;
    data.contacts = res.data;
}

//添加联系方式
async function add_contact() {
    if (!data.phone_num || !data.contact_nick_name) return message.error('phone num or contact nick name not empty');
    let params = {phone_num: data.phone_num, contact_nick_name: data.contact_nick_name};
    return await api.order.add_contact(params);
}

async function setOrderContact(contactId) {
    if (!contactId) return message.error('contact not be empty');
    let params = {
        _id: data.order._id,
        contact: contactId,
    }
    let res = await api.order.update_order(params);
    if (res.code != 0) return;
    let res2 = await api.order.distribute_interpreter({_id: data.order._id});
    if (res2.code != 0) return;
    message.success('set contact success');
    history.push('/order_active?id=' + data.order._id);
}

// 订单准备开始页面
let Prepare_order_start = observer(function () {
    history = useHistory();
    let params = commonUtils.query2ObjParams(useLocation().search);
    let order_id = params.id;
    useEffect(async () => {
        await getOrder(order_id);
        await getContacts();
    }, []);

    if (commonUtils.isEmpty(data.order)) return <div/>
    return (
        <Col className={css.containBox}>
            <p className={css.headTitle}>Getting ready to connect the interpreter to you ...</p>
            <p className={css.headTitle1}>(you get ?% discount because of pre-booking)</p>
            <Divider/>
            <div>
                <p className={css.headTitle4}>You Choose:<span
                    className={css.headTitle2}>{data.order.interpret_lang_user_need.language_name}</span> - <span
                    className={css.headTitle3}>{data.order.support_service_type.type_name}</span></p>
                <p className={css.headTitle}>Confirm your number for the interpreting call:</p>
                <Select placeholder='select your callback number here'
                        onChange={(item) => {
                            data.choose_contact = JSON.parse(item);
                        }}>
                    {data.contacts.map(item => {
                        return (
                            <Option
                                value={JSON.stringify(item)}>{`${item.contact_nick_name}(${item.phone_num})`}</Option>
                        )
                    })}
                </Select>
                <p/>
                <Button type='primary' onClick={() => {
                    if (!data.choose_contact || !data.choose_contact._id) return message.error(`contact can't empty`);
                    setOrderContact(data.choose_contact._id);
                }}>Call me now</Button>
                <Divider/>
                <p className={css.headTitle}>Or, enter a new callback number:</p>
                <Input type="number" placeHolder='US Phone Number (10-digit)' onChange={(e) => {
                    data.phone_num = e.target.value;
                }}/>
                <Input placeHolder='Nickname for the number, e.g. Rm #3' onChange={e => {
                    data.contact_nick_name = e.target.value;
                }}/>
                <p/>
                <Button type='primary' onClick={async () => {
                    let res = await add_contact();
                    if (res.code != 0) return;
                    setOrderContact(res.data._id);
                }}>Call me now</Button>
            </div>
        </Col>
    );
})

export default Prepare_order_start;