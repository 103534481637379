import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {useLocation, useParams} from "react-router";
import {useHistory} from "react-router-dom";
import css from './register_add_info.module.less';
import {Button, Col, Input, Row, message, Form, Radio, Select, Checkbox, InputNumber} from "antd";
import {Link} from "react-router-dom";

let store = require('../../../store');
let api = require('src/api');
let data = store.register;
let dataPersist = store.persist.register;//缓存数据
let userInfo = {};
// eslint-disable-next-line react-hooks/rules-of-hooks
let history;
let commonUtils = require('commonProject/utils/Common');
let moment = require('moment-timezone');
let queryParams;

function submitUpdateUser() {
    let formData = data.formData;
    console.log('formData', JSON.parse(JSON.stringify(formData)));
    //如果设置了defaultValue然后直接使用没有调用onChange的话，就需要这里配置了
    if (!formData.email && userInfo.email) formData.email = userInfo.email;
    if (!formData.phone_num && userInfo.phone_num) formData.phone_num = userInfo.phone_num;
    formData.user_name = formData.email;
    if (!formData.company_name ||
        !formData.department ||
        !formData.role ||
        !formData.company_phone_num ||
        !formData.time_zone ||
        !formData.is_agree
    ) return message.error('You have some field missing');
    api.user.register_add_info(formData).then(res => {
        let resData = res;
        if (resData.code !== 0) return;
        message.success(resData.msg);
        // history.push('/login/intepreter');
        history.push('/register_result?type=success');
    })
}


function getCerts() {
    api.order.get_cert_list().then(res => {
        data.chooseCerts = res.data;
    })
}

function getLanguage() {
    api.order.get_interpret_lang_list().then(res => {
        data.chooseLangs = res.data;
    })
}

// create observer
let RegisterAddInfo = observer(function () {
    history = useHistory();
    userInfo = store.persist.userInfo;
    queryParams = commonUtils.query2ObjParams(useLocation().search);
    queryParams.is_admin = queryParams.is_admin == 'true';
    queryParams.is_super_admin = queryParams.is_super_admin == 'true';
    data.formData.is_admin = queryParams.is_admin;
    data.formData.is_super_admin = queryParams.is_super_admin;
    data.formData.time_zone = commonUtils.getClientTimeZone();

    useEffect(() => {
        getCerts();
        getLanguage();
    }, []);

    if (commonUtils.isEmpty(data.chooseCerts)
        || commonUtils.isEmpty(data.chooseLangs)
        || commonUtils.isEmpty(dataPersist)) return <div/>

    return (
        <Col aligin='middle'>
            <p className={css.headTitleBox + ' wineRed'}>Complete Your Profile</p>
            <Form name='registerForm' initialValues={{}} onFinish={submitUpdateUser}>
                <Form.Item className={css.headTitle} label='* Add your info' name='user_name'>
                    <Input placeHolder='user name' onChange={(e) => {
                        data.formData.user_name = e.target.value
                    }}/>
                </Form.Item>
                {/*<Form.Item label='Password' name='user_password'>*/}
                {/*    <Input.Password placeHolder='password' onChange={(e) => {*/}
                {/*        data.formData.user_password = e.target.value*/}
                {/*    }}/>*/}
                {/*</Form.Item>*/}
                {/*<Form.Item label='Confirm password' name='user_password_confirm' rules={[{*/}
                {/*    validator: (rule, value, callback) => {*/}
                {/*        console.log(data.formData.user_password, data.formData.user_password_confirm)*/}
                {/*        data.formData.user_password === data.formData.user_password_confirm ? callback() : callback('password is not equal')*/}
                {/*    }*/}
                {/*}]}>*/}
                {/*    <Input.Password placeHolder='password confirm' onChange={(e) => {*/}
                {/*        data.formData.user_password_confirm = e.target.value*/}
                {/*    }}/>*/}
                {/*</Form.Item>*/}
                <Form.Item className={css.headTitle} label='* Email' name='email'>
                    <Input placeHolder='email' defaultValue={userInfo.email}
                           value={data.formData.email}
                           readOnly={!!userInfo.email}
                           onChange={(e) => {
                               data.formData.email = e.target.value
                           }}/>
                </Form.Item>
                <Form.Item className={css.headTitle} label='Phone number' name='phone_num'>
                    <Input placeHolder='phone_num' defaultValue={userInfo.phone_num} onChange={(e) => {
                        data.formData.phone_num = e.target.value
                    }}/>
                </Form.Item>
                <Form.Item className={css.headTitle}label='Add your company info' name='company_info'>
                    <Input placeHolder='company_name' onChange={(e) => {
                        data.formData.company_name = e.target.value
                    }}/>
                    <Input placeHolder='department' onChange={(e) => {
                        data.formData.department = e.target.value
                    }}/>
                    <Input placeHolder='role' onChange={(e) => {
                        data.formData.role = e.target.value
                    }}/>
                    <Input placeHolder='company_phone_num' onChange={(e) => {
                        data.formData.company_phone_num = e.target.value
                    }}/>
                    <p>* The number is the landline phone at your company or department that you will use for most of
                        your interpreting service calls. You can change it or add more later. More details in FAQs. This
                        number cannot be a personal number.</p>
                </Form.Item>

                {
                    data.formData.is_admin ? (
                        <Form.Item className={css.headTitle} label='Company address' name='company_address'>
                            <span className={' hintGray'}>For verify you are real you.</span>
                            <Input placeHolder='street_address' onChange={(e) => {
                                data.formData.street_address = e.target.value
                            }}/>
                            <Input placeHolder='city' onChange={(e) => {
                                data.formData.city = e.target.value
                            }}/>
                            <Input placeHolder='state' onChange={(e) => {
                                data.formData.state = e.target.value
                            }}/>
                            <Input placeHolder='zip_code' onChange={(e) => {
                                data.formData.zip_code = e.target.value
                            }}/>
                        </Form.Item>
                    ) : (<div/>)
                }

                <Form.Item className={css.headTitle}
                           label={
                               <label className={'required'}>
                                   {`Your Time Zone ${data.formData.time_zone ?`:${data.formData.time_zone} ${moment().tz(data.formData.time_zone).format('YYYY-MM-DD HH:mm z')}`: ''}`}
                               </label>
                           }
                           name='time_zone'>
                </Form.Item>
                <Form.Item className={css.headTitle} label='Does your place observe Daylight Saving Time (or DST) in summer?'
                           name='is_summer_time'>
                    <Radio.Group defaultValue={true} value={data.formData.is_admin} onChange={(e) => {
                        data.formData.is_summer_time = e.target.value
                    }}>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item className={css.headTitle} label='Agree service'>
                    <Checkbox onChange={(e) => {
                        data.formData.is_agree = e.target.checked
                    }}>
                        By Selecting Agree and continue below, I agree to InterpreTown's Term of Service, Payment Terms
                        of Service, and Privacy Policy.
                    </Checkbox>
                </Form.Item>
                <Button className={css.btn + ' greyBtn'} htmlType="submit">
                    Submit
                </Button>
            </Form>
        </Col>
    );
});

export default RegisterAddInfo;
