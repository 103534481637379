import React, {useEffect} from 'react';
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Button, Col, Form, message, Radio, Row} from "antd";
import css from './order_detail.module.less';
import {useHistory} from "react-router-dom";
import {useLocation, useParams} from "react-router";

let store = require('../../store');
let data;
let api = require('src/api');
let moment = require('moment');
let history;
let config = require('commonProject/config/config');
let commonUtils = require('commonProject/utils/Common');
let orderHelper = require('commonProject/helper/order_helper');
let order_id;


//个人页面


async function getOrder(id) {
    let res = await api.order.get_order_list({_id: id});
    if (res.code != 0 || res.data.length == 0) return;
    data.order = res.data.item[0];
}

//获取详情的表单
function getFormItemDetail(order) {
    let arrFormItem = [];
    let keys = Object.keys(order);
    let rowKeys = [
        '_id',
        'user_name',
        'certs',
        'interpret_lang_user_need',
        'interpreter',
        // 'order_price',
        'pause_times',
        'status',
        'subscribe_time',
        'subscribe_time_length',
        // 'subscribe_type',
        'Service format',
    ]
    for (let key of rowKeys) {
        let value = '';
        switch (key) {
            case 'user_name':
                value = order.user.user_name;
                break;
            case 'certs':
                if (order.certs && order.certs.length > 0) {
                    value = order.certs[0].cert_desc;
                }
                break;
            case 'interpret_lang_user_need':
                value = order.interpret_lang_user_need.language_name;
                break;
            case 'interpreter':
                if (commonUtils.isEmpty(order.interpreter)) continue;
                value = order.interpreter._id + order.interpreter.user_name;
                break;
            case 'subscribe_time':
                value = moment(order.subscribe_time).format('MM/DD/YYYY HH:mm a');
                break;
            case 'subscribe_time_length':
                value = order.subscribe_time_length + ' min';
                break;
            case 'status':
                value = orderHelper.getStatusName(order.status);
                break;
            case 'Service format':
                value = order.support_service_type.type_name;
                break;
            default:
                value = order[key];
                break;
        }
        let a = (
            // <Form.Item label={key}>
            //
            // </Form.Item>
            <p>{key + ': ' + value}</p>
        )
        arrFormItem.push(a);
    }
    return arrFormItem;
}

// create observer
let OrderDetail = observer(function () {
    data = store.order_detail;
    history = useHistory();
    let params = commonUtils.query2ObjParams(useLocation().search);
    order_id = params.id;
    useEffect(() => {
        getOrder(order_id);
    }, []);

    if (commonUtils.isEmpty(data.order)) return <div/>;
    return (
        <Col className={css.containBox}>
            <p>Order Detail</p>
            <Form name='orderDetail'>
                {
                    getFormItemDetail(data.order)
                }
            </Form>
        </Col>
    );
});

export default OrderDetail;