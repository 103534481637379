import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {Button, Card, Col, DatePicker, Divider, Image, InputNumber, List, message, Radio, Row} from 'antd';
import {useHistory} from "react-router-dom";
import css from './change_book_time.module.less';
import {useLocation, useParams} from "react-router";

let store = require('src/store');
let formData;
let data = store.edit_order.change_book_time;
let prebookingTimeData;
let config = require('commonProject/config/config');
let api = require('src/api');
let moment = require('moment');
let history;
let commonUtils = require('commonProject/utils/Common');

function submit() {
    let formData = store.persist.add_order.formData;
    if (!formData.subscribe_time || !formData.subscribe_time_length) return message.error('you should choose time');
    console.log('formData6', JSON.parse(JSON.stringify(formData.subscribe_time)));
    history.push('/add_order/book_confirm?id=' + data.order._id);
}

async function getOrder(id) {
    let res = await api.order.get_order_list({_id: id});
    if (res.code != 0 || res.data.length == 0) return;
    let tempOrder = res.data.item[0];

    formData = store.persist.add_order.formData;
    prebookingTimeData = store.add_order.prebooking_time;
    formData.chooseLang = tempOrder.interpret_lang_user_need;
    formData.support_service_type = tempOrder.support_service_type;
    formData.subscribe_time_length = tempOrder.subscribe_time_length;
    let momentDurationSubs = moment.duration(formData.subscribe_time_length, 'minutes');
    prebookingTimeData.hour = momentDurationSubs.hours();
    prebookingTimeData.minute = momentDurationSubs.minutes();

    data.order = tempOrder
}


async function getUsableTime() {
    if (!formData.subscribe_time || !formData.subscribe_time_length) return message.error('subscribe_time and time length not be empty')
    let params = {
        subscribe_time: moment(formData.subscribe_time).format('yyyy-MM-DD'),
        subscribe_time_length: formData.subscribe_time_length,
        times: data.request_times
    };
    let result = await api.order.get_interpret_usable_time(params);
    if (result.code != 0) return;
    data.usable_times = result.data;
    data.request_times += 1;
}

// create observer
let ChangeBookTime = observer(function () {
    history = useHistory();
    let queryParams = commonUtils.query2ObjParams(useLocation().search);
    store = require('src/store');
    useEffect(async () => {
        await getOrder(queryParams.id);
    }, [])

    if (commonUtils.isEmpty(data.order)) return <div/>;
    return (
        <Col className={css.containBox}>
            <p className={css.headTitle}>step 4 of 4:<span className={css.headTitle1}> Pre-booking</span></p>
            <p className={css.headTitle5}>{`You chose:${formData.chooseLang.language_name}-${formData.support_service_type.type_name}`}</p>
            <Divider/>
            <p className={css.headTitle4}>Select the Service Date:</p>
            <DatePicker defaultValue={moment(data.order.subscribe_time) ?? moment()}
                        disabledDate={commonUtils.disabledDate}
                        suffixIcon={null}
                        onChange={date => {
                            formData.subscribe_time = date;
                            data.usable_times = [];
                            message.success('choose time:' + moment(date).format('yyyy-MM-DD'))
                        }}/>
            <Divider/>
            <p className={css.headTitle6}>Try to find an available time slot:</p>
            <Row>
                <span>Estimate the duration:</span>
                <InputNumber size='small' min={0} max={24} defaultValue={prebookingTimeData.hour} onChange={value => {
                    prebookingTimeData.hour = value
                }}/>
                <span>hr,</span>
                <InputNumber size='small' min={0} max={60} defaultValue={prebookingTimeData.minute} onChange={value => {
                    prebookingTimeData.minute = value
                }}/>
                <span>min</span>
            </Row>
            <p></p>
            <Radio.Group onChange={e => {
                let time = moment(e.target.value.startTime, 'yyyy-MM-DD HH:mm').format('yyyy-MM-DD HH:mm:ss');
                formData.subscribe_time = time;
                message.success(time);
            }}>
                {
                    data.usable_times.map(item => {
                        return (
                            <Radio value={item}
                                   style={{'display': 'block'}}>
                                {moment(item.startTime).format('HH:mm') + '~' + moment(item.endTime).format('HH:mm')}
                            </Radio>
                        )
                    })
                }
            </Radio.Group>
            <Col>
                <Button className={css.headTitle3} onClick={async () => {
                    formData.subscribe_time_length = prebookingTimeData.hour * 60 + prebookingTimeData.minute;
                    await getUsableTime();
                }}>confirm</Button>
            </Col>
            <p></p>
            <Button className={css.headTitle3} onClick={submit}>continue</Button>
        </Col>
    );
})

export default ChangeBookTime;