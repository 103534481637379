import React, {useEffect} from 'react';
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Button, Col, Divider, message, Radio, Row} from "antd";
import css from './user_func_menu.module.less';
import {useHistory} from "react-router-dom";
import {useParams, useRouteMatch, useLocation} from "react-router";
import {loadStripe} from '@stripe/stripe-js';

let store = require('../../store');
let data = store.home_login;
let api = require('src/api');
let moment = require('moment');
let history;
let config = require('commonProject/config/config');
let commonUtils = require('commonProject/utils/Common');
let userInfo;

//用户功能菜单页面

async function init() {

}

function getViewType(type = 'employee') {
    return (
        <div className={css.menuListBox}>
            {
                ['owner'].includes(type) ? (
                    <div className={css.menuBox}>
                        <div className={css.leftTitle}>Work on your account:</div>
                        <div className={css.rightBox}>
                            <div className={'greyBtn bold ' + css.btn} onClick={() => {
                                history.push('/account_owner_setting?type=payment_read')
                            }}>Payment
                            </div>
                            <div className={'greyBtn bold ' + css.btn} onClick={() => {
                                history.push('/account_owner_setting?type=account_alert_read')
                            }}>Alerts
                            </div>
                            <div className={'greyBtn ' + css.btn} onClick={() => {
                                history.push('/account_owner_setting?type=account_info_read')
                            }}>Acc Info
                            </div>
                        </div>
                    </div>
                ) : null
            }
            {
                ['owner',].includes(type) ? (
                    <div className={css.menuBox}>
                        <div className={css.leftTitle}>Work on team admin(s):</div>
                        <div className={css.rightBox}>
                            <div className={'greyBtn bold ' + css.btn} onClick={() => {
                                history.push('/account_owner_setting?type=team_admin_read')
                            }}>Add
                            </div>
                            <div className={'greyBtn bold ' + css.btn} onClick={() => {
                                history.push('/account_owner_setting?type=team_admin_edit')
                            }}>Edit
                            </div>
                        </div>
                    </div>
                ) : null
            }
            {
                ['owner', 'team_admin',].includes(type) ? (
                    <div className={css.menuBox}>
                        <div className={css.leftTitle}>Work on users:</div>
                        <div className={css.rightBox}>
                            <div className={'greyBtn bold ' + css.btn} onClick={() => {
                                if (userInfo.user_role_type == 'team_admin') {
                                    history.push(`/account_owner_setting?type=team_info_edit&user_id=${userInfo._id}`);
                                } else {
                                    history.push('/team_admin_setting?type=team_admin_role')
                                }
                            }}>Add
                            </div>
                            <div className={'greyBtn bold ' + css.btn} onClick={() => {
                                history.push('/account_owner_setting?type=team_admin_edit_user')
                            }}>Edit
                            </div>
                            <div className={'greyBtn bold ' + css.btn} onClick={() => {
                                history.push('/account_owner_setting?type=all_user_list')
                            }}>User list
                            </div>
                        </div>
                    </div>
                ) : null
            }
            {
                ['owner', 'team_admin', 'employee'].includes(type) ? (
                    <div className={css.menuBox}>
                        <div className={css.leftTitle}>Use interpreting service:</div>
                        <div className={css.rightBox}>
                            <div className={'greyBtn bold ' + css.btn} onClick={() => {
                                history.push('/')
                            }}>Go
                            </div>
                        </div>
                    </div>
                ) : null
            }
        </div>
    )
}

let UserFuncMenu = observer(function () {
    history = useHistory();
    userInfo = store.persist.userInfo;

    useEffect(() => {
        init()
    }, []);

    let params = commonUtils.query2ObjParams(useLocation().search);
    let pay_result = params.type;
    let user_role_type = store.persist.userInfo.user_role_type;

    return (
        <Col className={css.containBox}>
            <div className={css.topTitle}>{user_role_type == 'owner' ? 'Account Owner Menu' : 'Team Admin Menu'}</div>
            <Divider/>
            {getViewType(user_role_type)}
        </Col>
    );
});

export default UserFuncMenu;
