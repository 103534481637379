import React, {Component} from "react";
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Button, Col, Dropdown, Image, Input, Menu, message, Modal, Row} from "antd";
import css from "./passwordVerify.module.less";
// import './header.module.less';
import menuSvg from 'src/assets/img/header/menu.svg';
import {useHistory} from "react-router-dom";
import {useLocation} from "react-router";

let mobx = require('mobx');
let store = require('src/store');
let api = require('src/api');
let history;
let location;
let menu;
let commonUtils = require('commonProject/utils/Common');
let data = mobx.observable({
    is_show_dialog: false,
    // onReturnVerify: null,
});


let PasswordVerifyBox = observer(
    class PasswordVerifyBox extends Component {

        constructor(props) {
            super(props);
            this.state = {
                onReturnVerify: function (res) {
                },
            }
        }

        show = (options) => {
            data.is_show_dialog = true;
            this.setState({...options});
        }

        async verifyPassword() {
            let res = await api.user.verify_password({user_password: data.password});
            let isOk = res.code == 0;
            this.state.onReturnVerify(isOk);
            if (isOk) {
                data.is_show_dialog = false;
                store.persist.is_verify_psw = true;
            }
        }

        async updatePassword() {
            let res = await api.user.update_user({user_password: data.password});
            let isOk = res.code == 0;
            this.state.onReturnVerify(isOk);
            if (isOk) {
                data.is_show_dialog = false;
                store.persist.is_verify_psw = true;
            }
        }

        render() {
            let that = this;
            if (store.persist.is_verify_psw) {
                this.state.onReturnVerify(true);
                return null;
            }
            return (
                <div className={css.containBox}>
                    {store.persist.userInfo.user_password ? (
                        <div className={css.containBox1}>
                            <Modal title="pass code verify" visible={data.is_show_dialog} onCancel={() => {
                                data.is_show_dialog = false;
                            }} footer={null} className={css.subUserDialog}>
                                <div className={css.subUserDialog2}>
                                    <div className={css.addBox}>
                                        <Input className={css.inputPsw} placeholder='password' onChange={e => {
                                            data.password = e.target.value
                                        }}/>
                                        <Button style={{marginTop: '10px'}} onClick={() => {
                                            that.verifyPassword()
                                        }}>send</Button>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    ) : (
                        <div className={css.containBox1}>
                            <Modal title="set pass code" visible={data.is_show_dialog} onCancel={() => {
                                data.is_show_dialog = false;
                            }} footer={null} className={css.subUserDialog}>
                                <div className={css.subUserDialog2}>
                                    <div className={css.addBox}>
                                        <div>
                                            -- You need to setup a 4-digit code for the first time only.
                                        </div>
                                        <div>-- Every time later on when you need to change important setting, you will
                                            be asked for this 4-digit code. So please write down in a safe place and
                                            remember it.
                                        </div>
                                        <Input placeholder='4 digit code' onChange={e => {
                                            data.password = e.target.value
                                        }}/>
                                        <Button style={{marginTop: '10px'}} onClick={() => {
                                            that.updatePassword()
                                        }}>Submit</Button>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    )}
                </div>
            )
        }
    }
)


let div = document.createElement('div');
document.body.appendChild(div);
let PasswordVerify = ReactDOM.render(<PasswordVerifyBox/>, div);

export default PasswordVerify;
