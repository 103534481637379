// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".containBox_DWxN0K .containBox1_2oq1fL .subUserDialog_2vP6bG .subUserDialog2_2LkLw8 .addBox_19yf_e {\n  display: flex;\n  flex-direction: column;\n  width: 10vw;\n}\n.containBox_DWxN0K .containBox1_2oq1fL .subUserDialog_2vP6bG .subUserDialog2_2LkLw8 .addBox_19yf_e .inputPsw_2FWYDv {\n  margin-bottom: 10px;\n}\n.containBox_DWxN0K .containBox1_2oq1fL .subUserDialog_2vP6bG .subUserDialog2_2LkLw8 .addBox_19yf_e .submitBtn1_2awZcp {\n  margin: 10px;\n  color: red;\n}\n", "",{"version":3,"sources":["webpack://src/component/password_verify/passwordVerify.module.less"],"names":[],"mappings":"AAAA;EAQU,aAAA;EACA,sBAAA;EACA,WAAA;AANV;AAJA;EAaY,mBAAA;AANZ;AAPA;EAiBY,YAAA;EACA,UAAA;AAPZ","sourcesContent":[".containBox {\n\n  .containBox1 {\n    //text-align: center;\n\n    .subUserDialog {\n      .subUserDialog2 {\n        .addBox {\n          display: flex;\n          flex-direction: column;\n          width: 10vw;\n\n          .inputPsw {\n            margin-bottom: 10px;\n          }\n\n          .submitBtn1 {\n            margin: 10px;\n            color: red;\n          }\n        }\n\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containBox": "containBox_DWxN0K",
	"containBox1": "containBox1_2oq1fL",
	"subUserDialog": "subUserDialog_2vP6bG",
	"subUserDialog2": "subUserDialog2_2LkLw8",
	"addBox": "addBox_19yf_e",
	"inputPsw": "inputPsw_2FWYDv",
	"submitBtn1": "submitBtn1_2awZcp"
};
export default ___CSS_LOADER_EXPORT___;
