import React, {useEffect} from 'react';
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {
    Button,
    Card,
    Col,
    Divider,
    Image,
    Collapse,
    Input,
    message,
    Modal,
    Radio,
    Row,
    Switch,
    InputNumber, Checkbox, Table, Select,
} from "antd";
import css from './account_owner_setting.module.less';
import {useHistory} from "react-router-dom";
import {useParams, useRouteMatch, useLocation} from "react-router";
import {loadStripe} from '@stripe/stripe-js';
import add_icon from 'src/assets/img/common/add_icon.svg';
import del_icon from 'src/assets/img/common/del_icon.svg';
import PasswordVerify from "../../component/password_verify/passwordVerify";
import BackBtn from "../../component/back_btn/back_btn";

const {Panel} = Collapse;
let store = require('../../store');
let mobx = require('mobx');
let userInfo;
let queryParams = {};
let api = require('src/api');
let moment = require('moment');
let history;
let config = require('commonProject/config/config');
let commonUtils = require('commonProject/utils/Common');
let data = mobx.observable({
    objSetPassword: {},
    objTeamAdmin: {
        data: [],//子团队数据
        formatData: {},//添加子团队数据
        // activeKey: ['1'],//激活的面板
        activeItem: {},//激活的子项
    },
    objTeamAdminEdit: {
        type: 'read',
        emailOrPhone: null,//搜索的邮箱号或者电话
        data: {},//team_admin团队数据
        formatData: {},//更新team_admin的数据
    },
    objTeamInfo: {
        user: {},
        subUsers: [],
        formatData: {},//添加子员工的数据
    },
    objPayment: {
        max_auto_pay: 200,
        auto_pay_status: 1,
    },
    objAccountAlert: {
        notifyData: {},
        formData: {
            notify_by_email_status: 0,
            notify_by_phone_status: 0,
        },
        notifyHistory: [],
    }
});


async function setPassword() {
    let res = await api.user.update_user({_id: userInfo._id, user_password: data.objSetPassword.user_password});
    if (res.code != 0) return;
    message.success('set password success');
    history.push('/account_owner_setting')
    // commonUtils.historyPushRefresh(history, '/account_owner_setting')
}

function init(queryParams) {
    getTeamAdmins();
    getTeamAdminItem(queryParams);
    getUserNotifyConfig();
    getUserNotifyHistory();
}

async function getTeamAdmins() {
    if (!['owner', 'team_admin'].includes(userInfo.user_role_type)) return;
    let res = await api.user.get_sub_users();
    if (res.code != 0) return;
    if (userInfo.user_role_type == 'team_admin') {
        let objItem = {
            ...userInfo
        };
        objItem.subUser = res.data;
        data.objTeamAdmin.data = [objItem];
    } else data.objTeamAdmin.data = res.data;
}

async function getTeamAdminItem(queryParams) {
    if (!queryParams.user_id && !queryParams.emailOrPhone) return;
    let res = await api.user.owner_get_user_info(queryParams);
    if (res.code != 0) return false;
    data.objTeamInfo.user = res.data.user;
    data.objTeamAdminEdit.data = res.data.user;
    data.objTeamInfo.subUsers = res.data.subUsers;
    return true;
}

async function addTeamAdmin() {
    let params = {
        p_user_id: userInfo._id,
        account: data.objTeamAdmin.formatData.email,
        first_name: data.objTeamAdmin.formatData.first_name,
        second_name: data.objTeamAdmin.formatData.second_name,
        team_name: data.objTeamAdmin.formatData.team_name,
        user_role_type: 'team_admin',
    };
    if (!params.account || !params.first_name || !params.second_name || !params.team_name) return message.error('must add all params');
    if (!commonUtils.isEmail(params.account)) return message.error('email format is error');
    let res = await api.user.admin_add_sub_user(params);
    if (res.code != 0) return;
    message.success('add success');
    history.go(0);
}

async function delTeamAdmin(email, isRefresh = false) {
    Modal.confirm({
        content: 'Danger!! Deleting this admin user will delete all users under it, are you sure? The best way is to change the admin status to "inactive".',
        cancelText: 'back',
        okText: 'delete',
        okButtonProps:{style:{background:'red','border-color':'red'}},
        onOk: async function () {
            let res = await api.user.admin_del_sub_user({account: email});
            if (res.code != 0) return;
            message.success('delete success');
            data.objTeamAdminEdit.type = 'del_success';
            if (isRefresh) history.go(0);
        },
    })
}


async function updateMaxAutoPay() {
    let res = await api.user.update_user({
        max_auto_pay: data.objPayment.max_auto_pay,
        auto_pay_status: data.objPayment.auto_pay_status,
    });
    if (res.code != 0) return;
    message.success('update success');
    api.user.update_user_max_auto_pay();
    history.go(-1);
}


async function getUserNotifyConfig() {
    let res = await api.user.get_user_notify_config();
    if (res.code != 0 || !res.data) return;
    data.objAccountAlert.notifyData = {...res.data};
    data.objAccountAlert.formData = {...res.data};
}

async function updateUserNotifyConfig() {
    let formData = data.objAccountAlert.formData;
    if (formData.phone && !commonUtils.isUSAPhone(formData.phone)) return message.error('phone number format error');
    if (!data.objAccountAlert.notifyData._id) {
        return addUserNotifyConfig();
    }
    let res = await api.user.update_user_notify_config(data.objAccountAlert.formData);
    if (res.code != 0) return;
    message.success('update success');
    history.go(-1);
}

async function addUserNotifyConfig() {
    let res = await api.user.add_user_notify_config(data.objAccountAlert.formData);
    if (res.code != 0) return;
    message.success('add success');
}


async function getUserNotifyHistory() {
    let res = await api.user.get_user_notify_list();
    if (res.code != 0 || !res.data) return;
    data.objAccountAlert.notifyHistory = res.data?.item;
}

async function delUserNotifyHistory(id_user_notify) {
    let res = await api.user.del_user_notify({_id: id_user_notify});
    if (res.code != 0) return;
    history.go(0)
}

async function addEmployee() {
    let params = {
        p_user_id: data.objTeamInfo.user._id,
        account: data.objTeamInfo.formatData.account,
        landline: data.objTeamInfo.formatData.landline,
        first_name: data.objTeamInfo.formatData.first_name,
        second_name: data.objTeamInfo.formatData.second_name,
        team_name: data.objTeamInfo.user.team_name,
        user_role_type: 'employee',
    };
    console.log('params', params)
    if (!params.account || !params.first_name || !params.second_name || !params.team_name) return message.error('must add all params');
    let res = await api.user.admin_add_sub_user(params);
    if (res.code != 0) return;
    message.success('add success');
    history.go(0);
}

async function delEmployee(email) {
    Modal.confirm({
        content: 'are you sure delete?',
        onOk: async function () {
            let res = await api.user.admin_del_sub_user({account: email});
            if (res.code != 0) return;
            message.success('add success');
            history.go(0);
        },
    })
}

async function onClickSearchTeamAdminItem(queryParams) {
    let res = await getTeamAdminItem(queryParams);
    //775311664@qq.com
    if (!res) return;
    data.objTeamAdminEdit.type = 'edit';
}

async function updateTeamAdminItem(formData) {
    message.info('Updating database, please wait ... ...');
    let res = await api.user.owner_update_user(formData);
    if (res.code != 0) return;
    message.success('update user success');
    await onClickSearchTeamAdminItem({emailOrPhone: data.objTeamAdminEdit.emailOrPhone})
    data.objTeamAdminEdit.type = 'edit_success';
    // history.go(0);
}

function getLoginTypePlaceHolder(type) {
    switch (type) {
        case 'Email':
            return 'email';
        case 'Phone':
            return 'phone';
        case 'Landline':
            return 'Landline';
        default:
            return 'email, or phone # (10-digit)';
    }
}

function getSetPasswordView() {
    return (
        <div className={css.setPasswordBox}>
            <h3 className={css.headerTitle}>Account Owner Role</h3>
            <Divider/>
            <p className={css.set4WordTitle}>Setup your 4 digit code:</p>
            <div/>
            <Input placeholder={'4 gigit code'} className={css.inputPsw} onChange={(e) => {
                data.objSetPassword.user_password = e.target.value
            }}/>
            <div className={'hintGray italic'}>This is only needed for the first time. Remember this security code
                because you will need this code
                every time when you change the Owner Settings. In case you forget, you have to contact us and we will
                call your business phone to recover the code.
            </div>
            <div className={'greyBtn ' + css.btn} onClick={setPassword}>Next</div>
        </div>
    );
}

function getMenuList() {
    return (
        <div className={css.menuListBox}>
            <div className={css.headerTitle}>Account Owner Role</div>
            <Divider/>
            <div className={css.btnBox}>
                <Button className={'greyBtn '} onClick={() => {
                    history.push('/account_owner_setting?type=team_admin_read')
                }}><span className={'bold'}>Add Team Admin(s)</span></Button>
                <Button className={'greyBtn ' + css.btn} onClick={() => {
                    history.push('/account_owner_setting?type=payment_read')
                }}><span className={'bold'}>Setup Payment</span></Button>
                <Button className={'greyBtn ' + css.btn} onClick={() => {
                    history.push('/account_owner_setting?type=account_alert_read')
                }}><span className={'bold'}>Account Alerts</span></Button>
                <Button className={'greyBtn ' + css.btnBusiness} onClick={() => {
                    history.push('/account_owner_setting?type=account_info_read')
                }}>Account Owner Info</Button>
                <Button className={'greyBtn ' + css.btnBusiness} onClick={() => {
                    history.push('/account_owner_setting?type=all_user_list');
                }}>All Users List</Button>

                {/*<Button className={'greyBtn ' + css.btn} onClick={() => {*/}
                {/*    history.push('/account_owner_setting?type=switch_admin')*/}
                {/*}}>Switch to Team Admin Role</Button>*/}
            </div>
        </div>
    );
}

function getTeamAdminView(type = 'read') {
    return (
        <div className={css.teamAdminBox}>
            <div className={css.teamTitle}>Add Team Admin:</div>
            <div className={css.hintTeam}>1) A <span className={'italic'}>team admin</span> is a service site manager, a
                branch manager, or a department manager, etc.
            </div>
            <div className={css.hintTeam}>2) Please add Team Admin first before adding any general users under the
                admin.
            </div>
            <Divider/>
            <div className={css.teamList}>
                {data.objTeamAdmin.data.filter(arrItem => arrItem.is_owner_self_team_admin != 1).map(item => {
                    return (
                        <div className={css.teamItem}>
                            <Row align={'middle'} className={css.nameBox}>
                                {`Team name:`}&nbsp;<span className={css.name}>{item.team_name}</span>
                                {/*{type == 'add' ?*/}
                                {/*    (*/}
                                {/*        <Image src={del_icon} className={css.delIcon} preview={false}*/}
                                {/*               onClick={() => {*/}
                                {/*                   delTeamAdmin(item.email, true)*/}
                                {/*               }}/>*/}
                                {/*    ) : null*/}
                                {/*}*/}
                            </Row>
                            <div>{`Admin:`}&nbsp;
                                <span className={css.otherValue}>{`${item.second_name}`},&nbsp;</span>
                                <span className={css.otherValue}>{`${item.first_name}`}</span>
                            </div>
                            <div>{`Email:`}&nbsp;<span className={css.otherValue}>{item.email}</span></div>
                        </div>
                    )
                })}
            </div>
            {(type == 'read' ? (
                <Row align={'center'}>
                    <div className={'greyBtn ' + css.btn} onClick={() => {
                        history.push('/account_owner_setting?type=team_admin_add')
                    }}>Add
                    </div>
                </Row>
            ) : (
                <div>

                    <Divider/>
                    <div className={css.editBox}>
                        <div>
                            <Input placeholder='Admin Email' onChange={(e) => {
                                data.objTeamAdmin.formatData.email = e.target.value
                            }}/>
                            <Input placeholder='Admin First Name' onChange={(e) => {
                                data.objTeamAdmin.formatData.first_name = e.target.value
                            }}/>
                            <Input placeholder='Admin Last Name' onChange={(e) => {
                                data.objTeamAdmin.formatData.second_name = e.target.value
                            }}/>
                            <Input placeholder='Team Name' onChange={(e) => {
                                data.objTeamAdmin.formatData.team_name = e.target.value
                            }}/>
                        </div>
                        <Button className={'greyBtn ' + css.btn} onClick={addTeamAdmin}>Add</Button>

                    </div>
                </div>
            ))}
            <BackBtn/>
        </div>

    )
}

function getTeamAdminViewEdit() {
    //type有 read  edit  edit_success del_success这几个页面
    // data.objTeamAdminEdit.type = 'del_success';
    let userTitle = queryParams.type == 'team_admin_edit_user' ? 'Edit User:' : 'Edit Team Admin:';
    let userSpan = queryParams.type == 'team_admin_edit_user' ? 'User' : 'Admin';
    return (
        <div className={css.teamAdminInfoBox}>
            <div
                className={css.teamTitle}>{userTitle}</div>
            <div className={css.hintTeam}>Search the {userSpan} by the login ID.</div>
            <Input placeholder='e.g. email or phone number'
                // defaultValue={data.objTeamAdminEdit.emailOrPhone}
                   className={css.emailInput}
                   onChange={(e) => {
                       data.objTeamAdminEdit.emailOrPhone = e.target.value
                   }}/>
            <div className={'greyBtn ' + css.btnSearch}
                 onClick={() => {
                     onClickSearchTeamAdminItem({emailOrPhone: data.objTeamAdminEdit.emailOrPhone})
                 }}>Search
            </div>
            <Divider/>
            {
                data.objTeamAdminEdit.type == 'edit' ? (
                    <div className={css.infoBox}>
                        <div className={css.itemBox}>
                            <span className={css.spanKey}>Team name:</span>
                            <Input placeholder='GYN department'
                                   className={css.value}
                                   value={data.objTeamAdminEdit.data.team_name}
                                   onChange={(e) => {
                                       data.objTeamAdminEdit.formatData.team_name = e.target.value
                                   }}/>
                        </div>
                        <div className={css.itemBox}>
                            <span className={css.spanKey}>{userSpan} first name:</span>
                            <Input placeholder='Elon' className={css.value}
                                   value={data.objTeamAdminEdit.data.first_name}
                                   onChange={(e) => {
                                       data.objTeamAdminEdit.formatData.first_name = e.target.value
                                   }}/>
                        </div>
                        <div className={css.itemBox}>
                            <span className={css.spanKey}>{userSpan} last name:</span>
                            <Input placeholder='Musk'
                                   className={css.value}
                                   value={data.objTeamAdminEdit.data.second_name}
                                   onChange={(e) => {
                                       data.objTeamAdminEdit.formatData.second_name = e.target.value
                                   }}/>
                        </div>
                        <div className={css.itemBox}>
                            <span className={css.spanKey}>{userSpan} login ID:</span>
                            <Input className={css.value}
                                   value={data.objTeamAdminEdit.emailOrPhone}
                                   disabled={true}
                            />
                        </div>
                        <div className={css.itemBox}>
                            <span className={css.spanKey}>{userSpan} status:</span>
                            <Switch className={css.switchBtn}
                                    checked={data.objTeamAdminEdit.data.status == 1}
                                    onChange={checked => {
                                        data.objTeamAdminEdit.formatData.status = checked ? 1 : 0;
                                        data.objTeamAdminEdit.data.status = checked ? 1 : 0;
                                    }}/>
                            <span>{data.objTeamAdminEdit.data.status == 1 ? 'active' : 'disabled'}</span>
                        </div>
                        <div className={'greyBtn ' + css.btnUpdate} onClick={() => {
                            updateTeamAdminItem({_id: data.objTeamAdminEdit.data._id, ...data.objTeamAdminEdit.formatData});
                        }}>Update
                        </div>
                        <div className={'greyBtn ' + css.btnDelete} onClick={() => {
                            delTeamAdmin(data.objTeamAdminEdit.data.email)
                        }}>Delete Account
                        </div>
                        <BackBtn/>
                    </div>
                ) : null
            }
            {data.objTeamAdminEdit.type == 'edit_success' ? (
                <div className={css.infoBox}>
                    <div className={css.itemBox}>
                        <span className={css.spanKey}>Team name:</span>
                        <span>{data.objTeamAdminEdit.data.team_name}</span>
                    </div>
                    <div className={css.itemBox}>
                        <span className={css.spanKey}>Admin first name:</span>
                        <span>{data.objTeamAdminEdit.data.first_name}</span>
                    </div>
                    <div className={css.itemBox}>
                        <span className={css.spanKey}>Admin last name:</span>
                        <span>{data.objTeamAdminEdit.data.second_name}</span>
                    </div>
                    <div className={css.itemBox}>
                        <span className={css.spanKey}>Admin login ID:</span>
                        <span>{data.objTeamAdminEdit.data.email}</span>
                    </div>
                    <div className={css.itemBox}>
                        <span className={css.spanKey}>Admin status:</span>
                        <span>{data.objTeamAdminEdit.data.status == 1 ? 'active' : 'disabled'}</span>
                    </div>
                    {/*<div className={css.updateHint}>Update was successful.</div>*/}
                    <BackBtn/>
                </div>
            ) : null}
            {data.objTeamAdminEdit.type == 'del_success' ? (
                <div className={css.infoBox}>
                    <div className={css.itemBox}>
                        <span className={css.spanKey}>Team name:</span>
                    </div>
                    <div className={css.itemBox}>
                        <span className={css.spanKey}>Admin first name:</span>
                    </div>
                    <div className={css.itemBox}>
                        <span className={css.spanKey}>Admin last name:</span>
                    </div>
                    <div className={css.itemBox}>
                        <span className={css.spanKey}>Admin login ID:</span>
                    </div>
                    <div className={css.itemBox}>
                        <span className={css.spanKey}>Admin status:</span>
                    </div>
                    {/*<div className={css.updateHint}>The account was deleted!</div>*/}
                    <BackBtn/>
                </div>
            ) : null}
        </div>
    )
}

function getSwitchAdminView() {
    return (
        <div className={css.switchAdminBox}>
            <h3 className={css.headerTitle}>All Users List</h3>
            <div className={css.titleDesc}>To add a Team Admin, go to "Team Admin List" on previous page. Please add a
                team admin first before
                adding any general users.
            </div>
            <Divider/>
            <p className={'bold'}>Click on a Team Admin & switch to her/his role:</p>
            <div className={css.teamList}>
                {data.objTeamAdmin.data.filter(arrItem => arrItem.is_owner_self_team_admin != 1).map(item => {
                    return (
                        <Card className={css.teamItem} onClick={() => {
                            history.push(`/account_owner_setting?type=team_info_read&user_id=${item._id}`)
                        }}>
                            <Row align={'middle'} className={css.nameBox}>
                                {`Team name:`}<span className={css.name}>{item.team_name}</span>
                            </Row>
                            <div>{`Admin:`}<span
                                className={css.otherValue}>{`${item.second_name},${item.first_name}`}</span></div>
                            <div>{`Email:`}<span className={css.otherValue}>{item.email}</span></div>
                        </Card>
                    )
                })}
            </div>
            <BackBtn/>
        </div>
    )
}

function getTeamInfo(type = 'read') {
    if (type == 'edit') {
        return (
            <div className={css.teamInfoEditBox}>
                <span className={css.headerTitle}>Add User</span>
                <span className={css.toAddUserHint}>(under this team admin)</span>
                <div className={css.teamInfoBox}>
                    <div className={css.box}>
                        <span className={css.key}>Team Name: </span>
                        <span className={css.value}>{`${data.objTeamInfo.user.team_name}`}</span>
                    </div>
                    <div className={css.box}>
                        <span className={css.key}>Team Admin Name: </span>
                        <span
                            className={css.value}>{data.objTeamInfo.user.first_name + data.objTeamInfo.user.second_name} </span>
                    </div>
                    <div className={css.box}>
                        <span className={css.key}>Email: {}</span>
                        {data.objTeamInfo.user.email ? (
                            <span>{commonUtils.substrOwnerSelfAdminEmail(data.objTeamInfo.user.email)}</span>
                        ) : ''
                        }
                    </div>
                </div>
                <Divider/>
                <div className={css.addBox}>
                    <div className={css.inputBox}>
                        <Select placeholder='Login Type' className={css.selectLoginType + ' italic'}
                                onChange={(value) => {
                                    data.objTeamInfo.formatData.login_type = value
                                }}>
                            {[{title: 'email login', value: 'Email'},
                                {title: 'cell phone number login', value: 'Phone'},
                                {title: 'landline number login', value: 'Landline'},].map(item => {
                                return (
                                    <Select.Option value={item.value} className={'italic'}>
                                        {item.title}
                                    </Select.Option>
                                )
                            })}
                        </Select>
                        <Input
                            placeholder={getLoginTypePlaceHolder(data.objTeamInfo.formatData.login_type)}
                            onChange={(e) => {
                                switch (data.objTeamInfo.formatData.login_type) {
                                    case 'Email':
                                        data.objTeamInfo.formatData.email = e.target.value;
                                        break;
                                    case 'Phone':
                                        data.objTeamInfo.formatData.phone_num = e.target.value
                                        break;
                                    case 'Landline':
                                        data.objTeamInfo.formatData.landline = e.target.value
                                        break;
                                }
                                data.objTeamInfo.formatData.account = e.target.value;
                            }}/>
                        <Input placeholder='First Name (or team name)' onChange={(e) => {
                            data.objTeamInfo.formatData.first_name = e.target.value
                        }}/>
                        <Input placeholder='Last Name (or business name)' onChange={(e) => {
                            data.objTeamInfo.formatData.second_name = e.target.value
                        }}/>
                    </div>
                    <Button className={'greyBtn ' + css.btn} onClick={addEmployee}>Add</Button>
                </div>
                <div className={css.desc}>Note: If using landline login, consider department name + business name as
                    First Name + Last Name, or
                    location name + department name, etc. A landline number will be considered as a unique user even if
                    it's shared by multiple persons.
                </div>
                <BackBtn/>
            </div>
        )
    }
    return (
        <div className={css.teamInfoBox}>
            <h3 className={css.headerTitle}>Users in My Team</h3>
            <div className={css.team}>
                <div className={css.title}>Team Admin:</div>
                <Row align={'middle'} className={css.nameBox}>
                    {`Team name:`}<span className={css.name}>{data.objTeamInfo.user.team_name}</span>
                </Row>
                <div>{`Admin:`}<span
                    className={css.otherValue}>{`${data.objTeamInfo.user.second_name},${data.objTeamInfo.user.first_name}`}</span>
                </div>
                <div>{`Email:`}<span className={css.otherValue}>{data.objTeamInfo.user.email}</span></div>

            </div>
            <div className={css.timeZone}>
                <div className={css.title}>Time Zone:</div>
                <div>{data.objTeamInfo.user.time_zone}</div>
                <div>{data.objTeamInfo.user.is_summer_time ? 'Your location observes Daylight Saving Time (DST) in summer.' : ''}</div>
            </div>
            <div className={css.teamMembers}>
                <Row align={'middle'} className={css.headerBox}>
                    <div className={css.headerTitle}>Team Members Login IDs</div>
                    <div className={'greyBtn ' + css.btn} onClick={() => {
                        history.push(`/account_owner_setting?type=team_info_edit&user_id=${data.objTeamInfo.user._id}`)
                    }}>Add
                    </div>
                </Row>
                <div>
                    <Collapse defaultActiveKey={['1']} ghost onChange={() => {
                    }}>
                        <Panel header="team members log in by email:" key="1">
                            {data.objTeamInfo.subUsers.map(item => {
                                return (
                                    <div className={css.team}>
                                        <div>{`Name:`}<span className={css.otherValue}>{item.email}</span></div>
                                        <div>{`Email:`}<span className={css.otherValue}>{item.email}</span></div>
                                    </div>
                                )
                            })}
                        </Panel>
                    </Collapse>
                </div>
            </div>
            <BackBtn/>
        </div>
    );
}


function getPayment(type = 'read') {
    let isRead = type == 'read';
    return (
        <div className={css.payment}>
            <h1 className={css.title}>Payment Settings:</h1>
            <div>Invoice will be sent to the owner's email every Sunday night. Payment is due by the end of Wednesday.
            </div>
            <Divider/>
            <div>Payment method (Stripe): <span className={'darkBlue'}>Credit Card</span></div>
            <div className={css.autoPayBox}>
                <span>Auto-Pay:</span>
                <Switch className={css.switchBtn}
                        disabled={isRead}
                        defaultChecked={userInfo.auto_pay_status == 1}
                        onChange={checked => {
                            data.objPayment.auto_pay_status = checked ? 1 : 0;
                        }}/>
            </div>
            <div>Stop auto-payment and alert you if weekly bill is more than $<span
                className={css.money}>{userInfo.max_auto_pay}</span> USD.<span className={'hintGray italic'}>(Manually authorization of payment is needed in this case).</span>
            </div>
            {
                !isRead ? (
                    <div className={css.enterMoneyBox}>Enter the amount:
                        <InputNumber size='small' min={0} defaultValue={userInfo.max_auto_pay} onChange={value => {
                            data.objPayment.max_auto_pay = value
                        }}/>
                        USD
                    </div>
                ) : null
            }

            <Row align={'center'} className={css.btn}>
                <div className={'greyBtn '} onClick={() => {
                    if (isRead) {
                        PasswordVerify.show({
                            onReturnVerify: resVerify => {
                                if (resVerify) {
                                    message.info(`verify success`);
                                    history.push('/account_owner_setting?type=payment_edit');
                                } else {
                                    message.info(`verify fail`);
                                }
                            }
                        });
                    } else {
                        updateMaxAutoPay();
                    }
                }}>{type == 'read' ? 'Edit' : 'Done'}</div>
            </Row>
            <BackBtn/>
        </div>
    );
}

function getAccountAlert(type = 'read') {
    let isEdit = type == 'edit';
    return (
        <div className={css.accountAlert}>
            <h1 className={css.title}>Account Alerts:</h1>
            <p className={css.alertDeliver}>Alert Delivery:</p>
            <Checkbox
                disabled={true}
                checked={data.objAccountAlert.formData.notify_by_email_status}
                className={css.notifyConfigItem}
                onChange={(e) => {
                    data.objAccountAlert.formData.notify_by_email_status = e.target.checked
                }}>Alert your by sending you email.</Checkbox>
            {/*<Checkbox*/}
            {/*    disabled={!isEdit}*/}
            {/*    checked={data.objAccountAlert.formData.notify_by_phone_status} onChange={(e) => {*/}
            {/*    data.objAccountAlert.formData.notify_by_phone_status = e.target.checked*/}
            {/*}}>Alert your by sending SMS to the number:</Checkbox>*/}
            {/*<Input disabled={!isEdit} placeholder={'10 digits cell phone number'} className={css.inputPsw}*/}
            {/*       defaultValue={commonUtils.formatUSAPhoneStr(store.persist.userInfo.phone_num)}*/}
            {/*       onChange={(e) => {*/}
            {/*           data.objAccountAlert.formData.phone = e.target.value;*/}
            {/*           data.objAccountAlert.formData.notify_by_phone_status = !!e.target.value;*/}
            {/*       }}/>*/}
            <div className={css.chooseAlertItemTitle}>Choose Alert Items:</div>
            <div className={css.alertItemDesc}>You will always get an alert when there is a team member added, a
                landline for interpreting service
                added, or payment setting changed.
            </div>
            <Checkbox
                disabled={!isEdit}
                className={css.notifyConfigItem}
                checked={data.objAccountAlert.formData.notify_call_more_than_time_status}
                onChange={(e) => {
                    data.objAccountAlert.formData.notify_call_more_than_time_status = e.target.checked
                }}>{`when a single call is longer than
                ${data.objAccountAlert.formData.notify_call_more_than_time ?? '____'} minutes.`}</Checkbox>

            {isEdit ? (
                <div className={'italic'}>
                    Call duration <InputNumber className={css.inputPsw}
                                               onChange={(value) => {
                                                   data.objAccountAlert.formData.notify_call_more_than_time = value;
                                                   data.objAccountAlert.formData.notify_call_more_than_time_status = !!value;
                                               }}/>
                    minutes.
                </div>
            ) : null}

            <Checkbox
                disabled={!isEdit}
                className={css.notifyConfigItem}
                checked={data.objAccountAlert.formData.notify_cost_more_than_money_status == 1}
                onChange={(e) => {
                    data.objAccountAlert.formData.notify_cost_more_than_money_status = e.target.checked
                }}>{`when daily cost is more than $${data.objAccountAlert.formData.notify_cost_more_than_money ?? '____'} USD:`}</Checkbox>

            {isEdit ? (
                <div className={'italic'}>
                    Dollar amount $ <InputNumber className={css.inputPsw}
                                                 onChange={(value) => {
                                                     data.objAccountAlert.formData.notify_cost_more_than_money = value;
                                                     data.objAccountAlert.formData.notify_cost_more_than_money_status = !!value;
                                                 }}/>
                </div>
            ) : null}

            <Checkbox disabled={!isEdit} className={css.notifyConfigItem}
                      checked={data.objAccountAlert.formData.notify_one_day_more_than_times_status == 1}
                      onChange={(e) => {
                          data.objAccountAlert.formData.notify_one_day_more_than_times_status = e.target.checked
                      }}>{`when number of calls a day exceed:
                ${data.objAccountAlert.formData.notify_one_day_more_than_times ?? '____'}.`}</Checkbox>


            {isEdit ? (
                <div className={'italic'}>
                    Number of calls: <InputNumber className={css.inputPsw}
                                                  onChange={(value) => {
                                                      data.objAccountAlert.formData.notify_one_day_more_than_times = value;
                                                      data.objAccountAlert.formData.notify_one_day_more_than_times_status = !!value;
                                                  }}/>
                </div>
            ) : null}

            <Checkbox disabled={!isEdit} className={css.notifyConfigItem}
                      checked={data.objAccountAlert.formData.notify_weekly_more_than_money_status == 1}
                      onChange={(e) => {
                          data.objAccountAlert.formData.notify_weekly_more_than_money_status = e.target.checked
                      }}>{`when weekly cost is more than $${data.objAccountAlert.formData.notify_weekly_more_than_money ?? '____'}USD:`}</Checkbox>


            {isEdit ? (
                <div className={'italic'}>
                    Dollar amount $ <InputNumber className={css.inputPsw}
                                                 onChange={(value) => {
                                                     data.objAccountAlert.formData.notify_weekly_more_than_money = value;
                                                     data.objAccountAlert.formData.notify_weekly_more_than_money_status = !!value;
                                                 }}/>
                </div>
            ) : null}

            <Row align={'center'} className={css.btn}>
                <div className={'greyBtn '} onClick={() => {
                    if (type == 'read') {
                        PasswordVerify.show({
                            onReturnVerify: resVerify => {
                                if (resVerify) {
                                    message.info('verify ' + resVerify);
                                    history.push('/account_owner_setting?type=account_alert_edit');
                                }
                            }
                        });
                    } else {
                        updateUserNotifyConfig();
                    }
                }}>{type == 'read' ? 'Edit' : 'Done'}</div>
            </Row>
            {!isEdit ? (
                <div>
                    <Divider/>
                    <h1 className={'bold'}>Alerts History:</h1>
                    {data.objAccountAlert.notifyHistory.map(item => {
                        return (
                            <div className={css.historyItem}>
                                <span className={css.desc}>{item.content}</span>
                                <Image className={css.delIcon} src={del_icon} preview={false} onClick={() => {
                                    delUserNotifyHistory(item._id);
                                }}/>
                            </div>
                        )
                    })}
                </div>
            ) : null}
            <BackBtn/>
        </div>
    );
}

function getAccountInfo(type = 'read') {
    return (
        <div className={css.accountInfo}>
            <h1 className={css.title}>Account Owner Info:</h1>
            <div className={'hintGrayItalic ' + css.hintAccount}>(To change content here, you have to call us.)</div>
            <div className={css.itemBox}>
                <span>First Name:&nbsp;</span>
                <span className={css.value}>{userInfo.first_name}</span>
            </div>
            <div className={css.itemBox}>
                <span>Last Name:&nbsp;</span>
                <span className={css.value}>{userInfo.second_name}</span>
            </div>
            <div className={css.itemBox}>
                <span>Email:&nbsp;</span>
                <span className={css.value}>{userInfo.email}</span>
            </div>
            <div className={css.itemBox}>
                <span>Business Name:&nbsp;</span>
                <span className={css.value}>{userInfo.company.company_name}</span>
            </div>
            <div className={css.itemBox}>
                <span>Business Account ID:&nbsp;</span>
                <span
                    className={css.value}>{`${userInfo.id2.substr(0, userInfo.id2.length - 3)}-${userInfo.id2.substr(userInfo.id2.length - 3)}`}</span>
            </div>
            <div className={css.itemBox}>
                <span>Street Address:&nbsp;</span>
                <span className={css.value}>{userInfo.company.address.street_address}</span>
            </div>
            <div className={css.itemBox}>
                <span>Street Address Line #2:&nbsp;</span>
                <span className={css.value}>{userInfo.company.address.street_address2}</span>
            </div>
            <div className={css.itemBox}>
                <span>City:&nbsp;</span>
                <span className={css.value}>{userInfo.company.address.city}</span>
            </div>
            <div className={css.itemBox}>
                <span>State:&nbsp;</span>
                <span className={css.value}>{userInfo.company.address.state}</span>
            </div>
            <div className={css.itemBox}>
                <span>Zip Code:&nbsp;</span>
                <span className={css.value}>{userInfo.company.address.zip_code}</span>
            </div>
            <div className={css.itemBox}>
                <span>Business Phone:&nbsp;</span>
                <span className={css.value}>{commonUtils.formatUSAPhoneStr(userInfo.company.phone_num)}</span>
            </div>
            <div className={css.itemBox}>
                <span>Business Website:&nbsp;</span>
                <span className={css.value}>{userInfo.company.website}</span>
            </div>

            <div className={css.teamAdminBox}>
                <div className={css.title}>Team Admin:</div>
                <div>Team Name:<span className={css.value}>  {userInfo.team_name} </span></div>
            </div>
            <div className={css.teamAdminBox}>
                <div className={css.title}>Time Zone:</div>
                <div>Your Time Zone: <span className={css.value}>{userInfo.time_zone}</span></div>
            </div>
            <BackBtn/>
        </div>
    );
}

function getAllUserList(type = 'read') {
    return (
        <div className={css.allUserList}>
            <h1 className={css.title}>All Users List</h1>
            <div>-- This page is for display only.</div>
            <div>-- To add a Team Admin, or to add users under a team admin, go back to previous page.</div>
            <Divider/>
            {data.objTeamAdmin.data.map((itemTeam, index) => {
                return (
                    <Collapse
                        // activeKey={data.objTeamAdmin.activeKey}
                        ghost onChange={(activeKey) => {
                        data.objTeamAdmin.activeItem = itemTeam;
                    }}>
                        <Panel header={(
                            <div className={css.teamInfo}>
                                <div className={css.key}>Team Name: <span
                                    className={css.teamName}>{itemTeam.team_name}</span></div>
                                {data.objTeamAdmin.activeItem.email == itemTeam.email ? (
                                    <div>
                                        <div className={css.key}>Team Admin:&nbsp;
                                            <span
                                                className={css.value}>{`${itemTeam.first_name} ${itemTeam.second_name}`}</span>
                                        </div>
                                        <div className={css.key}>Email:
                                            <span className={css.value}>{itemTeam.email}</span>
                                        </div>
                                    </div>
                                ) : null
                                }

                            </div>
                        )} key="1">
                            {itemTeam.subUser?.map(item => {
                                return (
                                    <div className={css.teamBox}>
                                        <div
                                            className={css.loginBy}> {`${item.email ? 'team members log in by email:' : (`${item.landline ? 'team members log in by landline:' : 'team members log in by cell phone number:'}`)}`}</div>
                                        <div className={css.team}>
                                            <div><span
                                                className={css.otherValue}>{`${item.first_name} ${item.second_name}`}</span>
                                            </div>

                                            <div><span
                                                className={css.otherValue}>{`${item.email ? item.email : (`${item.landline ? 'landline:' + item.landline : 'cell phone number:' + item.phone_num}`)}`}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </Panel>
                    </Collapse>
                )
            })}
            {/*<Row align={'center'}>*/}
            {/*    <div onClick={() => {*/}
            {/*        history.push('./account_owner_setting?type=all_user_list_active')*/}
            {/*    }} className={'greyBtn bold'}>Change User Status*/}
            {/*    </div>*/}
            {/*</Row>*/}
            {/*<p/>*/}
            <Row align={'center'}>
                <div onClick={() => {
                    message.info('Sorry, this function is not ready yet.');
                }} className={'greyBtn'}>Export User List
                </div>
            </Row>
            <BackBtn/>
        </div>
    );
}

function getAllUserListActive() {
    return (
        <div className={css.allUserList}>
            <h1 className={css.headerTitle}>Account Owner Role</h1>
            <h1 className={css.title}>All Users List</h1>
            <div>
                (User account will be automatically de-activated if not used in 3 months for your account safety).
            </div>

            {/*<Table*/}
            {/*    className={css.table}*/}
            {/*    rowKey="_id"*/}
            {/*    columns={[{*/}
            {/*        title: 'Team Name',*/}
            {/*        dataIndex: 'team_name',*/}
            {/*        key: 'team_name',*/}
            {/*        width: 5,*/}
            {/*    }, {*/}
            {/*        title: 'Last Name',*/}
            {/*        dataIndex: 'second_name',*/}
            {/*        key: 'second_name',*/}
            {/*        width: 5,*/}
            {/*    }, {*/}
            {/*        title: 'First Name',*/}
            {/*        dataIndex: 'first_name',*/}
            {/*        key: 'first_name',*/}
            {/*        width: 5,*/}
            {/*    }, {*/}
            {/*        title: 'Active',*/}
            {/*        dataIndex: 'status',*/}
            {/*        key: 'status',*/}
            {/*        width: 5,*/}
            {/*        render(status) {*/}
            {/*            switch (status) {*/}
            {/*                case 1:*/}
            {/*                    return (*/}
            {/*                        <Checkbox></Checkbox>*/}
            {/*                    );*/}
            {/*                case 0:*/}
            {/*                    return 'NotActive';*/}
            {/*            }*/}
            {/*        }*/}
            {/*    },]}*/}
            {/*    dataSource={data.objTeamAdmin.data.filter(arrItem => arrItem.is_owner_self_team_admin != 1)}*/}
            {/*    scroll={{x: 1000, y: 900}}*/}
            {/*/>*/}
            {data.objTeamAdmin.data.filter(arrItem => arrItem.is_owner_self_team_admin != 1).map((itemTeam, index) => {
                return (
                    <Card>
                        <div className={css.teamInfo}>
                            <div className={css.key}>Team Name: <span
                                className={css.teamName}>{itemTeam.team_name}</span></div>
                            {data.objTeamAdmin.activeKey.includes((index + 1) + '') ? (
                                <div>
                                    <div className={css.key}>Team Admin:
                                        <span
                                            className={css.value}>{`${itemTeam.first_name} ${itemTeam.second_name}`}</span>
                                    </div>
                                    <div className={css.key}>Email:
                                        <span className={css.value}>{itemTeam.email}</span>
                                    </div>
                                    <Checkbox defaultChecked={itemTeam.status} onChange={async (e) => {
                                        itemTeam.status = e.target.checked ? 1 : 0;
                                        let res = await api.user.owner_update_user({
                                            _id: itemTeam._id,
                                            status: itemTeam.status,
                                        });
                                        if (res.code != 0) return;
                                        message.success('update user success');
                                        await commonUtils.timeSleep(500)
                                        history.go(0);
                                    }}>active</Checkbox>
                                </div>
                            ) : null
                            }

                        </div>
                    </Card>
                )
            })}
        </div>
    )
}

// Email:   Elon.Musk@gmail.com
function getViewType(type) {
    switch (type) {
        case 'team_admin_read':
            return getTeamAdminView('read');
        case 'team_admin_add':
            return getTeamAdminView('add');
        case 'team_admin_edit':
        case 'team_admin_edit_user':
            return getTeamAdminViewEdit();
        case 'switch_admin':
            return getSwitchAdminView();
        case 'team_info_read':
            return getTeamInfo('read');
        case 'team_info_edit':
            return getTeamInfo('edit');
        case 'payment_read':
            return getPayment('read');
        case 'payment_edit':
            return getPayment('edit');
        case 'account_alert_read':
            return getAccountAlert('read');
        case 'account_alert_edit':
            return getAccountAlert('edit');
        case 'account_info_read':
            return getAccountInfo('read');
        case 'account_info_edit':
            return getAccountInfo('edit');
        case 'all_user_list':
            return getAllUserList('read');
        case 'all_user_list_active':
            return getAllUserListActive();
        default:
            if (!userInfo.user_password) {
                return getSetPasswordView();
            } else return getMenuList();
    }
}

let AccountOwnerSetting = observer(function () {
    history = useHistory();
    userInfo = store.persist.userInfo;
    queryParams = commonUtils.query2ObjParams(useLocation().search);
    queryParams.type = queryParams.type ?? 'menu_list';

    let params = commonUtils.query2ObjParams(useLocation().search);

    useEffect(() => {
        init(queryParams);

        return function componentWillUnmount() {
            data.objTeamAdminEdit.type = 'read';
        }
    }, []);

    return getViewType(queryParams.type);
});

export default AccountOwnerSetting;
