import React, {useEffect} from 'react';
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Button, Col, message, Radio, Row} from "antd";
import payResultCss from './pay_result.module.less';
import {useHistory} from "react-router-dom";
import {useParams, useRouteMatch, useLocation} from "react-router";
import {loadStripe} from '@stripe/stripe-js';

let store = require('../../store');
let data = store.home_login;
let api = require('src/api');
let moment = require('moment');
let history;
let config = require('commonProject/config/config');
let commonUtils = require('commonProject/utils/Common');


//支付结果页面

let Pay_result = observer(function () {
    history = useHistory();
    useEffect(() => {
        // getOrders();
    }, []);

    let params = commonUtils.query2ObjParams(useLocation().search);
    let pay_result = params.type;

    return (
        <Col className={payResultCss.containBox}>
            <p>Pay Result</p>
            <p>{pay_result == 1 ? 'pay success' : 'pay fail'}</p>
        </Col>
    );
});

export default Pay_result;