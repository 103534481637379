// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".containBox_2mlDSK {\n  text-align: center;\n}\n.containBox_2mlDSK .headTitle_3EJaI3 {\n  font-size: 22px;\n  font-weight: bold;\n  color: #920784;\n}\n.containBox_2mlDSK .subUserDialog_hYrRz7 .subUserDialog2_2QsZ6J .addBox_2cirql {\n  display: flex;\n}\n.containBox_2mlDSK .subUserDialog_hYrRz7 .subUserDialog2_2QsZ6J .itemUser_2TH8qW {\n  display: flex;\n}\n.containBox_2mlDSK .headTitle1_3re0rR {\n  font-size: 20px;\n  font-weight: bold;\n  color: black;\n  background: #BBBBBB;\n  border-radius: 4px;\n  border-style: none;\n  text-align: center;\n  height: 40px;\n}\n", "",{"version":3,"sources":["webpack://src/pages/payment/payment.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAFA;EAII,eAAA;EACA,iBAAA;EACA,cAAA;AACJ;AAPA;EAYQ,aAAA;AAFR;AAVA;EAgBQ,aAAA;AAHR;AAbA;EAsBI,eAAA;EACA,iBAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;EACA,kBAAA;EACA,YAAA;AANJ","sourcesContent":[".containBox {\n  text-align: center;\n\n  .headTitle{\n    font-size: 22px;\n    font-weight: bold;\n    color: #920784;\n  }\n\n  .subUserDialog {\n    .subUserDialog2 {\n      .addBox {\n        display: flex;\n      }\n\n      .itemUser {\n        display: flex;\n      }\n    }\n  }\n\n  .headTitle1{\n    font-size: 20px;\n    font-weight: bold;\n    color: black;\n    background: #BBBBBB;\n    border-radius: 4px;\n    border-style: none;\n    text-align:center;\n    height: 40px;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containBox": "containBox_2mlDSK",
	"headTitle": "headTitle_3EJaI3",
	"subUserDialog": "subUserDialog_hYrRz7",
	"subUserDialog2": "subUserDialog2_2QsZ6J",
	"addBox": "addBox_2cirql",
	"itemUser": "itemUser_2TH8qW",
	"headTitle1": "headTitle1_3re0rR"
};
export default ___CSS_LOADER_EXPORT___;
