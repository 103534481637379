let user = require('./user');
let interpreter = require('./interpreter');
let order = require('./order');

let api = {
    user,
    interpreter,
    order,
}

module.exports = api;